import api from '../config/api';

class DoctorApi {
    static getAll = async () => {
        const response = await api.get(`/api/anamneses`);
        return response.data;
    };

    static getByPatient = async (patientUuid) => {
        //Sync remote and local data and return all
        const response = await api.get(`/api/patients/${patientUuid}/anamneses`);
        return response.data;
    };

    static getByDoctor = async (doctorId) => {
        const response = await api.get(`/api/doctors/${doctorId}/anamneses`);
        return response.data;
    };
    
    static create = async (data) => {
        const response = await api.post('/api/anamneses', data);
        return response.data;
    };

    static getOne = async (id) => {
        const response = await api.get(`/api/anamneses/${id}`);
        return response.data;
    };

    static getOneFull = async (id) => {
        const response = await api.get(`/api/anamneses_full/${id}`);
        return response.data;
    };

    static update = async (anamnesisUuid, data) => {
        const response = await api.put(`/api/anamneses/${anamnesisUuid}`, data);
        return response.data;
    }

    /*
        Add answers to existed anamnesis
    */
    // static add = async (anamnesisUuid, data) => {
    //     const response = await api.post(`/api/anamneses/add/${anamnesisUuid}`, data);
    //     return response.data;
    // }

    static updateAll = async (patientUuid, anamneses) => {
        const response = await api.put(`/api/patients/${patientUuid}/anamneses`, anamneses);
        return response.data;
    }

    static remove = async (anamnesisUuid, updatedAt) => {
        const response = await api.delete(`/api/anamneses/${anamnesisUuid}?updatedAt=${updatedAt}`);
        return response.data;
    };
};

export default DoctorApi;
