import React from 'react';
import classNames from 'classnames';
import generateId from './generateId';

export const RadioButton = ({className, containerClassName, label, children, ...props}) => {
  const styleClasses = classNames('Form__input', className);
  const containerStyleClasses = classNames('Form__radio-container', containerClassName);

  return (
    <div className={styleClasses} {...props}>
      {label && (
        <label className="Form__input__label">
          {label}
        </label>
      )}
      <div className={containerStyleClasses}>
        {children}
      </div>
    </div>
  );
};

export const RadioOption = React.forwardRef(({className, label, disabled, ...props}, ref) => {
  const styleClasses = classNames('Form__radio', className);
  const id = generateId('radio');

  return (
    <div className={styleClasses}>
        <input type="radio" id={id} ref={ref} disabled={disabled} {...props}/>
        <label className={disabled && "Form__disabled"} htmlFor={id}>{label}</label>
    </div>
  );
});