import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import qs from 'qs';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { flashErrorMessage } from 'redux-flash';
import { Form, Input, Button, validations } from '../utility/form';
import ApiAuth from '../../api/ApiAuth';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.form = null;
    this.state = {
      password: '',
      passwordConfirm: '',
    };
  }

  setForm = (form) => {
    this.form = form;
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    const { t } = this.props;
    e.preventDefault();

    if (this.form.validateAll()) {
      const { password } = this.state;
      const { dispatch } = this.props;
      const querystring = this.props.location.search;
      const { reset_token } = qs.parse(querystring, { ignoreQueryPrefix: true });
      const token = reset_token;

      try {
        await ApiAuth.resetPassword(password, token);
        dispatch(push('/login'));
      } catch (error) {
        const message = error.response
          ? error.response.data.error
          : t("noServerConnection");

        dispatch(flashErrorMessage(message));
      }
    }
  };

  passwordMatch = (value, props, components) => {
    const { t } = this.props;
    if (value !== components['password'][0].value) {
      return <span className="Form__input__error">{t("passwordMatchValidation")}</span>
    }
  }

  render() {
    const { t } = this.props;
    const {
      password,
      passwordConfirm,
    } = this.state;

    return (
      <div className="auth_form">
        <div className="auth_form__inner">
          <p className="auth_form__head">{t("updatePassword")}</p>
          <div className="auth_form__body">
            <Form ref={this.setForm} onSubmit={this.handleSubmit} className="form">
              <div className="auth_form__body__element">
                <Input
                  label={`${t("password")}:`}
                  validations={[validations(t).required]}
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                />
              </div>
              <div className="auth_form__body__element">
                <Input
                  label={`${t("confirmPassword")}:`}
                  type="password"
                  validations={[this.passwordMatch]}
                  name="passwordConfirm"
                  value={passwordConfirm}
                  onChange={this.handleChange}
                />
              </div>
              <div className="auth_form__body__cta flex">
                <Button>
                  {t("updatePassword")}
                </Button>
                <Link to="/">
                  {t("cancel")}
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(withTranslation("common")(ResetPasswordForm));
