import * as actionTypes from './graph.actionTypes';
import { batch } from 'react-redux';

export function setBasicProperty(action) {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_BASIC_PROPERTY,
            action
        });
    }
}

export function setBatchBasicProperties(actions) {
    return (dispatch) => {
        batch(() => {
            for (let i = 0; i < actions.length; i++) {
                dispatch({
                    type: actionTypes.SET_BASIC_PROPERTY,
                    action: actions[i]
                });
            }
        });
    }
}

export function resetState() {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_INIT
        });
    }
}
