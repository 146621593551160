/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { MDBDataTableV5 } from 'mdbreact';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa';
import patientsListFolderImage from '../../assets/img/patients_list_folder.svg';
import { flashErrorMessage } from 'redux-flash';
import { removePatient } from '../../actions/Patients';
import i18n from 'i18next';
import moment from 'moment';
import { formatDate } from '../utility/Date';
import { push } from 'connected-react-router';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class PatientList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab_id: 'all'
        }
    }

    getMaxDate(anamneses) {
        let maxDate = new Date(anamneses[0].updatedAt);
        let returnDate = anamneses[0].updatedAt;

        for(let i = 1; i < anamneses.length; i++) {
            const newDate = new Date(anamneses[i]);
            if(newDate > maxDate) {
                maxDate = newDate;
                returnDate = anamneses[i];
            }
        }
        return returnDate;
    }

    /*
        Delete patient
    */
    deletePatient = async (patient) => {
        const { dispatch, t, connection } = this.props;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='theme_confirm'>
                        <p className='theme_confirm__text'>Soll die Patientenakte {patient.firstName} {patient.surName} wirklich gelöscht werden?</p>
                        <div className="theme_confirm__cta">
                            <button className="theme_btn small" onClick={onClose}>{t('no')}</button>
                            <button
                                className="theme_btn small"
                                onClick={async () => {
                                    const res = await removePatient(patient, connection);
                                    if (res.error) {
                                        dispatch(flashErrorMessage(res.error));
                                    } else {
                                        if (res.data) {
                                            dispatch({
                                                type: 'SET_PATIENTS',
                                                patients: res.data
                                            });
                                        }
                                    }
                                    onClose();
                                }}
                            >
                                {t('yes')}
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }

    renderPatientsData() {
        const { tab_id } = this.state;
        switch(tab_id) {
            case 'all': {;
                return this.renderAllPatients();
            }
            case 'active': {
                return this.renderActivePatients();
            }
            case 'fav': {
                return this.renderFavPatients();
            }
        }
    }

    renderActivePatients() {
        const {
            t,
            patients
        } = this.props;
        /*
            Build data for the data table
        */
        const tableData = {
            columns: [
                {
                    label: '',
                    field: 'icon',
                    sort: 'disabled'
                },
                {
                    label: t('tbl_name'),
                    field: 'name',
                    sort: 'desc'
                },
                {
                    label: t('tbl_birthday'),
                    field: 'birthday',
                    sort: 'disabled'
                },
                {
                    label: t('tbl_gender'),
                    field: 'gender',
                    sort: 'disabled'
                },
                {
                    label: t('tbl_actions'),
                    field: 'aktionen',
                    sort: 'disabled'
                }
            ],
            rows: []
        };
        const legitKeys = tableData.columns.map(c => {
            if (c.field && c.field !== '') {
                return c.field;
            }
            return null;
        }).filter(c => c !== null);
        const previousFewDays = moment().subtract(2, 'day').format('YYYY-MM-DD');
        const fpatients = (patients && patients.length > 0) ? patients.map(p => {
            let lastVisited = p.createdAt;
            if(p.anamneses && p.anamneses.length > 0) {
                lastVisited = this.getMaxDate(p.anamneses);
            }
            if (lastVisited > previousFewDays) {
                return p;
            }
            return null;
        }).filter(p => p !== null) : [];
        if (fpatients && fpatients.length > 0) {
            for (let p = 0; p < fpatients.length; p++) {
                const dataRowObject = {};
                for (let i = 0; i < legitKeys.length; i++) {
                    let legitValue = fpatients[p][legitKeys[i]];
                    Object.defineProperty(dataRowObject, 'clickEvent', {
                        value: this.redirectToPatientDetails.bind(this, fpatients[p].uuid),
                        writable: true,
                        enumerable: true,
                        configurable: true
                    });
                    if (legitKeys[i] === 'aktionen') {
                        legitValue = (
                            <div className="theme_table__controls">
                                <a onClick={() => this.deletePatient(fpatients[p])}><FaTrashAlt /></a>
                                <NavLink
                                    to={'/patients/' + fpatients[p].uuid + '/edit'}
                                >
                                    <FaPencilAlt />
                                </NavLink>
                            </div>
                        );
                    } else {
                        if (legitKeys[i] === 'name') {
                            legitValue = `${fpatients[p]['firstName']} ${fpatients[p]['surName']}`;
                        }
                        if (legitKeys[i] === 'gender') {
                            legitValue = fpatients[p].genderType ? t(fpatients[p].genderType.name) : 'not defined';
                        }
                        if (legitKeys[i] === 'icon') {
                            legitValue = (
                                <img src={patientsListFolderImage} />
                            );
                        }
                        if (legitKeys[i] === 'birthday') {
                            legitValue = formatDate(fpatients[p]['birthday']);
                        }
                    }
                    Object.defineProperty(dataRowObject, `${legitKeys[i]}`, {
                        value: legitValue,
                        writable: true,
                        enumerable: true,
                        configurable: true
                    });
                }
                tableData.rows.push(dataRowObject);
            }
        };
        return (
            <div className="theme_table">
                {/* <MDBDataTableV5 hover data={tableData} materialSearch /> */}
                <MDBDataTableV5 hover data={tableData} searching={false} paging={false} />
            </div>
        );
    }

    renderFavPatients() {
        const {
            t,
            patients
        } = this.props;
        /*
            Build data for the data table
        */
        const tableData = {
            columns: [
                {
                    label: '',
                    field: 'icon',
                    sort: 'disabled'
                },
                {
                    label: t('tbl_name'),
                    field: 'name',
                    sort: 'desc'
                },
                {
                    label: t('tbl_birthday'),
                    field: 'birthday',
                    sort: 'disabled'
                },
                {
                    label: t('tbl_gender'),
                    field: 'gender',
                    sort: 'disabled'
                },
                {
                    label: t('tbl_actions'),
                    field: 'aktionen',
                    sort: 'disabled'
                }
            ],
            rows: []
        };
        const legitKeys = tableData.columns.map(c => {
            if (c.field && c.field !== '') {
                return c.field;
            }
            return null;
        }).filter(c => c !== null);
        const fpatients = (patients && patients.length > 0) ? patients.filter(p => p && p.favorite) : [];
        if (fpatients && fpatients.length > 0) {
            for (let p = 0; p < fpatients.length; p++) {
                const dataRowObject = {};
                for (let i = 0; i < legitKeys.length; i++) {
                    let legitValue = fpatients[p][legitKeys[i]];
                    Object.defineProperty(dataRowObject, 'clickEvent', {
                        value: this.redirectToPatientDetails.bind(this, fpatients[p].uuid),
                        writable: true,
                        enumerable: true,
                        configurable: true
                    });
                    if (legitKeys[i] === 'aktionen') {
                        legitValue = (
                            <div className="theme_table__controls">
                                <a onClick={() => this.deletePatient(fpatients[p])}><FaTrashAlt /></a>
                                <NavLink
                                    to={'/patients/' + fpatients[p].uuid + '/edit'}
                                >
                                    <FaPencilAlt />
                                </NavLink>
                            </div>
                        );
                    } else {
                        if (legitKeys[i] === 'name') {
                            legitValue = `${fpatients[p]['firstName']} ${fpatients[p]['surName']}`;
                        }
                        if (legitKeys[i] === 'gender') {
                            legitValue = fpatients[p].genderType ? t(fpatients[p].genderType.name) : 'not defined';
                        }
                        if (legitKeys[i] === 'icon') {
                            legitValue = (
                                <img src={patientsListFolderImage} />
                            );
                        }
                        if (legitKeys[i] === 'birthday') {
                            legitValue = formatDate(fpatients[p]['birthday']);
                        }
                    }
                    Object.defineProperty(dataRowObject, `${legitKeys[i]}`, {
                        value: legitValue,
                        writable: true,
                        enumerable: true,
                        configurable: true
                    });
                }
                tableData.rows.push(dataRowObject);
            }
        };
        return (
            <div className="theme_table">
                {/* <MDBDataTableV5 hover data={tableData} materialSearch /> */}
                <MDBDataTableV5 hover data={tableData} searching={false} paging={false} />
            </div>
        );
    }

    redirectToPatientDetails(uuid, e) {
        const { dispatch } = this.props;
        const tagName = e.target.tagName;
        if (tagName !== 'svg' && tagName !== 'path') {
            const pathname = `/patients/${uuid}`;
            dispatch(push({pathname}));
        }
    }

    renderAllPatients() {
        const {
            t,
            patients
        } = this.props;
        /*
            Build data for the data table
        */
        const tableData = {
            columns: [
                {
                    label: '',
                    field: 'icon',
                    sort: 'disabled'
                },
                {
                    label: t('tbl_name'),
                    field: 'name',
                    sort: 'desc'
                },
                {
                    label: t('tbl_birthday'),
                    field: 'birthday',
                    sort: 'disabled'
                },
                {
                    label: t('tbl_gender'),
                    field: 'gender',
                    sort: 'disabled'
                },
                {
                    label: t('tbl_actions'),
                    field: 'aktionen',
                    sort: 'disabled'
                }
            ],
            rows: []
        };
        const legitKeys = tableData.columns.map(c => {
            if (c.field && c.field !== '') {
                return c.field;
            }
            return null;
        }).filter(c => c !== null);
        if (patients && patients.length > 0) {
            for (let p = 0; p < patients.length; p++) {
                const dataRowObject = {};
                for (let i = 0; i < legitKeys.length; i++) {
                    let legitValue = patients[p][legitKeys[i]];
                    Object.defineProperty(dataRowObject, 'clickEvent', {
                        value: this.redirectToPatientDetails.bind(this, patients[p].uuid),
                        writable: true,
                        enumerable: true,
                        configurable: true
                    });
                    if (legitKeys[i] === 'aktionen') {
                        legitValue = (
                            <div className="theme_table__controls">
                                <a onClick={() => this.deletePatient(patients[p])}><FaTrashAlt /></a>
                                <NavLink
                                    to={'/patients/' + patients[p].uuid + '/edit'}
                                >
                                    <FaPencilAlt />
                                </NavLink>
                            </div>
                        );
                    } else {
                        if (legitKeys[i] === 'name') {
                            legitValue = `${patients[p]['firstName']} ${patients[p]['surName']}`;
                        }
                        if (legitKeys[i] === 'gender') {
                            legitValue = patients[p].genderType ? t(patients[p].genderType.name) : 'not defined';
                        }
                        if (legitKeys[i] === 'icon') {
                            legitValue = (
                                <img src={patientsListFolderImage} />
                            );
                        }
                        if (legitKeys[i] === 'birthday') {
                            legitValue = formatDate(patients[p]['birthday']);
                        }
                    }
                    Object.defineProperty(dataRowObject, `${legitKeys[i]}`, {
                        value: legitValue,
                        writable: true,
                        enumerable: true,
                        configurable: true
                    });
                }
                tableData.rows.push(dataRowObject);
            }
        };
        return (
            <div className="theme_table">
                {/* <MDBDataTableV5 hover data={tableData} materialSearch /> */}
                <MDBDataTableV5 hover data={tableData} searching={false} paging={false} />
            </div>
        );
    }

    /*
        Change the tab
    */
    changeTabId(tab_id) {
        this.setState({ tab_id });
    }

    renderErrorMessgae() {
        const { error } = this.props;
        return (
            <div className="alert alert--error">
                <p className="alert__title">Error info:</p>
                <div className="alert__body">
                    <strong>{ error }</strong>
                </div>
            </div>
        );
    }

    render() {
        const {
            t,
            error
        } = this.props;
        const {
            tab_id
        } = this.state;
        return (
            <div className="patient_list">
                <div className="theme_tabs">
                    <a className={tab_id === 'all' ? 'active' : ''} onClick={() => this.changeTabId('all')}>{t('patient_list_all')}</a>
                    <a className={tab_id === 'active' ? 'active' : ''} onClick={() => this.changeTabId('active')}>{t('patient_list_last')}</a>
                    <a className={tab_id === 'fav' ? 'active' : ''} onClick={() => this.changeTabId('fav')}>{t('patient_list_fav')}</a>
                </div>
                <div className="patient_list__table">
                    {error && this.renderErrorMessgae()}
                    {this.renderPatientsData()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    patients: state.patients,
    error: state.error,
    connection: state.connection
});
  
export default connect(mapStateToProps)(withTranslation("common")(PatientList));
