import React from 'react';
import classNames from 'classnames';

function Relevance({ className, relevance, compact, onChangeRelevant, }) {
  return (
    <div className={classNames("theme_relevance", className)}>
      {Array.from({length: 5}, (v, k) => k+1).map(value => {
        const className = classNames(
          'theme_relevance__check',
          { 
            'theme_relevance__check--checked': value <= relevance,
            'theme_relevance__check--clickable': !!onChangeRelevant,
            'theme_relevance__check--compact': !!compact,
          },
        );
    
        return (
          <div 
            key={value} 
            className={className} 
            onClick={onChangeRelevant ? (() => onChangeRelevant({relevant: value})) : null}
          />
        );
      })}
      
    </div>
  );
}
export default Relevance;