import * as actionTypes from './connection.actionTypes';

export const initialState = { isOnline: true };

function connection(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ONLINE:
      return { ...state, isOnline: true };
    case actionTypes.SET_OFFLINE:
      return { ...state, isOnline: false };
    default:
      return state;
  }
}

export default connection;
