/* eslint-disable react/button-has-type */
// because of https://github.com/yannickcr/eslint-plugin-react/issues/1555

import React from 'react';
import classNames from 'classnames';
import './Card.scss';

function Card({ children, className, ...rest}) {
  const styleClasses = classNames('Card', className);

  return (
    <div {...rest} className={styleClasses}>
      {children}
    </div>
  );
}

Card.defaultProps = {
  className: '',
};

export default Card;
