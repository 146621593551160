import * as actionTypes from './misc.actionTypes';

export function setBasicProperty(action) {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_BASIC_PROPERTY,
            action
        });
    }
}
