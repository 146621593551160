import Cookie from 'universal-cookie';
import SpeechRecognitionApi from '../../api/SpeechRecognitionApi';

export async function getTokenOrRefresh() {
    const cookie = new Cookie();
    const speechToken = cookie.get('speech-token');

    if (speechToken === undefined) {
        try {
            const res = await SpeechRecognitionApi.getSpeechRecognitionToken();
            const token = res.token || null;
            const region = res.tokenRegion || null;
            cookie.set('speech-token', region + ':' + token, {maxAge: 540, path: '/'});
            console.log('Token fetched from back-end: ' + token);
            return { authToken: token, region: region };
        } catch (err) {
            console.log('token util error');
            return { token: null, region: null, error: err.response.data };
        }
    } else {
        console.log('Token fetched from cookie: ' + speechToken);
        const idx = speechToken.indexOf(':');
        return { authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
    }
}
