import React from 'react';
import classNames from 'classnames';
import { FaCircle } from 'react-icons/fa';
import './LoadingDots.scss';

export default function LoadingDots({ className }) {
  return (
    <div className={classNames("LoadingDots", className)}>
      <FaCircle className="LoadingDots__dot one" />
      <FaCircle className="LoadingDots__dot two" />
      <FaCircle className="LoadingDots__dot three" />
    </div>
  );
}