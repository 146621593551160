import api from '../config/api';
import { decryptPatient } from './PatientApi';

class DoctorApi {
  static async getData(doctorId) {
    const response = await api.get(`/api/doctors/${doctorId}/data`);
    response.data.patients.forEach((patient, index) => {
      response.data.patients[index] = decryptPatient(patient);
    });
    return response.data;
  }
}
export default DoctorApi;