import React from 'react';
import classNames from 'classnames';
import ButtonLink from './ButtonLink';
import { MdArrowBack } from 'react-icons/md';
import { withTranslation } from 'react-i18next';
import LoadingDots from './LoadingDots';
import './NavContainer.scss';

function NavContainer({
  children,
  className,
  navClassName,
  contentClassName,
  back,
  title,
  subtitle,
  navRight,
  t,
  backTitle,
}) {
  const containerClasses = classNames('NavContainer', className);
  const navClasses = classNames('NavContainer__nav', navClassName);
  const contentClasses = classNames('NavContainer__content', contentClassName);

  return (
    <div
      className={containerClasses}
    >
      <div className={navClasses}>
        <ButtonLink className="back" to={back} >
          <MdArrowBack />
          <span>{backTitle || t("back")}</span>
        </ButtonLink>
        <div className="NavContainer__title important">
          {title}
          {subtitle !== undefined && (
            <span className="NavContainer__sub-title">
              {subtitle || <LoadingDots className="NavContainer__loading-dots__nav"/>}
            </span>
          )}
        </div>
        <div className="right">
          {navRight}
        </div>
      </div>
      <div className={contentClasses}>
        {!children ? (
          <LoadingDots className="NavContainer__loading-dots__page"/>
        ) : (
          <>
            {children}
          </>
        )}
      </div>
    </div>
  );
}

export default withTranslation("common")(NavContainer);