import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createPatient } from '../../actions/Patients';
import PatientForm from './PatientForm';
import { NavLink } from 'react-router-dom';
import AppTopNavBar from '../navbar/AppTopNavBar';

class PatientCreate extends Component {
    constructor(props) {
        super(props);

        this.form = null;
        const birthday = props.patient ? new Date(props.patient.birthday) : new Date();
        this.state = {
            firstName: props.patient ? props.patient.firstName : "",
            surName: props.patient ? props.patient.surName : "",
            birthday: birthday,
            gender: props.patient ? props.patient.gender : "male",
            details: props.patient && props.patient.details ? props.patient.details : "",
            privacyPolicySigned: props.patient ? props.patient.privacyPolicySigned : false,
        }
    }

    setForm = (form) => {
        this.form = form;
    }

    savePatient = () => {
        if (this.form.validateAll()) {
            const data = this.form.state;
            const { dispatch, doctorId, t } = this.props;
            const payload = { ...data, doctorId };
            dispatch(createPatient(payload, t));
        }
    };

    switchMode = () => {
        console.log('switch mode');
    };

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <AppTopNavBar
                    isControlPanel={true}
                    title={'Patient anlegen'}
                    leftNavigation={
                        <NavLink to={'/patients'}>Abbrechen</NavLink>
                    }
                    rightNavigation={
                        <a onClick={this.savePatient}>Speichern</a>
                    }
                />
                <div className="app_container__inner">
                    <PatientForm ref={this.setForm} />
                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    doctorId: state.doctor.id,
});

export default connect(mapStateToProps)(withTranslation("common")(PatientCreate));