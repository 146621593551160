import React, { useState } from 'react';
import classNames from 'classnames';
import { control } from 'react-validation';
import Autosuggest from 'react-autosuggest';
import './Select.scss';

const CustomSelect = ({
  className,
  label,
  isChanged,
  isUsed,
  error,
  getSuggestions, 
  getSuggestionValue, 
  renderSuggestion,
  valueChangedhandler,
  ...props 
}) => {
  const styleClasses = classNames('Form__input', className);
  const [ value, setValue ] = useState(props.value ||'');
  const [ suggestions, setSuggestions] = useState([]);

  function onChange(event, { newValue }) {
    setValue(newValue);
    valueChangedhandler(newValue);
  };
 
  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  function onSuggestionsFetchRequested ({ value }) {
    setSuggestions(getSuggestions(value));
  };
 
  // Autosuggest will call this function every time you need to clear suggestions.
  function onSuggestionsClearRequested() {
    setSuggestions([]);
  };

  const inputProps = {
    ...props,
    value,
    onChange,
    ...isChanged && isUsed && error ? { className: 'is-invalid-input'} : {},    
  };

  return (
    <div className={styleClasses}>
      { label && (
        <label className="Form__input__label">
          {label}
        </label>
      )}
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
      { isChanged && isUsed && error}
    </div>
  );
};
const WrappedSelect = control(CustomSelect);

export const Select = React.forwardRef(({validations, ...props}, ref) => {
  if(validations) {
    return <WrappedSelect ref={ref} validations={validations} {...props} />;
  } else {
    return <CustomSelect ref={ref} {...props} />;
  }
});