import axios from 'axios';
import ApiConfig from './ApiConfig';
import CryptoJS from 'crypto-js';

const baseURL = process.env.NODE_ENV === 'production' ? ApiConfig.baseProURL: ApiConfig.baseDevURL;

const api = axios.create({
    baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'crossDomain': true
    },
    timeout: ApiConfig.timeOut,
});

if (!(process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test')) {
  api.interceptors.response.use(function (response) {
    console.log('%cRESPONSE: ' + response.status + ' %c'+ response.config.url, 'color: blue; font-weight: bold;', 'color: black;font-weight: normal;', response.data);
    return response;
  }, function (error) {
      console.log('%cRESPONSE: '+error , 'color: red; font-weight: bold;');
      return Promise.reject(error);
  })
}

let encryptionKey = null;

export function setEncryptionKey(key) {
  encryptionKey = key;
}

export function encrypt(value) {
  if(!value) return value;
  return CryptoJS.AES.encrypt(value, encryptionKey).toString();
}

export function decrypt(value) {
  if(!value) return value;
  return CryptoJS.AES.decrypt(value, encryptionKey).toString(CryptoJS.enc.Utf8);
}

export default api;
