import api from './api';
import { expireSession } from '../state/doctor/doctor.actionCreators';

function configureUnauthenticatedInterceptor(store) {
  api.interceptors.response.use(
    response => response,
    (error) => {
      const { response } = error;

      if (response && response.status === 401) {
        store.dispatch(expireSession());
      }

      return Promise.reject(error);
    },
  );
}

function configureAxios(store) {
  configureUnauthenticatedInterceptor(store);
}

export default configureAxios;
