/*
    React core modules imports / side modules imports
*/
import React, { useState, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
/*
    Icons imports
*/
import {
    MdCloudUpload
} from 'react-icons/md';

function BasicUploadButton(props) {
    const {
        t,
        uploadButtonCallback
    } = props;

    const [files, setFiles] = useState([]);
    const onDrop = useCallback(acceptedFile => {
        const mergedFiles = [...files, ...acceptedFile];
        setFiles(mergedFiles);
        // === api call to upload
        uploadButtonCallback(mergedFiles);
    });
    const {getRootProps, getInputProps} = useDropzone({onDrop});

	return (
        <div className="basic_upload_button">
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <a className="theme_btn theme_btn--inline">
                    <MdCloudUpload /> {t('uploadFile')}
                </a>
            </div>
        </div>
	);
}

export default withTranslation("common")(BasicUploadButton);
