import React, { Component } from 'react';
import crypto from 'crypto';
import { connect } from 'react-redux';
import qs from 'qs';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SimpleButton from '../utility/Button';
import { createPassword } from '../../state/doctor/doctor.actionCreators';
import { Form, Input, Button, validations } from '../utility/form';
import ApiAuth from '../../api/ApiAuth';
import Card from '../utility/Card';
import './SignUpForm.scss';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.form = null;
    this.token = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token;
    this.state = {
      doctor: null,
      confirm: false,
      password: '',
      passwordConfirm: '',
      errors: {
        missingInput: false,
        mismatchedPassword: false,
        invalidEmail: false,
      },
    };
  }

  setForm = (form) => {
    this.form = form;
  }

  async componentDidMount() {
    const doctor = await ApiAuth.getDoctorByToken(this.token);
    this.setState({ doctor });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.form.validateAll()) {
      this.setState({ confirm: true });
    } else {
      this.handleError('missingInput', true);
    }
    return true;
  };

  passwordMatch = (value, props, components) => {
    const { t } = this.props;
    if (value !== components['password'][0].value) {
      return <span className="Form__input__error">{t("passwordMatchValidation")}</span>
    }
  }

  onClickConfirm = (encryptionKey) => {
    const { t } = this.props;
    const { doctor, password } = this.state;
    const { dispatch } = this.props;
    dispatch(createPassword(doctor.id, password, encryptionKey, "/patients", t));
  }

  render() {
    const { t } = this.props;
    const {
      password,
      passwordConfirm,
      doctor,
      confirm,
    } = this.state;

    if(!doctor) {
      return null;
    }
    if(confirm) {
      const encryptionKey = crypto.randomBytes(16).toString("base64");
      return (
        <Card className="SignUpForm__confirm">
          <span className="SignUpForm__confirm__description">{`${t('encryptionKey')}:`}</span>
          <span className="SignUpForm__confirm__key">{encryptionKey}</span>
          <span className="SignUpForm__confirm__description">
            {t('storeInSafePlace')}
          </span>
          <SimpleButton
            type="button"
            className="SignUpForm__confirm__button Form__buttons__item Form__buttons__item__blue"
            onClick={() => this.onClickConfirm(encryptionKey)}
          >
            {t('continue')}
          </SimpleButton>
        </Card>
      );
    }

    return (
      <Card>
        <Form ref={this.setForm} onSubmit={this.handleSubmit} className="Form Form__centered">
          <div className="Form__title">{t('helloDoctor', { doctor: doctor.name})}</div>
          <span className="SignUpForm__subtitle">{t('createPasswordToContinue')}</span>
          <Input
            label={`${t("password")}:`}
            validations={[validations(t).required]}
            type="password"
            name="password"
            value={password}
            onChange={this.handleChange}
          />
          <Input
            label={`${t("confirmPassword")}:`}
            type="password"
            validations={[this.passwordMatch]}
            name="passwordConfirm"
            value={passwordConfirm}
            onChange={this.handleChange}
          />

          <div className="Form__buttons">
            <Button
              className="Form__buttons__item Form__buttons__item__blue"
            >
              {t("createPassword")}
            </Button>
            <Link
              to="/"
              className="Form__buttons__item Form__buttons__item__gray"
            >
              {t("cancel")}
            </Link>
          </div>
        </Form>
      </Card>
    );
  }
}

export default connect()(withTranslation("common")(ResetPasswordForm));
