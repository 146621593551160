/*
    React core modules imports / side modules imports
*/
import React, { Fragment, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Select } from '../utility/form';

/*
    Icons
*/
import { FaPaperclip, FaTextHeight } from 'react-icons/fa';

/*
    Components
*/
import AnamnesisBuilderQuestion from './AnamnesisBuilderQuestion';

function AnamnesisBuilderForm(props) {
    const {
        patient,
        diagnoses,
        transferData,
        transferAnamnesisTitle,
        t
    } = props;
    const [formData, setFormData] = useState({});
    const [isTouch, setTouchStatus] = useState(false);
    const [anamnesis_title, setAnamnesisTitle] = useState(null);
    const [answersRecognitionUuids , setAnswersRecognitionUuids] = useState([]);

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({audio: true});
        setFormData(props.formData);
        setAnamnesisTitle(props.formData.title);
        const isTouch = ('ontouchstart' in window && window.TouchEvent !== undefined) || window.navigator.maxTouchPoints > 1 || window.navigator.msMaxTouchPoints > 1;
        setTouchStatus(isTouch);
    }, []);

    /*
        Receieve data from the child question component
        Data expected to be received - updated answer object
    */
    const emitData = (answer) => {
        const formDataCopy = JSON.parse(JSON.stringify(formData));
        const answerKey = formDataCopy.answers.findIndex(a => a.uuid === answer.uuid);
        if (answerKey > -1) {
            formDataCopy.answers[answerKey].answer = answer.answer;
            const answerToTransfer = formDataCopy.answers[answerKey];
            setFormData(formDataCopy);
            transferData(answerToTransfer);
        }
    }

    const emitRootData = (answer) => {
        const formDataCopy = JSON.parse(JSON.stringify(formData));
        const answerKey = formDataCopy.answers.findIndex(a => a.uuid === answer.uuid);
        if (answerKey > -1) {
            formDataCopy.answers[answerKey] = answer;
            const answerToTransfer = formDataCopy.answers[answerKey];
            setFormData(formDataCopy);
            transferData(answerToTransfer);
        }
    }

    /*
        Find body answer in answers list
    */
    const getBodyAnswer = () => {
        const formDataAnswers = formData.answers || [];
        return formDataAnswers.find((answer) => {
            const questionType = (answer.question && answer.question.questionType) ? answer.question.questionType.id : null;
            if (questionType && questionType === 5) {
                return true;
            }
            return false;
        });
    }

    /*
        Render sympthoms list
    */
    const renderSymptomsText = () => {
        const bodyAnswer = getBodyAnswer();
        if (bodyAnswer) {
            const elements = (bodyAnswer.answer && bodyAnswer.answer.svgs) ? bodyAnswer.answer.svgs[0].elements : [];
            if (elements) {
                const sympthomsText = elements.map((e, i) => {
                    return (
                        <li key={i}>
                            {e.desc}
                        </li>
                    );
                });
                return (
                    <ul>
                        {sympthomsText}
                    </ul>
                );
            }
        }
        return null;
    }

    const onDeactivateOtherRecognitionsForm = (uuid, isStopped) => {
        let answersRecognitionUuidsCopy = [...answersRecognitionUuids];
        if (isStopped) {
            answersRecognitionUuidsCopy = [];
        } else {
            if (answersRecognitionUuidsCopy.length === 2) {
                answersRecognitionUuidsCopy.shift();
            }
            answersRecognitionUuidsCopy.push(uuid);
        }
        setAnswersRecognitionUuids(answersRecognitionUuidsCopy);
    };

    /*
        Render form questions
    */
    const renderQuestions = () => {
        const patientGender = (patient && patient.genderType.name) || 'male';
        const formDataAnswers = formData.answers || [];
        const answersContent = formDataAnswers.map((answer, index) => {
            const question = answer.question || {};
            const questionType = question.questionTypeId ? question.questionTypeId : question.questionType && question.questionType.id ? question.questionType.id : 0;
            const excludedGendersObject = (answer.question.options && answer.question.options.excludedGenders) ? answer.question.options.excludedGenders : {};
            const isQuestionExcluded = (Object.keys(excludedGendersObject).length > 0 && Object.keys(excludedGendersObject).indexOf(patientGender) > -1) ? true : false; 
            if (!isQuestionExcluded && questionType && questionType !== 5) {
                return (
                    <Fragment key={index}>
                        <AnamnesisBuilderQuestion
                            isTouch={isTouch}
                            answer={answer}
                            patient={patient}
                            emitData={emitData}
                            emitRootData={emitRootData}
                            onDeactivateOtherRecognitionsForm={onDeactivateOtherRecognitionsForm}
                            answersRecognitionUuids={answersRecognitionUuids}
                        />
                    </Fragment>
                );
            }
            return null;
        }).filter(q => q !== null);
        return (
            <div className="theme_questions">{answersContent}</div>
        );
    }

    /*
        Autocomplete set up
    */
    const getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : diagnoses.filter(diagnosis => diagnosis.name.toLowerCase().slice(0, inputLength) === inputValue);
    };
    const getSuggestionValue = (suggestion) => `${suggestion.name} (${suggestion.tag})`;
    const renderSuggestion = (suggestion) => (
        <div>
            {getSuggestionValue(suggestion)}
        </div>
    );
    const valueChangedhandler = (value) => {
        setAnamnesisTitle(value);
        transferAnamnesisTitle(value);
    }

    if (anamnesis_title !== null) {
        return (
            <div className="anamnese_form">
                <div className="anamnese_form__giagnosis">
                    <div className="anamnese_form__giagnosis__select">
                        <Select
                            label={`${t("diagnosis")}:`}
                            name="title"
                            value={anamnesis_title}
                            getSuggestions={getSuggestions} 
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            valueChangedhandler={valueChangedhandler}
                        />
                    </div>
                </div>
                <div className="anamnese_form__questions">
                    {renderQuestions()}
                </div>
                <div className="anamnese_form__additional-info">
                    <div className="anamnese_form__additional-info__textarea">
                        <div className="controls">
                            <div className="controls__icons">
                                <a onClick={() => false}><FaPaperclip/></a>
                                <a className="active" onClick={() => false}><FaTextHeight/></a>
                            </div>
                        </div>
                        <div className="Form__input">
                            <label>{t('additionalInformation')}</label>
                            <div className="read_only_textarea">
                                {renderSymptomsText()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return null;
}

export default withTranslation("common")(AnamnesisBuilderForm);
