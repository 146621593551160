import api from '../config/api';

class HomeLessonApi  {
  static async getByDoctor(doctorId) {
    const response = await api.get(`/api/doctors/${doctorId}/homeLessons`);
      return response.data;
  }
  static async create(data) {
    const response = await api.post('/api/homeLessons', data);
    return response.data;
  }
}
export default HomeLessonApi;