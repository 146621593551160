import React, { cloneElement } from 'react';
import classNames from 'classnames';
import { default as ValidatedInput } from 'react-validation/build/input';

export const Input = React.forwardRef(({className, validations, disabled, label, link, ...props}, ref) => {
  const styleClasses = classNames('Form__input', className);
  const decoratedLink = link ? cloneElement(link, {className: classNames('Form__input__link', link.props.className)}) : null;
  
  return (
    <div className={styleClasses}>
      { label && (
        <label className="Form__input__label">
          {label}
          {decoratedLink}
        </label>
      )}
      { validations ? (
        <ValidatedInput validations={validations} ref={ref} {...props} />
      ) : (
        disabled ? (
          <div className="Form__input__disabled">
            {props.defaultValue}
          </div>
        ) : (
          <input ref={ref} {...props} />
        )
      )}
    </div>
    
  );
});