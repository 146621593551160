import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import AuthNavBar from './navbar/AuthNavBar';
import LoginForm from './auth/LoginForm';
import PasswordRequestForm from './auth/PasswordRequestForm';
import ResetPasswordForm from './auth/ResetPasswordForm';
import SignUpForm from './auth/SignUpForm';

function PublicApp() {
  return (
    <div className='auth_layout'>
      <AuthNavBar/>
      <div className="auth_layout_container">
        <div className='auth_layout__left-bar'>
          <div className="auth_layout__left-bar__overlay"></div>
        </div>
        <div className='auth_layout__content'>
          <Switch>
            <Route path="/login" component={LoginForm} />
            <Route path="/forgot-password" component={PasswordRequestForm} />
            <Route path="/reset-password" component={ResetPasswordForm} />
            <Route path="/signup" component={SignUpForm} />
            <Redirect from="/" to="/login" />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default PublicApp;