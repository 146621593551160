import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { middleware as flashMiddleware } from 'redux-flash';
import thunk from 'redux-thunk';
import axiosAuthMiddleware from './axiosAuthMiddleware';
import createRootReducer from '../state/rootReducer';

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['doctor'],
};

const flashOptions = { timeout: 8000 };

function configureStore() {
  let middleware = [
    thunk,
    flashMiddleware(flashOptions),
    routerMiddleware(history),
    axiosAuthMiddleware(),
  ];

  if (process.env.NODE_ENV === 'development') {
    const { logger } = require('redux-logger');
    middleware = [...middleware, logger];
  }

  const rootReducer = createRootReducer(history);
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    applyMiddleware(...middleware),
  );

  const persistor = persistStore(store);

  return { store, persistor };
}

export default configureStore;
