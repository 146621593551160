import React, { useState, useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import {
    FaPlus
} from 'react-icons/fa';
import './AddLesson.scss';

function AddLesson({ category, onSubmit, t }) {
  const [ active, setActive ] = useState(false);
  const [ value, setValue ] = useState('');
  const inputElement = useRef(null);

  useEffect(() => {
    if(inputElement.current) {
      inputElement.current.focus();
    }
  });

  function onChange(e) {
    setValue(e.target.value);
  }

  function close() {
    setValue("");
    setActive(false);
  }

  function submitAndClose() {
    onSubmit(category, value);
    close();
  }

  function submitForm(e) {
    e.preventDefault();
    if(value.length > 0) {
      submitAndClose();
    } else {
      close();
    }
  }

  if(!active) {
    return (
        <a className="add_lesson_cta" onClick={() => setActive(true)}>
            <span className="add_lesson_cta__icon"><FaPlus/></span>{t('add')}
        </a>
    );
  } else {
    return (
      <form className="AddLesson__form" onSubmit={submitForm}>
        <div className="AddLesson__form__input">
          <input type="text" className="theme_text_input" value={value} onChange={onChange} ref={inputElement} />
          <button 
            type="button" 
            className="AddLesson__close" 
            onClick={close}
          >
            <MdClose />
          </button>
        </div>
        <button
          disabled={value.length === 0}
          type="button"
          className="AddLesson__submit"
          onClick={submitAndClose}
        >
            {t('add')}
        </button>
      </form>
    )
  }
}

export default withTranslation("common")(AddLesson);
