import React from "react";
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react'
import configureStore, { history } from './config/configureStore';
import configureAxios from './config/configureAxios';
import { Route } from 'react-router-dom';
import App from './App';

const { store, persistor } = configureStore();
configureAxios(store);

const Root = () => {
    return (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <ConnectedRouter history={history}>
                <Route path="/" component={App} />
              </ConnectedRouter>
          </PersistGate>
        </Provider>
    );
}

export default Root;