import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { flashErrorMessage } from 'redux-flash';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Form, Input, Button, validations} from '../utility/form';
import ApiAuth from '../../api/ApiAuth';

class PasswordRequestForm extends Component {
  constructor(props) {
    super(props);

    this.form = null;
    this.state = {
      email: '',
    };
  }

  setForm = (form) => {
    this.form = form;
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    const { t } = this.props;
    e.preventDefault();

    if (this.form.validateAll()) {
      const { email } = this.state;
      const { dispatch } = this.props;
      try {
        await ApiAuth.resetRequest(email);
        dispatch(push('/login'));
      } catch (error) {
        const message = error.response
          ? error.response.data.error
          : t("noServerConnection");

        dispatch(flashErrorMessage(message));
      }
    }
  };

  render() {
    const { t } = this.props;
    const { email } = this.state;
    return (
      <div className="auth_form auth_form--wide">
        <div className="auth_form__inner">
          <p className="auth_form__head">{t("resetPassword")}</p>
          <div className="auth_form__body">
            <Form ref={this.setForm} onSubmit={this.handleSubmit} className="form">
              <div className="auth_form__body__element">
                <Input
                  label={`${t("username")}:`}
                  placeholder={`${t("username_placeholder")}`}
                  validations={[validations(t).email_required]}
                  type="text"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
              </div>
              <div className="auth_form__body__cta flex">
                <Button>
                  {t("submit_auth")}
                </Button>
                <Link to="/login">{t("cancel_auth")}</Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(withTranslation("common")(PasswordRequestForm));
