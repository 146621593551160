import React, { Component } from 'react';
import { MdClose, MdSearch } from 'react-icons/md';
import onClickOutside from 'react-onclickoutside';
import classnames from 'classnames';
import './Search.scss';

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      isSelected: false,
    }
  }

  handleClickOutside = () => {
    this.setState({ isSelected: false});
  };


  onChange = (event) => {
    const { value } = event.target;
    const { onChange } = this.props;
    this.setState({ search: value});
    if(onChange) {
      onChange(value);
    }
  }

  onReset = () => {
    this.setState({ search: '' });
    const { onReset, onChange } = this.props;
    if(onReset) {
      onReset();
    } else if(onChange) {
      onChange('');
    }
  }
  
  handleSelect = () => {
    this.setState({ isSelected: true});
  };

  render() {
    const { search, isSelected} = this.state;
    const { className, id, onKeyDown, placeholder, searchInputClassName, enableCross } = this.props;
    const searchInputClassNameDefine = searchInputClassName || '';

    return (
      <form className="Search__form" onSubmit={(e) => e.preventDefault()}>
        <div className={classnames(className,"Search")} id={id}>
          <input
            autoFocus
            type="text"
            value={search}
            className={classnames(searchInputClassNameDefine, "Search__input")}
            onChange={this.onChange}
            onKeyDown={onKeyDown}
            onFocus={this.handleSelect}
            placeholder={placeholder}
          />
          <MdSearch className="Search__input__icon Search__input__icon--left" />
          {(isSelected && enableCross) && (
            <button
              type="button"
              onClick={this.onReset}
              className="Search__input__close"
            >
              <MdClose />
            </button>
          )}
        </div>
      </form>
    );
  }
}

export default onClickOutside(Search);