import LocalApi from '../api/LocalApi';
import AnamnesisApi from '../api/AnamnesisApi';
import QuestionnaireApi from '../api/QuestionnaireApi';
import uuid from 'uuid';
import { syncAnswers } from './Answers';
import { syncTreatments } from './Treatments';
import { addFiles, removeFiles } from './Patients';
import { push } from 'connected-react-router';
import { flashErrorMessage } from 'redux-flash';
import { getIsoTimeStamp, syncRecords, tryConnect, sortByCreatedAt, getItem, jobQueue, MultiCallback } from './Sync';

import { setBatchBasicProperties as setGraphBatchBasicProperties } from '../state/graph/graph.actionCreators';

export async function getQuestionnaireData(doctorId, isOnline) {
    if (isOnline) {
        return await QuestionnaireApi.getByDoctor(doctorId);
    }
    return null;
}

async function getAnamnesesObject(patient) {
  const { ...anamneses } = await getItem(`patients_${patient.uuid}_anamneses`) || [];
  return anamneses;
}

export async function getAnamneses(patient) {
  const anamneses = await getAnamnesesObject(patient);
  return sortByCreatedAt(anamneses);
}

export async function getAnamnesisDirectDb(anamnesisUuid) {
  const anamnesis = AnamnesisApi.getOneFull(anamnesisUuid);
  return anamnesis;
}

export async function getAnamnesis(patient, anamnesisUuid) {
  const anamneses = await getAnamnesesObject(patient);
  const anamnesis = anamneses[anamnesisUuid];
  if(!anamnesis || anamnesis.resourceState === 'deleted') {
    return null;
  }
  return anamnesis;
}

export function syncAnamneses(patientUuid, previousPromise, previousResolve) {
  return {
    key: `patients_${patientUuid}_anamneses`,
    task: async () => {
      const previous = await previousPromise;
      let localAnamneses = await LocalApi.getItem(`patients_${patientUuid}_anamneses`) || {};
      if(localAnamneses.length) {
        localAnamneses = {}; //Array of records is deprecated
      }
      const remoteAnamneses = await AnamnesisApi.getByPatient(patientUuid);
      let updatePromise;
      const result = syncRecords(localAnamneses, remoteAnamneses, (remoteUpdates) => {
        updatePromise = AnamnesisApi.updateAll(patientUuid, remoteUpdates);
      }, (deletedRecords) => {
        for(let record of deletedRecords) {
          LocalApi.removeItem(`anamneses_${record.uuid}_answers`);
          LocalApi.removeItem(`anamneses_${record.uuid}_treatments`);
        }
      });
      const resolve = new MultiCallback(anamneses => {
        if(anamneses) {
          LocalApi.updateAll(`patients_${patientUuid}_anamneses`, anamneses.map((anamnesis) => ({
            ...anamnesis,
            resourceState: null,
            syncState: "synced",
            updatedAtOriginal: null,
          }))).then(previousResolve(previous));
        } else {
          previousResolve(previous);
        }
      }, 2).getCallback();
  
      let anamnesesChanged = false;
      Object.values(result).forEach(anamnesis => {
        if(anamnesis.syncState !== "synced") {
          jobQueue.scheduleNow(syncAnswers(anamnesis.uuid, updatePromise, resolve));
          jobQueue.scheduleNow(syncTreatments(anamnesis.uuid, updatePromise, resolve));
          anamnesesChanged = true;
        }
      });
      if(!anamnesesChanged) {
        previousResolve(previous);
      }
      await LocalApi.persistRecords(`patients_${patientUuid}_anamneses`, result);
      return result;
    }
  }
}

function zipAnswers(answers, questions) {
  answers.forEach((answer, index) => {
    answer.question = questions[index];
  });
}

function unzipAnswers(answers) {
  const questions = [];
  const answersCopy = [];
  answers.forEach(answer => {
    const { question, ...rest } = answer;
    questions.push(question);
    answersCopy.push(rest);
  })
  return [answersCopy, questions]
}

export function resetGraphState() {
  return (dispatch, getState) => {
    const graph = getState().graph;
    const defaultGraphType = graph.graphTypes.find(e => e.id === 1);
    const defaultSvg = graph.defaultSvg;
    const batchActions = [
        {
            defaultGraphType
        },
        {
            grabOffset: null
        },
        {
            grabbedElement: null
        },
        {
            selectedElement: null
        },
        {
            svgs: defaultSvg
        },
        {
            selectedSvg: defaultSvg[0]
        }
    ];
    setGraphBatchBasicProperties(batchActions)(dispatch);
  }
}

export function createAnamnesis(patient, formData, t) {
  return async (dispatch, getState) => {
    try {
      const { isOnline } = getState().connection;

      //Prepare data
      const { fileData, ...data } = formData;
      const [ rawAnswers, questions ] = unzipAnswers(data.answers);
      data.answers = rawAnswers;
      data.updatedAt = getIsoTimeStamp();
      
      const [anamnesis, isLocal] = await tryConnect(isOnline, async () => {
        data.patientId = patient.id;
        //Send files first with a form-data request
        // if(fileData.newFiles.has('files')) {
        //   const createdFiles = await addFiles(patient, fileData.newFiles);
        //   for(const answer of data.answers) {
        //     if(answer.answer.newFiles) {
        //       answer.answer.files = answer.answer.newFiles.map((file) => {
        //         const uploadedFile = createdFiles.find(f => f.originalFilename === `${answer.questionId}_${file.name}`);
        //         uploadedFile.originalFilename = file.name;
        //         return uploadedFile;
        //       });
        //       delete answer.answer.newFiles;
        //     }
        //   }
        // }
        const anamnesis = await AnamnesisApi.create(data);
        zipAnswers(anamnesis.answers, questions);
        return anamnesis;
      }, async () => {
        // if(fileData.newFiles.has('files')) {
        //   throw new Error(t('cannotUpload'));
        // }
        const anamnesis = {
          ...data,
          uuid: uuid.v4(),
          createdAt: data.updatedAt,
          resourceState: "new",
        };
        const bodyIndex = anamnesis.answers.findIndex(answer => !!answer.answer.svgs);
        anamnesis.answers[bodyIndex].answer.svgs[0].createdAt = data.updatedAt;
        zipAnswers(anamnesis.answers, questions);
        anamnesis.answers.forEach(answer => {
          Object.assign(answer, {
            resourceState: "new",
            uuid: uuid.v4(),
            createdAt: data.updatedAt,
            updatedAt: data.updatedAt,
          })
        });
        
        return anamnesis;
      });
      const { answers, ...rawAnamnesis } = anamnesis;

      await Promise.all([
        LocalApi.addRecord(`patients_${patient.uuid}_anamneses`, rawAnamnesis),
        LocalApi.setRecords(`anamneses_${rawAnamnesis.uuid}_answers`, answers),
        await LocalApi.updateItem(`patients_${patient.uuid}`, { 
          updatedAt: data.updatedAt, 
          updatedAtOriginal: isLocal ? (patient.updatedAtOriginal || patient.updatedA) : null,
        }),
      ]);
      dispatch(push({pathname: `/patients/${patient.uuid}`, patient}));
    }
    catch(error) {
      dispatch(flashErrorMessage(error.message));
    }
  }
}

export function updateAnamnesis(patient, anamnesis, formData, t) {
  return async (dispatch, getState) => {
    try {
      const { isOnline } = getState().connection;

      const { fileData, ...data} = formData;
      const [ rawAnswers, questions ] = unzipAnswers(data.answers);
      data.answers = rawAnswers;
      data.updatedAt = getIsoTimeStamp();
      data.patientId = patient.id;
      const [ res, isLocal ] = await tryConnect(isOnline, async () => {
        if (fileData) {
          if(fileData.newFiles.has('files')) {
            const createdFiles = await addFiles(patient, fileData.newFiles);
            for(const answer of data.answers) {
              if(answer.answer.newFiles) {
                answer.answer.files = [...answer.answer.files, ...answer.answer.newFiles.map((file) => {
                  const uploadedFile = createdFiles.find(f => f.originalFilename === `${answer.questionId}_${file.name}`);
                  uploadedFile.originalFilename = file.name;
                  return uploadedFile;
                })];
                delete answer.answer.newFiles;
                delete answer.answer.deletedFiles;
              }
            }
          }
          if(fileData.deletedFiles.length) {
            await removeFiles(patient, fileData.deletedFiles);
          }
        }
        const updatedAnamnesis = await AnamnesisApi.update(anamnesis.uuid, data);
        zipAnswers(updatedAnamnesis.answers, questions);
		console.log('updatedAnamnesis: ', updatedAnamnesis);
        return updatedAnamnesis;
      }, async () => {
        if(fileData.newFiles.has('files')) {
          throw new Error(t('cannotUpload'));
        }
        if(fileData.deletedFiles.length) {
          throw new Error(t('cannotDelete'));
        }

        data.updatedAtOriginal = anamnesis.updatedAtOriginal || anamnesis.updatedAt;
        zipAnswers(data.answers, questions);
        data.answers.forEach(answer => {
          answer.updatedAtOriginal = answer.updatedAtOriginal || answer.updatedAt;
          answer.updatedAt = data.updatedAt;
        });
        data.id = anamnesis.id;
        data.uuid = anamnesis.uuid;
        return data;
      });
      let { answers, ...updatedAnamnesis }  = res;
      await Promise.all([
        LocalApi.updateRecord(`patients_${patient.uuid}_anamneses`, anamnesis.uuid, updatedAnamnesis),
        LocalApi.updateAll(`anamneses_${anamnesis.uuid}_answers`, answers),
        LocalApi.updateItem(`patients_${patient.uuid}`, { 
          updatedAt: data.updatedAt, 
          updatedAtOriginal: isLocal ? (patient.updatedAtOriginal || patient.updatedAt) : null,
        }),
      ]);
      updatedAnamnesis = { ...anamnesis, ...updatedAnamnesis};
      dispatch(push({
        pathname: `/patients/${patient.uuid}/anamneses/${anamnesis.uuid}`, 
        patient,
        anamnesis: updatedAnamnesis,
      }));
    } catch(error) {
      dispatch(flashErrorMessage(error.message));
    }
  }
}

export function removeAnamnesis(patient, anamnesis) {
  return async (dispatch, getState) => {
    const { isOnline } = getState().connection;
    const updatedAt = getIsoTimeStamp();
    const isLocal = await tryConnect(isOnline, async () => {
      await AnamnesisApi.remove(anamnesis.uuid, updatedAt);
    }, async () =>  {
      return null;
    })[1];

    const anamneses = await LocalApi.getItem(`patients_${patient.uuid}_anamneses`);
    const anamnesisToDelete = anamneses[anamnesis.uuid];
    if(anamnesisToDelete.resourceState === "new") {
      delete anamneses[anamnesis.uuid];
    } else {
      anamneses[anamnesis.uuid].resourceState = "deleted";
    }
    await LocalApi.setItem(`patients_${patient.uuid}_anamneses`, anamneses);
    await LocalApi.removeItem(`anamneses_${anamnesis.uuid}_answers`);
    await LocalApi.removeItem(`anamneses_${anamnesis.uuid}_treatments`);
    await LocalApi.updateItem(`patients_${patient.uuid}`, { 
      updatedAt,
      updatedAtOriginal: isLocal ? (patient.updatedAtOriginal || patient.updatedAt) : null,
    });
    return true;
  }
}