import React, { Component } from 'react';
import classNames from 'classnames';
import { default as ValidatedForm } from 'react-validation/build/form';


export class Form extends Component {
  constructor(props) {
    super(props);
    
    this.form = null;
  } 

  get components() {
    const { byName, byId } = this.form.state;
    return Object.fromEntries(Object.entries(byName).map(([key, value]) => {
      return [key, byId[value[0]]]
    }));
  }

  validateAll() {
    this.form.validateAll();

    //Check if all components are valid

    return Object.values(this.form.state.byId).every((component) => !component.error)
  }

  setForm = (form) => {
    this.form = form;
  }

  render() {
    const { className, children, ...props } = this.props; 
    const styleClasses = classNames('Form', className);

    return (
      <ValidatedForm ref={this.setForm} className={styleClasses} {...props}>
        {children}
      </ValidatedForm>
    );
  }
};
