import React from 'react';
import classNames from 'classnames';
import { default as ValidatedTextarea } from 'react-validation/build/textarea';

export const Textarea = React.forwardRef(({className, validations, label, link, disabled, ...props}, ref) => {
  const styleClasses = classNames('Form__input', className);
  
  return (
    <div className={styleClasses}>
      { label && (
        <label className="Form__input__label">
          {label}
        </label>
      )}
      { validations ? (
        <ValidatedTextarea validations={validations} ref={ref} {...props} />
      ) : (
        disabled ? (
          <div className="Form__textarea__disabled">
            {props.defaultValue}
          </div>
        ) : (
          <textarea ref={ref} {...props} />
        )
      )}
    </div>
  );
});