import * as actionTypes from './patients.actionTypes';
import { syncPatients as _syncPatients } from '../../actions/Patients';
import { syncOnePatient as _syncOnePatient } from '../../actions/Patients';

export function syncPatients() {
  return async (dispatch, getState) => {
    const doctorId = getState().doctor.id;
    const patients = await dispatch(_syncPatients(doctorId));

    dispatch({
      type: actionTypes.SET_PATIENTS,
      patients,
    });
  }
}

export function syncOnePatient(patient) {
  return async (dispatch) => {
    await dispatch(_syncOnePatient(patient));
    // const patients = await dispatch(_syncOnePatient(patient));
    // dispatch({
    //   type: actionTypes.SET_PATIENTS,
    //   patients,
    // });
  }
}
