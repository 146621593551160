import React from 'react';
import './HomeLessons.scss';

function HomeLessons({homeLessons}) {
  if(!homeLessons.length) {
    return [];
  }

  homeLessons.sort((a, b) => {
    if(a.homeLessonCategory.id ===  b.homeLessonCategory.id) {
      return a.name < b.name ? -1 : 1;
    } else {
      return a.homeLessonCategory.name <  b.homeLessonCategory.name ? -1 : 1;
    }
  })

  const groups = [[{ index: 1, ...homeLessons[0]}]];
  for(let i = 1; i < homeLessons.length; i++) {
    if(
      homeLessons[i-1].homeLessonCategory.id !==
      homeLessons[i].homeLessonCategory.id
    ) {
      groups.push([{ index: i+1, ...homeLessons[i]}]);
    } else {
      groups[groups.length - 1].push({ index: i+1, ...homeLessons[i]});
    }
  }

  return (
    <div className="HomeLessons">
      {groups &&
        <ul>
          {groups.map((group, index) => (
            <li key={index} className="HomeLessons__category">
              <span className="HomeLessons__category__title">{group[0].homeLessonCategory.name}</span>
              <ul>
                {group.map((homeLesson) => (
                  <li key={homeLesson.uuid}>
                    {`${homeLesson.index}. ${homeLesson.name}`}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      }
    </div>
  );
}
export default HomeLessons;