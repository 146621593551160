/* eslint-disable no-restricted-globals */
/*
    React core modules imports / side modules imports
*/
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';
import { MDBDataTableV5 } from 'mdbreact';
import { flashErrorMessage } from 'redux-flash';
import { push } from 'connected-react-router';
/*
    Components imports
*/
import AppTopNavBar from '../navbar/AppTopNavBar';
import AnamnesisBuilderQuestion from './AnamnesisBuilderQuestion';
import Relevance from './Relevance';
import SymptomModule from '../graph/SymptomModule';
/*
    Actions imports
*/
import { getPatient } from '../../actions/Patients';
import { getAnamnesis } from '../../actions/Anamneses';
import { getAnswers as loadAnswers} from '../../actions/Answers';
import { getTreatments, removeTreatment } from '../../actions/Treatments';
/*
    Redux actions imports
*/
import { setBasicProperty as setGraphBasicProperty } from '../../state/graph/graph.actionCreators';
/*
    Icons imports
*/
import { FaTrashAlt, FaPlus, FaPencilAlt } from 'react-icons/fa';
import anamnesisCardIcon from '../../assets/img/icon_clipboard.svg';
import symtomeIcon from '../../assets/img/symtome_icon.svg';
import treatmentIcon from '../../assets/img/icon_behandlung_blank.svg';
/*
    Misc
*/
import { getAge, formatDate } from '../utility/Date';
import { getAnswers, getBodyAnswer } from './AnamnesisLoader';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class AnamnesisDetailsNew extends Component {
    constructor(props) {
        super(props);
        /*
            Retrieve url params
        */
        this.patientUuid = props.match.params.uuid;
        this.anamnesisUuid = props.match.params.anamnesisUuid;
        /*
            Data holders
        */
        this.anamnesis = null;
        this.answers = null;
        this.bodyAnswer = null;
        this.treatments = [];
        this.patient = null;
        /*
            Set the loading state
        */
        this.state = {
            isLoading: true,
            tab_id: props.location.tab_id ? props.location.tab_id : 'overview'
        }
    }
    /*
        Lifecycle methods
    */
    componentDidMount() {
        this.setData();
    }
    /*
        Method responsible for the data visualization
    */
    async setData() {
        const { dispatch, graph } = this.props;
        /*
            Load patient data
        */
        const patient = await dispatch(getPatient(this.patientUuid));
        this.patient = patient;
        /*
            Load anamnesis data
        */
        this.anamnesis = await getAnamnesis(this.patient, this.anamnesisUuid);
        if(this.anamnesis) {
            const answers = await loadAnswers(this.patient, this.anamnesis);
            this.answers = getAnswers(answers);
            this.bodyAnswer = getBodyAnswer(answers);
            /*
                Setup graph editor config
            */
            const gender = (this.patient && this.patient.genderType.name) || 'male';
            const svgs = this.bodyAnswer.answer.svgs;
            const editorConfigUpdate = {
                svgs,
                gender,
                isFullScreenMode: false,
                mode: 'preview'
            };
            const editorConfig = { ...graph.editorConfig, ...editorConfigUpdate};
            const action = {
                editorConfig
            };
            setGraphBasicProperty(action)(dispatch);
        }
        /*
            Load treatments data
        */
        this.treatments = await getTreatments(this.anamnesis);
        /*
            Dismiss loading state
        */
        this.setState({
            isLoading: false,
            treatments: this.treatments,
            anamnesis: this.anamnesis
        });
    }
    /*
        Change the tab
    */
    changeTabId(tab_id) {
        this.setState({ tab_id });
    }
    /*
        Data spreader among the tabs
    */
    spreadData() {
        const { tab_id } = this.state;
        switch(tab_id) {
            case 'overview': {;
                return this.renderOverviewDetails();
            }
            case 'anamnese': {
                return this.renderAnamneseDetails();
            }
            case 'treatment': {
                return this.renderTreatmentDetails();
            }
        }
    }
    /*
        Render overview tab content
    */
    scrollElementIntoView(element) {
        const yOffset = 64 + 16; //Adjust for navbar + extra padding
        const y = element.getBoundingClientRect().top + window.scrollY - yOffset;
        window.scrollTo(0, y);
    }
    scrollIntoView(questionId) {
        this.changeTabId('anamnese');
        setTimeout(() => {
            const question = document.getElementById(questionId);
            if (question) {
                this.scrollElementIntoView(question);
            }
        }, 0);
    }
    renderOverviewDetails() {
        const { t, graph } = this.props;
        const symptomPageLink = `/patients/${this.patientUuid}/anamneses/${this.anamnesisUuid}/symptoms`;
        const editorConfig = graph.editorConfig;
        const answers = this.answers.filter((answer) => answer.relevant > 0);
        answers.sort((a, b) => a.relevant > b.relevant ? -1 : 1);
        const characteristics = answers.map((answer, index) => {
            let answerBody = answer.answer && answer.answer.basic ? answer.answer.basic : '';
            if(answerBody === "yes" && answer.question.questionTypeId === 4) {
                answerBody = answer.answer.additionalAnswer;
            }
            const answerTranslated = answerBody === "yes" || answerBody === "no" ? t(answerBody) : answerBody;
            return (
                <div key={index} className="overview_left__relevance" onClick={() => this.scrollIntoView(`question-${answer.question.id}`)}>
                    <div className="left">
                        { answer.question.abbreviation && <span>{answer.question.abbreviation}: </span>}
                        {answerTranslated}
                    </div>
                    <div className="right">
                        <Relevance
                            relevance={answer.relevant}
                            compact={true}
                        />
                    </div>
                </div>
            )
        });
        return (
            <div className="anamnesis_detail__data__overview">
                <div className="overview_left">
                    {characteristics}
                </div>
                <div className="overview_right">
                    {/* render mini sympthoms editor in preview mode */}
                    { !editorConfig.isFullScreenMode && (
                        <Link
                            to={{pathname:`/patients/${this.patientUuid}/anamneses/${this.anamnesisUuid}/symptoms`, prevPath: this.props.location.pathname}}
                        >
                            <SymptomModule
                                editorConfig={editorConfig}
                                symptomPageLink={symptomPageLink}
                                prevPath={this.props.location.pathname}
                            />
                        </Link>
                    )}
                </div>
            </div>
        );
    }
    /*
        Render anamnese tab content
    */
    renderAnamneseDetails() {
        const patientGender = (this.patient && this.patient.genderType.name) || 'male';
        const questionsContent = this.answers.map((answer, index) => {
            const excludedGendersObject = (answer.question.options && answer.question.options.excludedGenders) ? answer.question.options.excludedGenders : {};
            const isQuestionExcluded = (Object.keys(excludedGendersObject).length > 0 && Object.keys(excludedGendersObject).indexOf(patientGender) > -1) ? true : false; 
            if (!isQuestionExcluded) {
                return (
                    <Fragment key={index}>
                        <AnamnesisBuilderQuestion
                            answer={answer}
                            patient={this.patient}
                            disabled={true}
                        />
                    </Fragment>
                );
            }
            return null;
        }).filter(q => q !== null);
        return (
            <div className="anamnesis_detail__data__anamnese">
                <div className="theme_questions">
                    {questionsContent}
                </div>
            </div>
        );
    }

    redirectToTreatmenstsDetails(link, e) {
        const { dispatch } = this.props;
        const tagName = e.target.tagName;
        if (tagName !== 'svg' && tagName !== 'path') {
            dispatch(push({ pathname: link }));
        }
    }

    /*
        Render treatment tab content
    */
    renderTreatmentDetails() {
        const { t } = this.props;
        const { treatments } = this.state;
        /*
            Build data for the treatments data table
        */
        const tableData = {
            columns: [
                {
                    label: '',
                    field: 'icon',
                    sort: 'disabled',
                    width: 100
                },
                {
                    label: t('tbl_date'),
                    field: 'createdAt',
                    sort: 'asc'
                },
                {
                    label: t('treatment_success'),
                    field: 'success',
                },
                {
                    label: t('tbl_actions'),
                    field: 'aktionen',
                    sort: 'disabled',
                    width: 100
                }
            ],
            rows: []
        };
        if (treatments.length > 0) {
            const legitKeys = tableData.columns.map(c => c.field);
            for (let p = 0; p < treatments.length; p++) {
                const dataRowObject = {};
                const link = `/patients/${this.patientUuid}/anamneses/${this.anamnesisUuid}/treatments/${treatments[p].uuid}/edit`;
                Object.defineProperty(dataRowObject, 'clickEvent', {
                    value: this.redirectToTreatmenstsDetails.bind(this, link),
                    writable: true,
                    enumerable: true,
                    configurable: true
                });
                for (let i = 0; i < legitKeys.length; i++) {
                    let legitValue = treatments[p][legitKeys[i]];
                    if (legitKeys[i] === 'icon') {
                        legitValue = (
                            <img src={treatmentIcon} />
                        );
                    }
                    if (legitKeys[i] === 'createdAt') {
                        legitValue = formatDate(treatments[p]['createdAt']);
                    }
                    if (legitKeys[i] === 'success') {
                        const success = legitValue || 0;
                        legitValue = (
                            <Relevance relevance={success} onChangeRelevant={this.changeSuccess}/>
                        );
                    }
                    if (legitKeys[i] === 'aktionen') {
                        legitValue = (
                            <div className="theme_table__controls">
                                <a onClick={() => this.deleteTreatment(treatments[p])}><FaTrashAlt /></a>
                            </div>
                        );
                    }
                    Object.defineProperty(dataRowObject, `${legitKeys[i]}`, {
                        value: legitValue,
                        writable: true,
                        enumerable: true,
                        configurable: true
                    });
                }
                tableData.rows.push(dataRowObject);
            }
        }
        return (
            <div className="anamnesis_detail__data__treatment">
                <div className="treatment_header">
                    <div className="treatment_header__title"></div>
                    <div className="treatment_header__cnt">
                        <NavLink className="theme_btn medium theme_btn--white-green" to={`/patients/${this.patientUuid}/anamneses/${this.anamnesisUuid}/treatments/create`}>
                            <FaPlus/><span>{t('treatment')}</span>
                        </NavLink>
                    </div>
                </div>
                <div className="treatment_data_table">
                    <div className="theme_table">
                        {tableData.rows.length > 0 ? (
                            <>
                                <MDBDataTableV5 hover paging={false} data={tableData} searching={false} />
                            </>
                        ) : (
                            <div className="theme_empty_data">
                                {t('treatment_empty_list')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
    /*
        Change relevance success
    */
    changeSuccess = (success) => {
        console.log('TODO : success: ', success);
    }
    /*
        Delete treatment
    */
    deleteTreatment = async (treatment) => {
        const { dispatch, connection, t } = this.props;
        const { anamnesis } = this.state;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='theme_confirm'>
                        <p className='theme_confirm__text'>Soll die wirklich gelöscht werden?</p>
                        <div className="theme_confirm__cta">
                            <button className="theme_btn small" onClick={onClose}>{t('no')}</button>
                            <button
                                className="theme_btn small"
                                onClick={async () => {
                                    const updatedTreatmentsResponse = await removeTreatment(anamnesis, treatment, connection);
                                    if (updatedTreatmentsResponse.error) {
                                        dispatch(flashErrorMessage(updatedTreatmentsResponse.error));
                                    } else {
                                        if (updatedTreatmentsResponse.data) {
                                            this.setState({
                                                treatments: updatedTreatmentsResponse.data
                                            });
                                        }
                                    }
                                    onClose();
                                }}
                            >
                                {t('yes')}
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }
    /*
        Render basic patient info in a header
    */
    renderPatientCard() {
        const { t } = this.props;
        if (this.patient) {
            const age = getAge(this.patient.birthday);
            return (
                <div className="patient_details">
                    <p>{t('anamnesis')}</p>
                    <p>{`${this.patient.firstName} ${this.patient.surName} (${age} ${t('years')})`}</p>
                </div>
            );
        }
        return null;
    }
    
    /*
        Render the component when data is loaded
    */
    renderLoaded() {
        const { t } = this.props;
        const { tab_id } = this.state;
        /*
            Define config for the graph editor
        */
        return (
            <>
                <AppTopNavBar
                    isControlPanel={true}
                    title={this.renderPatientCard()}
                    leftNavigation = {
                        <NavLink className='icon-enabled-link' to={`/patients/${this.patientUuid}`}>
                            <span className='icon icon-navi-left'></span>
                            {t('navigation_back')}
                        </NavLink>
                    }
                    rightNavigation = {
                        <NavLink to={`/patients/${this.patientUuid}/anamneses/${this.anamnesisUuid}/edit`}>{t('navigation_edit_anamnesis')}</NavLink>
                    }
                />
                <div className="app_container__inner">
                    <div className="anamnesis_details_header">
                        <div className="theme_header">
                            <div className="theme_header__info">
                                <div className="theme_header__info__left">
                                    <div className="info_cell">
                                        <div className="info_cell__icon">
                                            <img src={anamnesisCardIcon} />
                                        </div>
                                        <div className="info_cell__text">
                                            <p>{this.anamnesis.title}</p>
                                            <p>{t('from') + ' ' + formatDate(this.anamnesis.createdAt)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="theme_header__info__right">
                                    <div className="control_cell">
                                        <Link
                                            className="theme_btn medium"
                                            to={{pathname:`/patients/${this.patientUuid}/anamneses/${this.anamnesisUuid}/symptoms`, prevPath: this.props.location.pathname}}
                                        >
                                            <img src={symtomeIcon} />
                                            {t("anamnesis_symptoms")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="theme_tabs">
                            <a className={tab_id === 'overview' ? 'active' : ''} onClick={() => this.changeTabId('overview')}>{t("anamnesis_details_tab_overview")}</a>
                            <a className={tab_id === 'anamnese' ? 'active' : ''} onClick={() => this.changeTabId('anamnese')}>{t("anamnesis_details_tab_anamnese")}</a>
                            <a className={tab_id === 'treatment' ? 'active' : ''} onClick={() => this.changeTabId('treatment')}>{t("anamnesis_details_tab_treatment")}</a>
                        </div>
                    </div>
                    <div className="anamnesis_detail__data">
                        {this.spreadData()}
                    </div>
                </div>
            </>
        );
    }
    render() {
        const { isLoading } = this.state;
        return (
            <>
                { !isLoading && this.renderLoaded()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    connection: state.connection,
    graph: state.graph
});

export default connect(mapStateToProps)(withTranslation('common')(AnamnesisDetailsNew));
