import React, { useState } from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';

export const TagBox = withTranslation("common")(({onUpdateTags, disabled, t, ...props}) => {
  const [ tags, setTags ] = useState(props.tags || []);
  const [ value, setValue ] = useState('');

  let input = null;

  const setInput = (value) =>{
    input = value;
  }

  const onChange = (event) => {
    const { value } = event.target;
    setValue(value);
  }

  const onKeyDown = (event) => {
    const { value } = event.target;
    const { keyCode } = event;
    
    if(keyCode === 8) {
      // Backspace
      if(!value.length) {
        const newTags = tags.slice(0, tags.length - 1)
        onUpdateTags(newTags);
        setTags(newTags);
      }
    }
    if(keyCode === 13) {
      // Enter
      if(value.length) {
        const newTags = [...tags, value]
        onUpdateTags(newTags);
        setTags(newTags);
        setValue('');
      }
    } else if(keyCode === 27) {
      // Escape
      setValue('');
    }
  }

  const deleteTag = (index) => {
    const newTags = [...tags.slice(0, index), ...tags.slice(index+1)]
    onUpdateTags(newTags);
    setTags(newTags);
    input.focus();
  }

  return (
    <div className="Form__input">
      <div className="Form__tagbox">
        {tags.map((tag, index) => (
          <span className={classNames("Form__tagbox__tag", {"Form__disabled": disabled})} key={index}>
            {tag}
            <MdClose onClick={disabled ? null : () => deleteTag(index)}/>
          </span>
        ))}
        <input
          disabled={disabled}
          ref={setInput}
          type="text" 
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      </div>
      <span className="Form__tagbox__hint">{t("pressEnter")}</span>
    </div>
  );
});