/*
    React core modules imports / side modules imports
*/
import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FaPlus, FaCheck } from 'react-icons/fa';
/*
    Components imports
*/
import { Input } from '../utility/form';

/*
    Misc
*/
import { bytesConverter } from '../utility/Basics';

function FileDropzoneModal(props) {
    const { t } = props;
    const [ selectedEditFile, setSelectedEditFile ] = useState(null);
    const [isOpened, setOpenedState] = useState(false);
    const [ files, setFiles ] = useState([]);
    const [ fileName, setFileName ] = useState('');

    const onDrop = useCallback(acceptedFiles => {
        setFileName(acceptedFiles[0].name);
        setFiles([...files, ...acceptedFiles]);
    }, []);

    async function onUpload(e) {
        e.preventDefault();
        e.stopPropagation();
        await props.onSubmit(files, fileName);
        setFiles([]);
    }

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        onDrop
    });
    // const {
    //     acceptedFiles,
    //     getRootProps,
    //     getInputProps
    // } = useDropzone({
    //     onDrop,
    //     accept: 'image/jpeg, image/jpg, image/png, video/mp4'
    // });
    
    useEffect(() => {
        if (props.initialValue) {
            setOpenedState(true);
        }
        if (props.selectedEditFile) {
            setSelectedEditFile(props.selectedEditFile);
            setFileName(props.selectedEditFile.originalFilename);
        }
    });

    /*
        Close file dropzone modal
    */
    const closeFileModalDropzone = () => {
        setOpenedState(false);
        props.fileModalDropzoneClose();
    }

    /*
        Change file name
    */
    const onChangeFileName = (e) => {
        const value = e.target.value;
        setFileName(value);
    }

	return (
        <div className="file_dropzone_modal">
            <div className="file_dropzone_modal__backdrop" onClick={() => closeFileModalDropzone()}></div>
            <div className="file_dropzone_modal__inner">
                <div className="file_dropzone_modal__name">
                    { (files.length > 0 || selectedEditFile) ? (
                            <Input
                                type="text"
                                value={fileName}
                                onChange={onChangeFileName}
                            />
                        ) : (
                            <Input
                                defaultValue={t('name_of_document')}
                                disabled={true}
                            />
                        )
                    }
                </div>
                <div className="file_dropzone_modal__zone">
                    { (files.length > 0 || selectedEditFile) ? (
                        <div className="existed_segment">
                            <div className="existed_segment__inner">
                                <div className="existed_segment__circle">
                                    <FaCheck />
                                </div>
                                {files.map((file, index) => (
                                    <div key={index} className="adding_segment__file-info">
                                          <p>{fileName}</p>
                                        <p>{bytesConverter(file.size)} - {file.type}</p>
                                    </div>
                                ))}
                                { selectedEditFile &&
                                    <>
                                        <div className="adding_segment__file-info edit">
                                            <p>{fileName}</p>
                                        </div>
                                        <a className="theme_btn" onClick={() => closeFileModalDropzone()}>{t('upload')}</a>
                                    </>
                                }
                            </div>
                        </div>
                    ) : (
                        <div className="FileDropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="adding_segment">
                                <div className="adding_segment__inner">
                                    <div className="adding_segment__circle">
                                        <FaPlus />
                                    </div>
                                    <p className="adding_segment__text">{t('upload_document')}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="file_dropzone_modal__cnt">
                    <a 
                        className={classNames(`theme_btn`, { 
                            'disabled': files.length === 0 
                        })}
                        onClick={files.length > 0 ? onUpload : null }
                    >
                        {t('create_document')}
                    </a>
                    <a className="theme_btn theme_btn--white" onClick={() => closeFileModalDropzone()}>{t('cancel')}</a>
                </div>
            </div>
        </div>
	);
}

export default withTranslation("common")(FileDropzoneModal);
