import * as actionTypes from './patients.actionTypes';

export const initialState = null;

function patients(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_PATIENTS:
      return action.patients
    default:
      return state;
  }
}

export default patients;
