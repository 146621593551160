import * as actionTypes from './doctor.actionTypes';

export const initialState = {
  isAuthenticated: false,
  id: null,
  name: null,
  accessToken: null,
  cachedPath: null,
  encryptionKey: null,
  questionnaire: null,
  homeLessons: null,
  diagnoses: null,
};

function doctor(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken
      };
    case actionTypes.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        id: action.id,
        name: action.name,
        accessToken: action.accessToken,
        encryptionKey: action.encryptionKey,
        cachedPath: null,
        expiresIn: action.expiresIn
      };
    case actionTypes.UPDATE_USER:
      return {
        ...state,
        id: action.id,
        name: action.name,
      };
    case actionTypes.UPDATE_DATA:
      return {
        ...state,
        questionnaire: action.questionnaire || state.questionnaire,
        homeLessons: action.homeLessons || state.homeLessons,
        diagnoses: action.diagnoses || state.diagnoses,
      }
    case actionTypes.CHANGE_PASSWORD:
      return state;
    case actionTypes.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        id: null,
        name: null,
        accessToken: null,
        encryptionKey: null,
        cachedPath: null,
      };
    case actionTypes.RESET:
      return {
        ...state,
        isAuthenticated: false,
        id: null,
        name: null,
        accessToken: null,
        encryptionKey: null,
        cachedPath: action.cachedPath,
      }
    default:
      return state;
  }
}

export default doctor;
