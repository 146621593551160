import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { withTranslation } from 'react-i18next';
import PatientSearch from './PatientSearch';
import PatientsList from './PatientsList';
import AppTopNavBar from '../navbar/AppTopNavBar';
import { Link } from 'react-router-dom';

const Patients = ({ t }) => {
    return (
        <>
            <AppTopNavBar title={t('patient_overview')} />
            <div className="app_container__inner">
                <div className="patient_panel">
                    <div className="patient_panel__left">
                        <PatientSearch />
                    </div>
                    <div className="patient_panel__right">
                        <Link to={{ pathname:"/patients/create" }} className="patient_panel__cta">
                            <FaPlus />
                            <span>{t("addPatient")}</span>
                        </Link>
                    </div>
                </div>
                <PatientsList />
            </div>
        </>
    );
}

export default withTranslation("common")(Patients)
