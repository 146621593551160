import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Popover from '@material-ui/core/Popover';
import { NavLink } from 'react-router-dom';
import { FaSignOutAlt, FaSearch, FaCog } from 'react-icons/fa';
import i18n from 'i18next';
import DoctorApi from '../../api/DoctorApi';
import { locales } from "../../config/i18n";
import { logout } from '../../state/doctor/doctor.actionCreators';

function AppBottomNavBar(props) {
    const {
        t
    } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const currentLocale = i18n.language || window.localStorage.i18nextLng;

    const handleLogout = () => {
        props.dispatch(logout(props.t));
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (code) => {
        i18n.changeLanguage(code);
    };

    const handleGetData = async () => {
        const data = await DoctorApi.getData(props.doctorId);
        const blob = new Blob([JSON.stringify(data, null, 2)], {type : 'application/json'});
        const fileUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = fileUrl;
        a.download = "dump.json";
        a.click();
        window.URL.revokeObjectURL(fileUrl);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'config-popover' : undefined;

    return (
        <div className="app_bottom_nav_bar">
            <div className="app_bottom_nav_bar__inner">
                <a onClick={handleLogout} className="app_bottom_nav_bar__link">
                    <FaSignOutAlt />
                    <span>Abmelden</span>
                </a>
                <NavLink className="app_bottom_nav_bar__link green_variant" to='/patients'>
                    <FaSearch />
                    <span>{t("patient_overview")}</span>
                </NavLink>
                <a aria-describedby={id} variant="contained" color="primary" onClick={handleClick} className="app_bottom_nav_bar__link hidden">
                    <FaCog />
                    <span>Einstellungen</span>
                </a>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className="app_bottom_nav_config_content">
                        <div className="config_content__misc">
                            <a onClick={handleGetData}>Download data</a>
                        </div>
                        <div className="config_content__langs">
                            {locales.map(locale => (
                                <a className={locale.code === currentLocale ? 'active' : ''} key={locale.code} onClick={() => handleLanguageChange(locale.code)}>{locale.name}</a>
                            ))}
                        </div>
                    </div>
                </Popover>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    doctorId: state.doctor.id
});
export default connect(mapStateToProps)(withTranslation("common")(AppBottomNavBar));
