/*
    React core modules imports / side modules imports
*/
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import uuid from 'uuid';
import moment from 'moment';
/*
    Components imports
*/
import ToolCase from './Drawing';
/*
    API's imports
*/
import LocalApi from '../../api/LocalApi';
import ApiConfig from '../../config/ApiConfig';
/*
    Actions imports
*/
import { getBodyImageKey } from '../../state/doctor/doctor.actionCreators';
// import { resetGraphState } from '../../actions/Anamneses';
/*
    Slider imports
*/
import Nouislider from 'react-nouislider';
import "nouislider/distribute/nouislider.css";
/*
    Redux actions imports
*/
import { setBasicProperty as setGraphBasicProperty, setBatchBasicProperties as setGraphBatchBasicProperties } from '../../state/graph/graph.actionCreators';
/*
    Icons imports
*/
import { FaCrosshairs, FaArrowsAltH, FaTimesCircle, FaTrashAlt, FaClock } from 'react-icons/fa';
import TabIcon from '../../assets/img/tab_icon.svg';

class GraphEditorModule extends Component {
    constructor(props) {
        super(props);
        this.svgBox = React.createRef();
        this.isTouch = ('ontouchstart' in window && window.TouchEvent !== undefined) || window.navigator.maxTouchPoints > 1 || window.navigator.msMaxTouchPoints > 1;
        this.toolCase = new ToolCase({ 
            setUnfinishedElement: this.setUnfinishedElement
        });
    }

    shouldComponentUpdate(nextProps) {
        const { graph } = this.props;
        const currentSelectedElement = graph.selectedElement || {};
        const nextSelectedElement = nextProps.graph.selectedElement || {};
        if (Object.keys(currentSelectedElement).length > 0 && Object.keys(nextSelectedElement).length > 0) {
            if (currentSelectedElement.degree_of_pain !== nextSelectedElement.degree_of_pain) {
                return false;
            }
            return true;
        }
        return true;
    }

    async componentDidMount() {
        const gender = this.props.config && this.props.config.gender ? this.props.config.gender : 'male';
        const defaultImage = `body_image_${gender}.png`;
        const baseURL = process.env.NODE_ENV === 'production'? ApiConfig.baseProURL: ApiConfig.baseDevURL;
        const imageUrl = `${baseURL}/static/defaults/${defaultImage}`;
        const loadedImage = await fetch(imageUrl);
        const loadedImageBlob = await loadedImage.blob();
        await LocalApi.setItem(getBodyImageKey(gender), loadedImageBlob);
        const image = await LocalApi.getItem(getBodyImageKey(gender));
        try {
            const imageUrl = URL.createObjectURL(image);
            const { config} = this.props;
            setTimeout(() => {
                /*
                    Setup svg boxes
                    Each svg consist of viewbox and elements
                    Represents data
                */
                let svgs = config.svgs || [];
                /*
                    Re-define viewbox is dimensions are equals to zero's
                    And assign unique number for each svg box
                */
                svgs = svgs.map(s => {
                    if (!s.uuid) {
                        s['uuid'] = uuid.v4()
                    };
                    if (Object.keys(s.viewBox).length > 0 && s.viewBox.width === 0 && s.viewBox.height === 0) {
                        s.viewBox.width = 960;
                        s.viewBox.height = 775;
                    }
                    return s;
                });
                /*
                    Add default svg if there is no any svgs existed
                */
                if (svgs.length == 0) {
                    const defaultSvg = {
                        createdAt: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
                        uuid: uuid.v4(),
                        elements: [],
                        viewBox: {
                            width: 960,
                            height: 775
                        }
                    };
                    svgs.push(defaultSvg);
                }
                /*
                    Define the selected svg box
                */
                const selectedSvg = svgs[0];
                /*
                    Define viewBox
                */
                const refImageDimensions = {
                    width: this.refs.image ? this.refs.image.clientWidth : 0,
                    height: this.refs.image ? this.refs.image.clientHeight : 0
                };
                const viewBox = {
                    width: (Object.keys(selectedSvg).length > 0 && selectedSvg.viewBox.width !== 0) ? selectedSvg.viewBox.width : refImageDimensions.width,
                    height: (Object.keys(selectedSvg).length > 0 && selectedSvg.viewBox.height !== 0) ? selectedSvg.viewBox.height : refImageDimensions.height
                };
                /*
                    Calculate scale
                */
                const viewBoxScale = viewBox.height / refImageDimensions.height;
                const batchActions = [
                    {
                        imageUrl
                    },
                    {
                        viewBox
                    },
                    {
                        viewBoxScale
                    },
                    {
                        svgs
                    },
                    {
                        selectedSvg
                    },
                    {
                        pt: this.svgBox.current ? this.svgBox.current.createSVGPoint() : null
                    }
                ];
                setGraphBatchBasicProperties(batchActions)(this.props.dispatch);
            }, 0);
        } catch (e) {
            console.log(`GraphEditorModule componentDidMount: ${e.message}`);
        }
    }

    componentWillUnmount() {
        /*
            Reset selected item
        */
        const action = {
            selectedElement: null
        };
        setGraphBasicProperty(action)(this.props.dispatch);
    }

    setUnfinishedElement = (unfinishedElement) => {
        unfinishedElement.drawColor = '#00A999';
        const action = {
            unfinishedElement
        };
        setGraphBasicProperty(action)(this.props.dispatch);
    }

    /*
        Reset graph
    */
    // triggerResetGraphEditor = () => {
    //     const { graph, transferData, dispatch } = this.props;
    //     dispatch(resetGraphState());
    //     transferData({
    //         svgs: graph.defaultSvg
    //     });
    // }

    /*
        Get cursor position
    */
    getScaledPosition(nativeEvent) {
        const { graph } = this.props;
        const pt = graph.pt;
        pt.x = nativeEvent.clientX;
        pt.y = nativeEvent.clientY;
        const cursorpt = pt.matrixTransform(this.svgBox.current.getScreenCTM().inverse());
        return {
            x: cursorpt.x,
            y: cursorpt.y
        }
    }

    /*
        Deactivate tutorial modal window
    */
    deactivateTutorial = () => {
        const action = {
            isTutorialActive: false
        };
        setGraphBasicProperty(action)(this.props.dispatch);
    }

    /*
        Update element in state
    */
    updateSelectedElement(updateObject) {
        const { graph, dispatch } = this.props;
        const selectedElement = { ... graph.selectedElement };
        const elementUpdated = { ...selectedElement, ...updateObject };
        const action = {
            selectedElement: elementUpdated
        };
        setGraphBasicProperty(action)(dispatch);
    }
    updateElement(uuid, updateObject, opts = {}) {
        const { graph, transferData } = this.props;
        if (Object.keys(graph.selectedSvg).length > 0) {
            // all svgs copy
            const svgs = [...graph.svgs];
            // selected svg copy
            const selectedSvgCopy = JSON.parse(JSON.stringify(graph.selectedSvg));
            // get the copy of current elements
            const elements = selectedSvgCopy.elements;
            // find element which needs to be updated
            const key = elements.findIndex(e => e.uuid === uuid);
            if (key > -1) {
                let elementUpdated = updateObject;
                elements[key] = updateObject;
                if (opts.dropSelected) {
                    elementUpdated = null;
                }
                /*
                    Find svg key
                    With a fallback to the first element in array
                */
                let svgKey = svgs.findIndex(s => (s.createdAt && selectedSvgCopy.createElement) && s.createdAt === selectedSvgCopy.createElement);
                svgKey = svgKey > -1 ? svgKey : 0;
                /*
                    Update state
                */
                svgs[svgKey].elements = elements;
                selectedSvgCopy.elements = elements;
                const batchActions = [
                    {
                        svgs
                    },
                    {
                        selectedSvg: selectedSvgCopy
                    },
                    {
                        selectedElement: elementUpdated
                    }
                ];
                setGraphBatchBasicProperties(batchActions)(this.props.dispatch);
                // tranfer data outside
                transferData({
                    svgs
                });
            }
        }
    }
    
    /*
        Handle mouse up event (callback)
        Invoked in the end of tool handleMouseUp method
        And pass created element , which is a JSON object
    */
    addElement(element, cursorPosition = {}) {
        const { graph, transferData } = this.props;
        if (Object.keys(graph.selectedSvg).length > 0) {
            // all svgs copy
            const svgs = [...graph.svgs];
            // selected svg copy
            const selectedSvgCopy = JSON.parse(JSON.stringify(graph.selectedSvg));
            if (element) {
                // get the defaults from the graph types
                const graphTypeIndex = graph.graphTypes.findIndex(g => g.id === element.type);
                const graphType = graphTypeIndex > -1 ? graph.graphTypes[graphTypeIndex] : {};
                // get the copy of current elements
                const elements = selectedSvgCopy.elements || [];
                // sort elements by count property
                elements.sort((a, b) => (a.count > b.count) ? 1 : -1);
                // calculate the count of new element
                const count = elements.length > 0 ? elements[elements.length - 1].count + 1 : 1;
                // build a json which represents a new elemnt
                const fullElement = { 
                    ...element, 
                    uuid: uuid.v4(),
                    desc: graphType.desc,
                    degree_of_pain: graphType.degree_of_pain,
                    isDraft: true,
                    count
                };
                // add new element to other elements
                elements.push(fullElement);
                /*
                    Find svg key
                    With a fallback to the first element in array
                */
                let svgKey = svgs.findIndex(s => (s.createdAt && selectedSvgCopy.createElement) && s.createdAt === selectedSvgCopy.createElement);
                svgKey = svgKey > -1 ? svgKey : 0;
                /*
                    Update svgs
                */
                svgs[svgKey].elements = elements;
                selectedSvgCopy.elements = elements;
                /*
                    Update state
                */
                const batchActions = [
                    {
                        svgs
                    },
                    {
                        selectedSvg: selectedSvgCopy
                    },
                    {
                        selectedElement: fullElement
                    },
                    {
                        isTutorialActive: false
                    }
                ];
                setGraphBatchBasicProperties(batchActions)(this.props.dispatch);
                setTimeout(() => {
                    /*
                        Set the element modal form position
                    */
                    this.configureModalForm(element, cursorPosition);
                    // tranfer data outside
                    transferData({
                        svgs
                    });
                }, 0);
            }
        }
    }

    /*
        Change the currently selected element
    */
    selectElement(element, cursorPosition = {}) {
        const batchActions = [
            {
                grabbedElement: null
            },
            {
                selectedElement: element
            },
            {
                grabOffset: null
            }
        ];
        setGraphBatchBasicProperties(batchActions)(this.props.dispatch);
        setTimeout(() => {
            /*
                Set the element modal form position
            */
            this.configureModalForm(element, cursorPosition);
        }, 0);
    }

    /*
        Configure element modal form
    */
    configureModalForm(element, cursorPosition = {}) {
        const { graph_panel_form } = this.refs;
        if (graph_panel_form) {
            const scaledX = element.anchor.x
            const inputWidth = graph_panel_form.offsetWidth;
            let left = scaledX - (inputWidth / 2);
            if(left < 0) {
                left = 0;
            } else if(scaledX + (inputWidth / 2) > this.refs.svgArea.clientWidth) {
                left = this.refs.svgArea.clientWidth - inputWidth;
            }
            // calculate top
            const scaledY = element.anchor.y;
            const inputOffset = 35;
            const inputHeight = graph_panel_form.offsetHeight;
            let yDirection = (scaledY + inputOffset + inputHeight) > this.refs.svgArea.clientHeight ? -1 : 1;
            const top = yDirection === -1 ? scaledY - inputOffset - inputHeight  : scaledY  + inputOffset;
            graph_panel_form.style.left = `${left}px`;
            graph_panel_form.style.top = `${top}px`;
        }
    }

    /*
        Update element description
    */
    changeElementDeescription = (e) => {
        const { graph } = this.props;
        const selectedElement = graph.selectedElement || null;
        if (selectedElement) {
            const desc = e.nativeEvent.target.value;
            const addData = {
                desc
            };
            const updateObject = { ...selectedElement, ...addData };
            this.updateElement(selectedElement.uuid, updateObject);
        }
    }

    /*
        Update element pain degree
    */
    updatePainDegree = (value) => {
        /*
            Get currently selected element
        */
        const { graph } = this.props;
        const currrentSelectedElement = graph.selectedElement;
        /*
            Define degree of pain
        */
        const degree_of_pain = parseInt(value, 10);
        /*
            Access DOM element directly
            And change pain point opacity
            !!! REFACTOR IT : DO IT VIA REFS !!!
        */
        const painPointElementId = `pain_point_${currrentSelectedElement.uuid}`;
        const painPointElement = document.getElementById(painPointElementId);
        const opacity = 0.1 * degree_of_pain;
        if (painPointElement) {
            painPointElement.setAttribute('style', `opacity: ${opacity}`);
        }
        /*
            Update selected element state
        */
        this.updateSelectedElement({ degree_of_pain });
    }

    /*
        Change default type
    */
    changeDefaultGraphType = (id) => {
        const { graph } = this.props;
        const graphTypes = graph.graphTypes || [];
        const graphTypeIndex = graphTypes.findIndex(g => g.id === id);
        if (graphTypeIndex > -1) {
            const action = {
                defaultGraphType: graphTypes[graphTypeIndex]
            };
            setGraphBasicProperty(action)(this.props.dispatch);
        }
    }

    addElementSeqence = (e, extendElementObject = {}) => {
        const { graph } = this.props;
        const defaultType = graph.defaultGraphType;
        const defaultTypeId = defaultType.id;
        const tool = this.toolCase.getTool(defaultTypeId);
        if (tool) {
            const nativeEvent = this.isTouch ? (e.nativeEvent.changedTouches ? e.nativeEvent.changedTouches[0] : e.nativeEvent) : e.nativeEvent;
            const cursorPosition = this.getScaledPosition(nativeEvent);
            const baseElement = tool.handleMouseUp(cursorPosition);
            const element = { ...baseElement, ...extendElementObject};
            /*
                Now we deciding what to do
                Add a brand new element on a canvas
                Or modify existed one
            */
            if ((defaultTypeId === 2 && graph.selectedElement) || (defaultTypeId === 4 && graph.selectedElement)) {
                // update
                const updateElement = { ...graph.selectedElement, ...element};
                delete updateElement.drawingPainStarted;
                this.updateElement(updateElement.uuid, updateElement);
                setTimeout(() => {
                    this.configureModalForm(updateElement, cursorPosition);
                }, 0);
            } else {
                this.addElement(element, cursorPosition);
            }
        }
    }
    
    /*
        Main SVG canvas events handlers
    */
    handleTouchMouseDown = (e) => {
        const { graph } = this.props;
        const defaultType = graph.defaultGraphType;
        /*
            Do nothing if there is not seleted element
        */
        if (!graph.selectedElement) {
            const tool = this.toolCase.getTool(defaultType.id);
            /*
                Get mouse cursor coordinates when clicked / create the element
            */
            const nativeEvent = this.isTouch ? (e.nativeEvent.changedTouches ? e.nativeEvent.changedTouches[0] : e.nativeEvent) : e.nativeEvent;
            const cursorPosition = this.getScaledPosition(nativeEvent);
            tool.handleMouseDown(cursorPosition);
        } else {
            if (defaultType.id !== 2 && defaultType.id !== 4) {
                this.removeSelected();
            }
        }
    }
    handleTouchMouseUp = (e) => {
        const { graph } = this.props;
        const defaultType = graph.defaultGraphType;
        const defaultTypeId = defaultType.id;
        /*
            Do nothing if there is not seleted element
        */
        const extendElementObject = {};
        if (!graph.selectedElement && (defaultTypeId === 2 || defaultTypeId === 4)) {
            extendElementObject.drawingPainStarted = true;
        }
        this.addElementSeqence(e, extendElementObject);
    }
    handleTouchMouseMove = (e) => {
        const { graph } = this.props;
        const grabbedElement = graph.grabbedElement;
        if (grabbedElement) {
            const grabOffset = graph.grabOffset;
            const nativeEvent = this.isTouch ? (e.nativeEvent.changedTouches ? e.nativeEvent.changedTouches[0] : e.nativeEvent) : e.nativeEvent;
            const tool = this.toolCase.getTool(grabbedElement.type);
            const position = this.getScaledPosition(nativeEvent);
            position.x -= grabOffset.x;
            position.y -= grabOffset.y;
            const newElement = tool.updatePosition(grabbedElement, position);
            const updateElement = { ...graph.selectedElement, ...newElement};
            this.updateElement(newElement.uuid, updateElement);
        }
    }

    /*
        Confirm element by removing the draft status
    */
    confirmElement = () => {
        const { graph } = this.props;
        const selectedElement = graph.selectedElement;
        if (selectedElement.uuid) {
            const addData = {
                isDraft: false
            };
            const updateObject = { ...selectedElement, ...addData };
            this.updateElement(selectedElement.uuid, updateObject, { dropSelected: true });
        }
    }

    /*
        Cancel element selected status
    */
    removeSelected = () => {
        const action = {
            selectedElement: null
        };
        setGraphBasicProperty(action)(this.props.dispatch);
    }

    /*
        Remove element
    */
    removeElement = (uuid) => {
        const { graph, transferData } = this.props;
        // all svgs copy
        const svgs = [...graph.svgs];
        // selected svg copy
        const selectedSvgCopy = JSON.parse(JSON.stringify(graph.selectedSvg));
        // find element to remove
        const elements = selectedSvgCopy.elements;
        const elementKey = elements.findIndex(e => e.uuid === uuid);
        if (elementKey > -1) {
            elements.splice(elementKey, 1);
            /*
                Find svg key
                With a fallback to the first element in array
            */
            let svgKey = svgs.findIndex(s => (s.createdAt && selectedSvgCopy.createElement) && s.createdAt === selectedSvgCopy.createElement);
            svgKey = svgKey > -1 ? svgKey : 0;
            svgs[svgKey].elements = elements;
            selectedSvgCopy.elements = elements;
            // update state
            const batchActions = [
                {
                    svgs
                },
                {
                    selectedSvg: selectedSvgCopy
                },
                {
                    selectedElement: null
                }
            ];
            setGraphBatchBasicProperties(batchActions)(this.props.dispatch);
            // tranfer data outside
            transferData({
                svgs
            });
        }
    }

    /*
        Set of event handlers
        Which will be applied for each element
        Each element is a child svg of main svg
    */
    elementCallbacks = (element) => {
        const { config, graph } = this.props;
        const mode = config.mode || null;
        const readOnly = mode === 'preview' || false;
        if (this.isTouch) {
            return {
                onTouchStart: (e) => {
                    e.stopPropagation(); // prevents creation of a new element when clicking on one
                    if (readOnly) {
                        return;
                    };
                    const {x, y} = this.getScaledPosition(e.touches[0]);
                    if (!readOnly) {
                        const grabOffset = {
                            x: x - element.anchor.x,
                            y: y - element.anchor.y,
                        };
                        const batchActions = [
                            {
                                grabbedElement: element
                            },
                            {
                                grabOffset
                            }
                        ];
                        setGraphBatchBasicProperties(batchActions)(this.props.dispatch);
                    }
                },
                onTouchEnd: (e) => {
                    e.stopPropagation();
                    if (readOnly) {
                        return;
                    };
                    const { isMoving, ...rest } = element;
                    const cursorPosition = this.getScaledPosition(e.changedTouches[0]);
                    this.selectElement(rest, cursorPosition);
                }
            }
        }
        return {
            onMouseDown: (e) => {
                e.stopPropagation(); // prevents creation of a new element when clicking on one
                if (e.button !== 0 || readOnly) {
                    return;
                };
                const {x, y} = this.getScaledPosition(e.nativeEvent);
                if (!readOnly) {
                    const grabOffset = {
                        x: x - element.anchor.x,
                        y: y - element.anchor.y,
                    };
                    const batchActions = [
                        {
                            grabbedElement: element
                        },
                        {
                            grabOffset
                        }
                    ];
                    setGraphBatchBasicProperties(batchActions)(this.props.dispatch);
                }
            },
            onMouseUp: (e) => {
                e.stopPropagation(); //Prevents creation of a new element when clicking on one
                if (readOnly) {
                    return;
                };
                const { isMoving, ...rest } = element;
                const cursorPosition = this.getScaledPosition(e.nativeEvent);
                this.selectElement(rest, cursorPosition);
            }
        }
    }

    submitPainPointForm = (e) => {
        e.preventDefault();
        this.confirmElement();
    }

    /*
        Element creation form builder
    */
    renderElementCreationForm() {
        const { graph, config, t } = this.props;
        const defaultType = graph.defaultGraphType;
        const defaultTypeId = defaultType.id;
        /*
            Define mode and readOnly state
        */
        const mode = config.mode || null;
        const readOnly = mode === 'preview' || false;
        // ===
        const isSelected = (graph.selectedElement && Object.keys(graph.selectedElement).length > 0) || false;
        const selectedElement = graph.selectedElement || null;
        // if ((selectedElement && (defaultTypeId === 2 || defaultTypeId === 4) && !selectedElement.drawingPainStarted) || ((selectedElement && defaultTypeId !== 2) || (selectedElement && defaultTypeId !== 4))) {
        // if ((selectedElement && defaultTypeId === 2 && !selectedElement.drawingPainStarted) || (selectedElement && defaultTypeId !== 2)) {
        if ((selectedElement && defaultTypeId === 2 && !selectedElement.drawingPainStarted) || (selectedElement && defaultTypeId === 4 && !selectedElement.drawingPainStarted) || (selectedElement && defaultTypeId !== 2 && defaultTypeId !== 4)) {
            /*
                Define degree of pain
            */
            let painDegree = selectedElement.degree_of_pain || null;
            if (!painDegree) {
                switch(selectedElement.drawColor) {
                    case 'Graph__pain-degree-1': {
                        painDegree = 1;
                        break;
                    }
                    case 'Graph__pain-degree-2': {
                        painDegree = 2;
                        break;
                    }
                    case 'Graph__pain-degree-3': {
                        painDegree = 3;
                        break;
                    }
                    case 'Graph__pain-degree-4': {
                        painDegree = 4;
                        break;
                    }
                }
            }
            // ===
            const isElementMoving = (graph.grabbedElement && graph.selectedElement) || false;
            const range = {min: 1, max: 10};
            /*
                Get the type of a selected element
            */
            const selectedElementTypeIndex = selectedElement ? graph.graphTypes.findIndex(g => g.id === selectedElement.type) : -1;
            const selectedElementType = selectedElementTypeIndex > -1 ? graph.graphTypes[selectedElementTypeIndex] : {};
            // ===
            if (Object.keys(selectedElementType).length > 0) {
                return (
                    <form
                        className={classNames(`graph_panel_form`, {
                            'read-only': readOnly,
                            'hidden': isElementMoving || !isSelected
                        })}
                        ref="graph_panel_form"
                        onSubmit={this.submitPainPointForm}
                    >
                        <div className="graph_panel_form__input">
                            <input 
                                className="graph_panel_form__input__desc"
                                type="text"
                                onChange={(e) =>  readOnly ? false : this.changeElementDeescription(e)}
                                disabled={readOnly ? true : false}
                                value={selectedElement ? selectedElement.desc : ''}
                            />
                            <div className="graph_panel_form__input__slider">
                                <p className="title">Schmerzgrad</p>
                                <div className="slider">
                                    <Nouislider
                                        range={range}
                                        start={[painDegree]}
                                        step={1}
                                        pips={{mode: 'count', values: 10}}
                                        connect={[true, false]}
                                        disabled={readOnly ? true : false}
                                        onSlide={readOnly ? null : this.updatePainDegree}
                                    />
                                </div>
                            </div>
                            { !readOnly &&
                                <div className="graph_panel_form__input__controls">
                                    <a className="theme_btn" onClick={() => this.confirmElement()}>{t('save')}</a>
                                    <a className="theme_btn theme_btn--white" onClick={() => this.removeElement(selectedElement.uuid)}>{t('delete')}</a>
                                </div>
                            }
                        </div>
                    </form>
                );
            }
            return (
                <form
                    className={classNames(`graph_panel_form`, {
                        'hidden': !isSelected
                    })}
                    ref="graph_panel_form"
                >
                    <p>Type is not found</p>
                </form>
            );
        }
        return null;
    }

    /*
        Change svg box handler
    */
    changeSvgBox = (e) => {
        const { graph, dispatch } = this.props;
        const { svgs } = graph;
        const boxUuid = e.target.value;
        /*
            Switch selected svg
        */
        const svgKey = svgs.findIndex(i => i.uuid === boxUuid);
        if (svgKey > -1) {
            setGraphBasicProperty({
                selectedSvg: svgs[svgKey]
            })(dispatch);
        }
    }

    /*
        Render dates switcher
        Switch between svg's boxes
    */
    renderDatesSwitcherModule() {
        const { graph, config, t } = this.props;
        const { svgs, selectedSvg } = graph;
        /*
            Check if control panel activated
        */
        const mode = config.mode || null;
        const readOnly = mode === 'preview' || false;
        /*
            Define radio boxes to switch between svg boxes
        */
        const switchOptions = (svgs && svgs.length > 0) ? svgs.map((s, i) => {
            const createDate = s.createdAt ? moment(s.createdAt).format('D. MMM. YYYY hh:mm') : moment(new Date).format('D. MMM. YYYY');
            return (
                <div className="theme_radio theme_radio__small" key={i}>
                    <input
                        type="radio"
                        name="svg_box_option"
                        id={'svg_box_option_' + s.uuid}
                        value={s.uuid}
                        checked={s.uuid === selectedSvg.uuid}
                        onChange={(e) => this.changeSvgBox(e)}
                    />
                    <label htmlFor={'svg_box_option_' + s.uuid}>
                        <div className="check_round"><span className="check_round__inside"></span></div>
                        {createDate}
                    </label>
                </div>
            );
        }) : null;
        return (
            <div className={classNames('graph_panel_dates_switcher', { 
                'control_panel_activated': !readOnly
            })}>
                <p className="graph_panel_dates_switcher__title">
                    <FaClock /><span>{t("treatmentChanges")}</span>
                </p>
                <div className="graph_panel_dates_switcher__opts">
                    {switchOptions}
                </div>
            </div>
        );
    }

    render() {
        /*
            Translation object
            State graph object (used to store data related to the graph)
        */
        const { t, graph, config, isFullScreen } = this.props;
        const mode = config.mode || null;
        const readOnly = mode === 'preview' || false;
        const defaultGraphType = graph.defaultGraphType || null;
        const isTutorialActive = graph.isTutorialActive || false;
        /*
            Data for svg box
        */
        const imageUrl = graph.imageUrl ? graph.imageUrl : null;
        const viewBox = {
            width: graph.viewBox && graph.viewBox.width ? graph.viewBox.width : 0,
            height: graph.viewBox && graph.viewBox.height ? graph.viewBox.height : 0
        };
        /*
            Prepare elements for rendering
            svgProps - object with a functions
            which are used as event handler for each sub svg
        */
        const elementsToRender = graph.selectedSvg.elements || [];
        const renderedElements = elementsToRender.map((element) => {
            const tool = this.toolCase.getTool(element.type);
            if (tool) {
                return tool.getSVG({ ...element, svgProps: this.elementCallbacks(element), scale: graph.viewBoxScale });
            }
            return null;
        }).filter(e => e !== null);
        /*
            Template rendering
        */
        return (
            <div className="graph_editor">
                {/* graph type switcher */}
                { !readOnly && (
                    <div className="graph_editor__control">
                        <div
                            className={classNames(`graph_editor__control__button-wrapper`, { 
                                'active': defaultGraphType.id === 1
                            })}
                        >
                            <div onClick={() => this.changeDefaultGraphType(1)} className="graph_editor__control__button-wrapper__button">
                                <FaCrosshairs />
                                <span>{t("graph_option_1")}</span>
                            </div>
                        </div>
                        <div
                            className={classNames('graph_editor__control__button-wrapper', { 
                                'active': defaultGraphType.id === 2
                            })}
                        >
                            <div onClick={() => this.changeDefaultGraphType(2)} className="graph_editor__control__button-wrapper__button">
                                <FaArrowsAltH />
                                <span>{t("graph_option_2")}</span>
                            </div>
                        </div>
                        <div
                            className={classNames('graph_editor__control__button-wrapper', { 
                                'active': defaultGraphType.id === 3
                            })}
                        >
                            <div onClick={() => this.changeDefaultGraphType(3)} className="graph_editor__control__button-wrapper__button">
                                <FaTimesCircle />
                                <span>{t("graph_option_3")}</span>
                            </div>
                        </div>
                        <div
                            className={classNames('graph_editor__control__button-wrapper', { 
                                'active': defaultGraphType.id === 4
                            })}
                        >
                            <div onClick={() => this.changeDefaultGraphType(4)} className="graph_editor__control__button-wrapper__button">
                                <FaArrowsAltH />
                                <span>{t("graph_option_4")}</span>
                            </div>
                        </div>
                    </div>
                )}
                {/* dates switcher */}
                { (isFullScreen && (mode === 'edit' || mode === 'preview')) && this.renderDatesSwitcherModule()}
                <div className="graph_panel">
                    <div className="graph_panel__inner">
                        {/* Tutorial window rendering */}
                        { (elementsToRender.length === 0 && !readOnly && isTutorialActive) && (
                            <div className="graph_panel_form_tutorial">
                                <div className="graph_panel_form_tutorial__pointer">
                                    <img src={TabIcon} />
                                </div>
                                <div className="graph_panel_form_tutorial__title">
                                    {t("graph_tutorial_title")}
                                </div>
                                <div className="graph_panel_form_tutorial__desc">
                                    {t("graph_tutorial_desc")}
                                </div>
                                <div className="graph_panel_form_tutorial__cnt">
                                    <a className="theme_btn" onClick={this.deactivateTutorial}>{t("graph_tutorial_submit_btn")}</a>
                                </div>
                            </div>
                        )}
                        {/* SVG area rendering */}
                        <div
                            className={classNames('graph_panel_svg', { 
                                'fixed_size': isFullScreen
                            })}
                            ref="svgArea"
                        >
                            {/* SVG element creating / editing form */}
                            {this.renderElementCreationForm()}
                            <img
                                className="graph_panel_svg__img"
                                ref="image"
                                src={imageUrl}
                                crossOrigin=""
                                alt="body"
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                ref={this.svgBox}
                                viewBox={`0 0 ${viewBox.width} ${viewBox.height}`}
                                className="graph_panel_svg__svg"
                                draggable={false}
                                onDragStart={(e) => { e.preventDefault()}}
                                onDrag={(e) => { e.preventDefault()}}
                                onMouseDown = {(!readOnly && !this.isTouch) ? this.handleTouchMouseDown : null}
                                onMouseMove = {(!readOnly && !this.isTouch) ? this.handleTouchMouseMove : null}
                                onMouseUp = {(!readOnly && !this.isTouch) ? this.handleTouchMouseUp : null}
                                onTouchStart = {(!readOnly && this.isTouch) ? this.handleTouchMouseDown : null}
                                onTouchMove = {(!readOnly && this.isTouch) ? this.handleTouchMouseMove : null}
                                onTouchEnd = {(!readOnly && this.isTouch) ? this.handleTouchMouseUp : null}
                            >
                                {renderedElements}
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    graph: state.graph
});

export default connect(mapStateToProps)(withTranslation('common')(GraphEditorModule));
