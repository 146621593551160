import LocalApi from '../api/LocalApi';
import { syncRecords, getAll, getItem  } from './Sync';

import AnswerApi from '../api/AnswerApi';

export async function getAnswers(patient, anamnesis) {
  let [answerObject, files] = await Promise.all([
    getItem(`anamneses_${anamnesis.uuid}_answers`),
    LocalApi.getItem(`patients_${patient.uuid}_files`),
  ]);
  answerObject = answerObject || {};
  files = files || {};
  const answers = getAll(answerObject);
  for(const answer of answers) {
    if(answer.answer.files) {
      //Check if file still exists and remove if not
      answer.answer.files = answer.answer.files.filter(f => !!files[f.id]);
    }
  }
  return answers;
}

export function syncAnswers(anamnesisUuid, previousPromise, previousResolve) {
  return {
    key: `anamneses_${anamnesisUuid}_answers`,
    task: async () => {
      const previous = await previousPromise;
      let localAnswers = await LocalApi.getItem(`anamneses_${anamnesisUuid}_answers`) || {};
      if(localAnswers.length) {
        localAnswers = {}; //Array of records is deprecated
      }
      const remoteAnswers = await AnswerApi.getByAnamnesis(anamnesisUuid);
      let remoteUpdates = null;
      const result = syncRecords(localAnswers, remoteAnswers, (updates) => {
        remoteUpdates = updates;
      });
      if(remoteUpdates) {
        AnswerApi.updateAll(anamnesisUuid, remoteUpdates).then((answers) => {
          LocalApi.updateAll(`anamneses_${anamnesisUuid}_answers`, answers.map((answer) => ({
            id: answer.id,
            uuid: answer.uuid,
            syncState: "synced",
            resourceState: null,
            createdAt: answer.createdAt,
            updatedAt: answer.updatedAt,
          }))).then(previousResolve(previous));
        });
      } else {
        previousResolve(previous);
      };

      await LocalApi.persistRecords(`anamneses_${anamnesisUuid}_answers`, result);
      return result;
    } 
  }
}