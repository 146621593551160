/*
    React core modules imports / side modules imports
*/
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
/*
    Components imports
*/
import GraphEditorModule from './GraphEditorModule';

function SymptomModule(props) {
    const {
        editorConfig,
        symptomSlideCallback
    } = props;
    const [isFullScreenMode, setFullScreenMode] = useState(false);
    const [currentSvg, setCurrentSvg] = useState({});
    
    useEffect(() => {
        if (editorConfig.isFullScreenMode) {
            setFullScreenMode(true);
        }
        if (Object.keys(currentSvg).length === 0) {
            if (editorConfig.svgs && editorConfig.svgs.length > 0) {
                setCurrentSvg(editorConfig.svgs[0]);
            }
        }
    });

    /*
        Render text based version of elements
    */
    const renderTextElements = () => {
        if (Object.keys(currentSvg).length > 0) {
            const elements = currentSvg.elements || [];
            const items = elements.map(i => (
                <li key={i.uuid}>{i.desc}</li>
            ));
            return (
                <ul className="graph_panel_data__list">
                    {items}
                </ul>
            );
        }
        return null;
    }

    /*
        Activate full screen graph editor mode
    */
    const toogleFullScreenMode = () => {
        setFullScreenMode(true);
    }

    /*
        Manage outcoming graph editor data
    */
    const outcomeGraphData = (v) => {
        if (typeof symptomSlideCallback === 'function') {
            symptomSlideCallback(v);
        }
    }

    const isFullScreen = () => isFullScreenMode || editorConfig.isFullScreenMode;

	return (
        <>
            <div
                className={classNames(`sympthoms_slide`, { 
                    'full_screen': isFullScreen()
                })}
            >
                {isFullScreen() ? (
                    <>
                        <GraphEditorModule
                            config={editorConfig || {}}
                            transferData={outcomeGraphData}
                            activateGraphFullScreen={toogleFullScreenMode}
                            isFullScreen={isFullScreen()}
                        />
                    </>
                ) : (
                    <>
                        <div className="sympthoms_slide__body">
                            <div className="graph_panel_sheet">
                                <GraphEditorModule
                                    config={editorConfig || {}}
                                    transferData={outcomeGraphData}
                                    isFullScreen={isFullScreen()}
                                />
                            </div>
                            <div className="graph_panel_data">
                                {renderTextElements()}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
	);
}

export default withTranslation("common")(SymptomModule);