import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import logoIcon from '../../assets/img/logo.png';

const mapStateToProps = (state) => ({
  username: state.doctor.name
});

class AppTopNavBar extends Component {
    mainPanelTemplate = (props) => {
        const {
            username,
            title
        } = props;
        const pageName = title || '';
        return (
            <>
                <div className="app_top_nav_bar__logo">
                    <NavLink
                        to="/"
                        className="logo"
                    >
                        <div className="logo__cta">
                            <img src={logoIcon} />
                        </div>
                        <span className="logo__text">Treat-Assistant</span>
                    </NavLink>
                </div>
                <div className="app_top_nav_bar__page-title">{pageName}</div>
                <div className="app_top_nav_bar__auth-info">
                    <div className="avatar">
                        <span className="avatar__text">{username}</span>
                        <div className="avatar__image"></div>
                    </div>
                </div>
            </>
        );
    };
    controlPanelTemplate = (props) => {
        const {
            title,
            leftNavigation,
            rightNavigation
        } = props;
        const pageName = title || '';
        return (
            <>
                <div className="app_top_nav_bar__left-link">
                    {leftNavigation}
                </div>
                <div className="app_top_nav_bar__page-title">{pageName}</div>
                <div className="app_top_nav_bar__right-link">
                    {rightNavigation}
                </div>
            </>
        );
    };
    render() {
        const {
            isControlPanel,
            classNameAdd
        } = this.props;
        /*
            Define the panel type
            If isMainPanel equals to true
            Then we have logo , username and etc.
            Otherwise , back link , submit button and title
        */
        const isControlPanelStatus = isControlPanel || false;
        const topBarClassName = `app_top_nav_bar ${classNameAdd ? classNameAdd : ''}`;
        return (
            <div className={topBarClassName}>
                <div className="app_top_nav_bar__inner app_top_nav_bar__inner--flex-layout">
                    {isControlPanelStatus ? this.controlPanelTemplate(this.props) : this.mainPanelTemplate(this.props)}
                </div>
            </div>
        );
  }
}

export default connect(mapStateToProps)(withTranslation("common")(AppTopNavBar));
