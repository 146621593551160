import api, { setEncryptionKey } from './api';

function axiosAuthMiddleware() {
  return ({ getState }) => next => (action) => {
    const accessToken = action.accessToken || getState().doctor.accessToken;
    const encryptionKey = action.encryptionKey || getState().doctor.encryptionKey;
    setEncryptionKey(encryptionKey);

    if (accessToken) {
      api.defaults.headers.common['x-access-token'] = accessToken;
    } else {
      delete api.defaults.headers.common['x-access-token'];
    }

    return next(action);
  };
}

export default axiosAuthMiddleware;
