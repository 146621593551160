/*
    React core modules imports / side modules imports
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import uuid from 'uuid';
/*
    Custom hooks
*/
// import useOnlineStatus from '../../hooks/useOnlineStatus';
/*
    Components imports
*/
import AppTopNavBar from '../navbar/AppTopNavBar';
import AnamnesisBuilderForm from './AnamnesisBuilderForm';
import SymptomModule from '../graph/SymptomModule';
/*
    Actions imports
*/
import { getPatient } from '../../actions/Patients';
import { createAnamnesis, updateAnamnesis, getAnamnesis, getQuestionnaireData } from '../../actions/Anamneses';
import { getAnswers as loadAnswers} from '../../actions/Answers';
/*
    Redux actions imports
*/
import { setBasicProperty as setMiscBasicProperty } from '../../state/misc/misc.actionCreators';
import { setBasicProperty as setAnamnesisBasicProperty } from '../../state/anamnesis/anamnesis.actionCreators';
import { setBatchBasicProperties as setAnamnesisBatchBasicProperties } from '../../state/anamnesis/anamnesis.actionCreators';
/*
    Icons imports
*/
import symtomeIcon from '../../assets/img/symtome_icon.svg';
import patientsListFolderImage from '../../assets/img/patients_list_folder.svg';
/*
    Misc
*/
import { getAge, formatDate } from '../utility/Date';
import { getBodyAnswer, getBodyAnswerIndex } from './AnamnesisLoader';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class AnamnesisBuilderModule extends Component {
    constructor(props) {
        super(props);
        /*
            Retrieve url params
        */
        this.patientUuid = props.match.params.uuid;
        this.anamnesisUuid = props.match.params.anamnesisUuid || null;
        /*
            Data holders
            Whicn is coming from the server
        */
        this.patient = null;
        this.questionnaire = null;
        this.anamnesis = null;
        this.editorConfig = {};
        this.state = {
            error: null
        };
    }

    async setData() {
        const { graph, dispatch, doctorId, connection } = this.props;
        /*
            Load patient data (not reactive)
        */
        const patient = await dispatch(getPatient(this.patientUuid));
        this.patient = patient;
        /*
            Load questionnaire data
        */
        const questionnaireData = await getQuestionnaireData(doctorId, connection.isOnline);
        /*
            Get questions from the questionnaire data
            And filter them by gender
            And map in order to array of objects which we need
        */
        const questions = (questionnaireData && questionnaireData.questions) || [];
        const filteredQuestions = questions.filter((question) => {
            return !(
                question.options &&
                question.options.excludedGenders && 
                question.options.excludedGenders[this.patient.genderType.name]
            );
        }).map((question) => ({
            questionId: question.id,
            question, 
            answer: { basic: '' },
            relevant: 0
        }));
        /*
            Load anamnesis
            For the edit mode
        */
        let anamnesis = null;
        if (this.anamnesisUuid) {
            anamnesis = await getAnamnesis(this.patient, this.anamnesisUuid);
        }
        this.anamnesis = anamnesis;
        /*
            Define answers based on a template (by default)
        */
        let answers = [ ...filteredQuestions ];
        if (anamnesis) {
            answers = await loadAnswers(this.patient, anamnesis);
        }
        /*
            Check if all answers has uuid
        */
        const hasAnswersUuid = answers.every(a => a.uuid);
        if (!hasAnswersUuid) {
            answers = answers.map((a) => {
                return  {
                    uuid: uuid.v4(),
                    ...a
                };
            })
        }
        /*
            Sort answers
        */
        answers.sort((a, b) => {
            return a.question.order < b.question.order ? -1 : 1;
        });
        /*
            Extract / define body answer
        */
        const bodyAnswer = getBodyAnswer(answers);
        if (!anamnesis) {
            bodyAnswer.answer = {
                basic: null,
                svgs: [
                    {
                        elements: [],
                        viewBox: {
                            width: 960,
                            height: 775
                        }
                    }
                ]
            };
        }
        /*
            Setup graph editor config
        */
        const gender = (this.patient && this.patient.genderType.name) || 'male';
        const svgs = bodyAnswer.answer.svgs;
        const editorConfigUpdate = {
            svgs,
            gender,
            isFullScreenMode: true,
            mode: 'edit'
        };
        const editorConfig = { ...graph.editorConfig, ...editorConfigUpdate};
        this.editorConfig = editorConfig;
        /*
            Set initial state data
        */
        const form_builder_data = {
            answers,
            title: anamnesis ? anamnesis.title : ''
        };
        const batchActions = [
            {
                isLoading: false
            },
            {
                form_builder_data
            }
        ];
        setAnamnesisBatchBasicProperties(batchActions)(dispatch);
    }

    componentDidMount() {
        this.setData();
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        /*
            Set component to the loading state
            Upon exit
        */
        const action = {
            isLoading: true
        };
        setAnamnesisBasicProperty(action)(dispatch);
    }

    formDataAnamnesisTitleReceiver = (title) => {
        const { dispatch, anamnesis } = this.props;;
        const formDataCopy = JSON.parse(JSON.stringify(anamnesis.form_builder_data));
        formDataCopy.title = title;
        const action = {
            form_builder_data: formDataCopy
        };
        setAnamnesisBasicProperty(action)(dispatch);
    }

    formDataReceiver = (answer) => {
        const { dispatch, anamnesis } = this.props;;
        const formDataCopy = JSON.parse(JSON.stringify(anamnesis.form_builder_data));
        const answerKey = formDataCopy.answers.findIndex(a => a.uuid === answer.uuid);
        if (answerKey > -1) {
            formDataCopy.answers[answerKey] = answer;
        }
        const action = {
            form_builder_data: formDataCopy
        };
        setAnamnesisBasicProperty(action)(dispatch);
    }

    editAnamnesis = () => {
        const { dispatch, anamnesis, t } = this.props;
        if (anamnesis.form_builder_data.title !== '') {
            /*
                Double check if body answer has svgs
            */
            let error = {
                msg: 'Body answer has no svgs object property exsited inside of answer object'
            };
            const bodyAnswerIndex = getBodyAnswerIndex(anamnesis.form_builder_data.answers);
            if (bodyAnswerIndex > -1) {
                const bodyAnswer = anamnesis.form_builder_data.answers[bodyAnswerIndex];
                if (bodyAnswer.answer.svgs) {
                    error = null;
                }
            }
            // ===
            if (!error) {
                dispatch(updateAnamnesis(this.patient, this.anamnesis, anamnesis.form_builder_data, t));
            } else {
                this.setState({
                    error
                });
            }
        } else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='theme_confirm'>
                            <p className='theme_confirm__text'>{t('diagnose_required')}</p>
                            <div className="theme_confirm__cta single">
                                <button className="theme_btn small" onClick={onClose}>
                                    {t('back')}
                                </button>
                            </div>
                        </div>
                    );
                },
                afterClose: () => {
                    const autoSuggestElements = document.getElementsByClassName('react-autosuggest__input');
                    const autoSuggestElement = (autoSuggestElements && autoSuggestElements.length > 0) ? autoSuggestElements[0] : null;
                    if (autoSuggestElement) {
                        autoSuggestElement.focus();
                        autoSuggestElement.scrollIntoView();
                    }
                }
            });
        }
    }

    saveAnamnesis = () => {
        const { anamnesis, dispatch, t } = this.props;
        if (anamnesis.form_builder_data.title !== '') {
            /*
                Double check if body answer has svgs
            */
            let error = {
                msg: 'Body answer has no svgs object property exsited inside of answer object'
            };
            const bodyAnswerIndex = getBodyAnswerIndex(anamnesis.form_builder_data.answers);
            if (bodyAnswerIndex > -1) {
                const bodyAnswer = anamnesis.form_builder_data.answers[bodyAnswerIndex];
                if (bodyAnswer.answer.svgs) {
                    error = null;
                }
            }
            // ===
            if (!error) {
                dispatch(createAnamnesis(this.patient, anamnesis.form_builder_data, t));
            } else {
                this.setState({
                    error
                });
            }
        } else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='theme_confirm'>
                            <p className='theme_confirm__text'>{t('diagnose_required')}</p>
                            <div className="theme_confirm__cta single">
                                <button className="theme_btn small" onClick={onClose}>{t('back')}</button>
                            </div>
                        </div>
                    );
                },
                afterClose: () => {
                    const autoSuggestElements = document.getElementsByClassName('react-autosuggest__input');
                    const autoSuggestElement = (autoSuggestElements && autoSuggestElements.length > 0) ? autoSuggestElements[0] : null;
                    if (autoSuggestElement) {
                        autoSuggestElement.focus();
                        autoSuggestElement.scrollIntoView();
                    }
                }
            });
        }
    }

    /*
        Open / close sympthoms panel
    */
    toggleSympthoms = () => {
        const { misc, dispatch } = this.props;
        const action = {
            isSympthomsOpened: !misc.isSympthomsOpened
        };
        setMiscBasicProperty(action)(dispatch);
    }
    /*
        Symptom callback function
        Happens every time symptoms window is closed
    */
    symptomSlideCallback = (v) => {
        const { anamnesis } = this.props;
        const formBuilderDataCopy = JSON.parse(JSON.stringify(anamnesis.form_builder_data));
        const answers = formBuilderDataCopy.answers || [];
        const bodyAnswerIndex = getBodyAnswerIndex(answers);
        const bodyAnswer = answers[bodyAnswerIndex];
        if (bodyAnswer && bodyAnswer.answer.svgs) {
            /*
                Adjust body answer with sympthoms
            */
            if (Object.keys(v).length > 0) {
                if (v.svgs) {
                    const { dispatch } = this.props;
                    bodyAnswer.answer.svgs = v.svgs;
                    const action = {
                        form_builder_data: formBuilderDataCopy
                    };
                    setAnamnesisBasicProperty(action)(dispatch);
                }
            }
        } else {
            const error = {
                msg: 'Body answer has no svgs object property exsited inside of answer object'
            };
            this.setState({
                error
            });
        }
    }
    /*
        Close symptoms
    */
    closeSympthoms = () => {
        const { dispatch } = this.props;
        const action = {
            isSympthomsOpened: false
        };
        setMiscBasicProperty(action)(dispatch);
    }
    /*
        Save symptoms
    */
    saveSympthoms = () => {
        this.closeSympthoms();
    }

    renderLoaded = () => {
        const { misc, anamnesis, diagnoses, t } = this.props;
        const { error } = this.state;
        const age = getAge(this.patient.birthday);
        return (
            <>
                {(error && error.msg) ? (
                    <div className="app_container__inner">
                        <div className="alert alert--error">
                            <p className="alert__title">Error info:</p>
                            <div className="alert__body">
                                <strong>{error.msg}</strong>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        {misc.isSympthomsOpened ? (
                            <>
                                <AppTopNavBar
                                    isControlPanel={true}
                                    title={t('take_anamnesis')}
                                    leftNavigation = {
                                        <a className='icon-enabled-link' onClick = {this.closeSympthoms}>
                                            <span className='icon icon-navi-left'></span>
                                            {t('back')}
                                        </a>
                                    }
                                    rightNavigation = {
                                        <a onClick = {this.saveSympthoms}>{t('save')}</a>
                                    }
                                />
                                <div className="app_container__inner fullWidth whiteBackground">
                                    <>
                                        <SymptomModule
                                            editorConfig={this.editorConfig}
                                            symptomSlideCallback={this.symptomSlideCallback}
                                        />
                                    </>
                                </div>
                            </>
                        ) : (
                            <>
                                <AppTopNavBar
                                    isControlPanel={true}
                                    title={t('take_anamnesis')}
                                    leftNavigation = {
                                        <NavLink className='icon-enabled-link' to={this.anamnesis ? `/patients/${this.patientUuid}/anamneses/${this.anamnesisUuid}` : `/patients/${this.patientUuid}`}>
                                            <span className='icon icon-navi-left'></span>
                                            {t('back')}
                                        </NavLink>
                                    }
                                    rightNavigation = {
                                        <a onClick={this.anamnesis ? this.editAnamnesis : this.saveAnamnesis}>{t('save')}</a>
                                    }
                                />
                                <div className="app_container__inner">
                                    <div className={classNames('anamnese_create', { 
                                            'hidden_content': misc.isSympthomsOpened
                                        })}
                                    >
                                        <div className="anamnese_create_header">
                                            <div className="anamnese_create__info">
                                                <div className="anamnese_create__info__left">
                                                    <div className="info_cell">
                                                        <div className="info_cell__icon">
                                                            <img src={patientsListFolderImage} />
                                                        </div>
                                                        <div className="info_cell__text">
                                                            <p>{this.patient.firstName} {this.patient.surName}</p>
                                                            <p>{age + ' ' + t('years') +' | ' + t('birthday') + ': ' + formatDate(this.patient.birthday)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="anamnese_create__info__right">
                                                    <div className="control_cell">
                                                        <div className="control_cell__btn">
                                                            <a onClick={() => this.toggleSympthoms()}>
                                                                <img src={symtomeIcon} />
                                                                {t("anamnesis_symptoms")}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="anamnese_create_data">
                                            {/* <useOnlineStatus /> */}
                                            <AnamnesisBuilderForm
                                                patient={this.patient}
                                                formData={anamnesis.form_builder_data}
                                                diagnoses={diagnoses}
                                                transferData={this.formDataReceiver}
                                                transferAnamnesisTitle={this.formDataAnamnesisTitleReceiver}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </>
        );
    }

    render() {
        const { anamnesis } = this.props;
        return (
            <>
                { !anamnesis.isLoading && this.renderLoaded()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    doctorId: state.doctor.id,
    questionnaire: state.doctor.questionnaire,
    graph: state.graph,
    connection: state.connection,
    misc: state.misc,
    anamnesis: state.anamnesis,
    diagnoses: state.doctor.diagnoses
});

export default connect(mapStateToProps)(withTranslation('common')(AnamnesisBuilderModule));
