import React from 'react';
import classNames from 'classnames';
import graphDefaults from  '../../state/graph/graph.defaults';

const defineDegreeOfPain = (element) => {
    /*
      Define degree of pain
    */
    let degree_of_pain = element.degree_of_pain || null;
    if (!degree_of_pain) {
        switch(element.drawColor) {
            case 'Graph__pain-degree-1': {
                degree_of_pain = 1;
                break;
            }
            case 'Graph__pain-degree-2': {
                degree_of_pain = 2;
                break;
            }
            case 'Graph__pain-degree-3': {
                degree_of_pain = 3;
                break;
            }
            case 'Graph__pain-degree-4': {
                degree_of_pain = 4;
                break;
            }
        }   
    }
    /*
        Define the color of pain point
    */
    const fillColor = graphDefaults.painDegreesColors.fillColor;
    const strokeColor = graphDefaults.painDegreesColors.strokeColor;
    const opacity = 0.1 * degree_of_pain;
    const style = {
        opacity
    };
    /*
        Return needed object
    */
    return {
        fillColor,
        strokeColor,
        style
    };
}

class Tool {
  constructor(options) {
    this.addElement = options.addElement;
    this.isGrabbed = false;
  }

  getCommonProps(element) {
    return {
      className: classNames('', {"moving": element.isMoving }),
      key: element.uuid,
      pointerEvents: "bounding-box",
      ...element.svgProps,
    }
  }
  handleMouseDown() {
    throw new Error('You have to implement the method handleMouseDown!');
  }
  
  handleMouseMove() {
    return;
  }

  handleMouseUp() {
    throw new Error('You have to implement the method handleMouseUp!');
  }

  updatePosition() {
    throw new Error('You have to implement the method updatePosition!');
  }
}

class CrossHair extends Tool {
  constructor(options) {
    super(options);
    this.x = null;
    this.y = null;
  }

  handleMouseDown = (position) => {
    this.x = position.x;
    this.y = position.y;
  }

  handleMouseUp = (position = {}) => {
    if (Object.keys(position).length > 0) {
        this.x = position.x;
        this.y = position.y;
    }
    if (this.x == null || this.y == null) {
      //Happens when mouseUp is fired without a prior mouseDown event
      return;
    }
    const element = this.createElement(this.x, this.y);
    this.x = null;
    this.y = null;
    return element;
    // this.addElement(element);
  }  

  getSVG(element) {
    const painDegreeObject = defineDegreeOfPain(element);
    const s = element.scale || 1;
    const graphTypes = graphDefaults.graphTypes;
    const graphType = graphTypes.find(g => g.id === element.type);
    if (graphType) {
        return (
            <svg
                { ...this.getCommonProps(element) }
                style={painDegreeObject.style}
                id={'pain_point_' + element.uuid}
            >
                <circle
                    cx={element.x}
                    cy={element.y}
                    r="15"
                    fill={painDegreeObject.fillColor}
                    stroke={painDegreeObject.strokeColor}
                    strokeWidth="2"
                />
                <circle
                    cx={element.x}
                    cy={element.y}
                    r="11"
                    fill={painDegreeObject.strokeColor}
                    stroke={painDegreeObject.strokeColor}
                    strokeWidth="1"
                />
                <circle
                    cx={element.x}
                    cy={element.y}
                    r="5"
                    fill={painDegreeObject.strokeColor}
                    stroke={painDegreeObject.fillColor}
                    strokeWidth="2"
                />
                <circle
                    cx={element.x}
                    cy={element.y}
                    r="1"
                    fill={painDegreeObject.fillColor}
                    stroke={painDegreeObject.fillColor}
                    strokeWidth="1"
                />
                <g strokeWidth="2" stroke="white">
                    <line 
                        x1={element.x - element.offset*s} 
                        y1={element.y}
                        x2={element.x - element.innerOffset*s} 
                        y2={element.y}
                    />
                    <line 
                        x1={element.x + element.offset*s} 
                        y1={element.y}
                        x2={element.x + element.innerOffset*s} 
                        y2={element.y}
                    />
                    <line 
                        x1={element.x} 
                        y1={element.y + element.offset*s}
                        x2={element.x} 
                        y2={element.y + element.innerOffset*s}
                    />
                    <line 
                        x1={element.x} 
                        y1={element.y - element.offset*s}
                        x2={element.x} 
                        y2={element.y - element.innerOffset*s}
                    />
                </g>
            </svg>
        )
    }
    console.log('CrossHair graphtype is missing');
    return null;
  }

  createElement(x, y) {
    return  {
      type: 1,
      anchor: {
        x,
        y,
      },
      x: x,
      y: y,
      offset: 12,
      innerOffset: 4,
      staticColor: false,
    };
  }

  updatePosition = (element, position) => {
    const { x, y } = position;
    return {
      ...element,
      anchor: {
        x,
        y,
      },
      x: x,
      y: y,
    }
  }
}

class Arrow extends Tool {
  constructor(options) {
    super(options);
    this.setUnfinishedElement = options.setUnfinishedElement;
    this.x = 0;
    this.y = 0;
  }
  
  handleMouseDown = (position) => {
    const { x, y } = position;
    this.x = x;
    this.y = y;
    const element = this.createElement(this.x, this.y, x, y);
    if (typeof this.setUnfinishedElement === 'function') {
      this.setUnfinishedElement(element);
    }
    return element;
  }

  handleMouseMove = (position) => {
    const element = this.createElement(this.x, this.y, position.x, position.y);
    if (typeof this.setUnfinishedElement === 'function') {
      this.setUnfinishedElement(element);
    }
  }

  handleMouseUp = (position) => {
    const element = this.createElement(this.x, this.y, position.x, position.y);
    return element;
  }

  drawFilledPolygon = (ctx, shape) => {
    ctx.beginPath();
    ctx.moveTo(shape[0][0],shape[0][1]);
  
    for(let p in shape)
        if (p > 0) ctx.lineTo(shape[p][0],shape[p][1]);
  
    ctx.lineTo(shape[0][0],shape[0][1]);
    ctx.fill();
  };
  
  translateShape = (shape,x,y) => {
    var rv = [];
    for(let p in shape)
        rv.push([ shape[p][0] + x, shape[p][1] + y ]);
    return rv;
  };
  
  rotateShape = (shape,ang) => {
    var rv = [];
    for(let p in shape)
        rv.push(this.rotatePoint(ang,shape[p][0],shape[p][1]));
    return rv;
  };
  
  rotatePoint = (ang,x,y) => {
    return [
        (x * Math.cos(ang)) - (y * Math.sin(ang)),
        (x * Math.sin(ang)) + (y * Math.cos(ang))
    ];
  };

  getSVG(element) {
    const painDegreeObject = defineDegreeOfPain(element);
    const s = element.scale || 1;
    //Scale points
    const arrow = element.arrow.map((shape) => (
      [shape[0] * s, shape[1] * s]
    ));
    const x1 = element.coords.x1;
    const y1 = element.coords.y1;
    const x2 = element.coords.x2;
    const y2 = element.coords.y2;
    const ang = Math.atan2(y2 - y1, x2 - x1);
    const shape = this.translateShape(this.rotateShape(arrow, ang), x2, y2);
    const graphTypes = graphDefaults.graphTypes;
    const graphType = graphTypes.find(g => g.id === element.type);
    if (graphType) {
      return (
        <svg
          { ...this.getCommonProps(element) }
          style={painDegreeObject.style}
          id={'pain_point_' + element.uuid}
        >
          <circle
              cx={x1}
              cy={y1}
              r="15"
              fill={painDegreeObject.fillColor}
              stroke={painDegreeObject.strokeColor}
              strokeWidth="2"
          />
          <circle
              cx={x1}
              cy={y1}
              r="11"
              fill={painDegreeObject.strokeColor}
              stroke={painDegreeObject.strokeColor}
              strokeWidth="1"
          />
          <line 
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            fill={painDegreeObject.strokeColor}
            stroke={painDegreeObject.strokeColor}
            strokeWidth={3*s}
          />
          <polygon
            fill={painDegreeObject.strokeColor}
            points={`${shape[0][0]},${shape[0][1]} ${shape[1][0]},${shape[1][1]} ${shape[2][0]},${shape[2][1]}`}
          />
        </svg>
      );
    }
    return null;
  }

  createElement(x1, y1, x2, y2) {
    return {
      type: 2,
      anchor: {
        x: (x1 + x2) / 2,
        y: (y1 + y2) / 2,
      },
      coords: {
        x1,
        x2,
        y1,
        y2
      },
      arrow: [[ 6, 0 ], [ -6, -6 ], [ -6, 6]],
      staticColor: false,
    };
  }

  updatePosition = (element, position) => {
    const { x, y } = position;
    const offsetX = x - element.anchor.x;
    const offsetY = y - element.anchor.y;
    
    return {
      ...element,
      anchor: {
        x,
        y,
      },
      coords: {
        x1: element.coords.x1 + offsetX,
        x2: element.coords.x2 + offsetX,
        y1: element.coords.y1 + offsetY,
        y2: element.coords.y2 + offsetY,
      }
    }
  }
}

class Cross extends Tool {
  constructor(options) {
    super(options);
    this.x = null;
    this.y = null;
  }

  handleMouseDown = (position) => {
    this.x = position.x
    this.y = position.y
  }

  handleMouseUp = (position = {}) => {
    if (Object.keys(position).length > 0) {
        this.x = position.x;
        this.y = position.y;
    }
    if (this.x == null || this.y == null) {
      //Happens when mouseUp is fired without a prior mouseDown event
      return;
    }
    const element = this.createElement(this.x, this.y);
    this.x = null;
    this.y = null;
    return element;
    // this.addElement(element);
  }  

  getSVG(element) {
    const painDegreeObject = defineDegreeOfPain(element);
    const s = element.scale || 1;
    const graphTypes = graphDefaults.graphTypes;
    const graphType = graphTypes.find(g => g.id === element.type);
    if (graphType) {
      return (
        <svg
          { ...this.getCommonProps(element) }
          style={painDegreeObject.style}
          id={'pain_point_' + element.uuid}
        >
          <circle
              cx={element.x}
              cy={element.y}
              r="15"
              fill={painDegreeObject.fillColor}
              stroke={painDegreeObject.strokeColor}
              strokeWidth="2"
          />
          <circle
              cx={element.x}
              cy={element.y}
              r="11"
              fill={painDegreeObject.strokeColor}
              stroke={painDegreeObject.strokeColor}
              strokeWidth="1"
          />
          <g strokeWidth={3} stroke="white">
            <line 
              x1={element.x - element.offset*s} 
              y1={element.y - element.offset*s}
              x2={element.x + element.offset*s} 
              y2={element.y + element.offset*s}
            />
            <line 
              x1={element.x - element.offset*s} 
              y1={element.y + element.offset*s}
              x2={element.x + element.offset*s} 
              y2={element.y - element.offset*s}
            />
          </g>
        </svg>
      )
    }
    return null;
  }

  createElement(x, y) {
    return  {
      type: 3,
      anchor: {
        x,
        y,
      },
      x: x,
      y: y,
      offset: 8,
      staticColor: true,
    };
  }

  updatePosition = (element, position) => {
    const { x, y } = position;
    return {
      ...element,
      anchor: {
        x,
        y,
      },
      x: x,
      y: y,
    }
  }
}

// TODO: alter the drawings to match the requirements
class ChainCause extends Tool {
    constructor(options) {
        super(options);
        this.setUnfinishedElement = options.setUnfinishedElement;
        this.x = 0;
        this.y = 0;
    }

    handleMouseDown = (position) => {
        const { x, y } = position;
        this.x = x;
        this.y = y;
        const element = this.createElement(this.x, this.y, x, y);
        if (typeof this.setUnfinishedElement === 'function') {
            this.setUnfinishedElement(element);
        }
        return element;
    }

    handleMouseMove = (position) => {
        const element = this.createElement(this.x, this.y, position.x, position.y);
        if (typeof this.setUnfinishedElement === 'function') {
            this.setUnfinishedElement(element);
        }
    }

    handleMouseUp = (position) => {
        const element = this.createElement(this.x, this.y, position.x, position.y);
        return element;
    }

    getSVG(element) {
        const painDegreeObject = defineDegreeOfPain(element);
        const s = element.scale || 1;
        const offset = element.offset || 0;
        const x1 = element.coords.x1;
        const y1 = element.coords.y1;
        const x2 = element.coords.x2;
        const y2 = element.coords.y2;
        const graphTypes = graphDefaults.graphTypes;
        const graphType = graphTypes.find(g => g.id === element.type);
        if (graphType) {
            return (
                <svg
                    {...this.getCommonProps(element)}
                    style={painDegreeObject.style}
                    id={'pain_point_' + element.uuid}
                >
                    <circle
                        cx={x1}
                        cy={y1}
                        r="15"
                        fill={painDegreeObject.fillColor}
                        stroke={painDegreeObject.strokeColor}
                        strokeWidth="2"
                    />
                    <circle
                        cx={x1}
                        cy={y1}
                        r="11"
                        fill={painDegreeObject.strokeColor}
                        stroke={painDegreeObject.strokeColor}
                        strokeWidth="1"
                    />
                    <g strokeWidth={3} stroke="white">
                        <line 
                            x1={x1 - offset*s} 
                            y1={y1 - offset*s}
                            x2={x1 + offset*s} 
                            y2={y1 + offset*s}
                        />
                        <line 
                            x1={x1 - offset*s} 
                            y1={y1 + offset*s}
                            x2={x1 + offset*s} 
                            y2={y1 - offset*s}
                        />
                    </g>
                    <line
                        x1={x1}
                        y1={y1}
                        x2={x2}
                        y2={y2}
                        fill={painDegreeObject.strokeColor}
                        stroke={painDegreeObject.strokeColor}
                        strokeWidth={3 * s}
                    />
                    <circle
                        cx={x2}
                        cy={y2}
                        r="15"
                        fill={painDegreeObject.fillColor}
                        stroke={painDegreeObject.strokeColor}
                        strokeWidth="2"
                    />
                    <circle
                        cx={x2}
                        cy={y2}
                        r="11"
                        fill={painDegreeObject.strokeColor}
                        stroke={painDegreeObject.strokeColor}
                        strokeWidth="1"
                    />
                    <g strokeWidth={3} stroke="white">
                        <line 
                            x1={x2 - offset*s} 
                            y1={y2 - offset*s}
                            x2={x2 + offset*s} 
                            y2={y2 + offset*s}
                        />
                        <line 
                            x1={x2 - offset*s} 
                            y1={y2 + offset*s}
                            x2={x2 + offset*s} 
                            y2={y2 - offset*s}
                        />
                    </g>
                </svg>
            );
        }
        return null;
    }

    createElement(x1, y1, x2, y2) {
        return {
            type: 4,
            anchor: {
                x: (x1 + x2) / 2,
                y: (y1 + y2) / 2,
            },
            coords: {
                x1,
                x2,
                y1,
                y2
            },
            offset: 8,
            arrow: [[6, 0], [-6, -6], [-6, 6]],
            staticColor: false,
        };
    }

    updatePosition = (element, position) => {
        const { x, y } = position;
        const offsetX = x - element.anchor.x;
        const offsetY = y - element.anchor.y;

        return {
            ...element,
            anchor: {
                x,
                y,
            },
            coords: {
                x1: element.coords.x1 + offsetX,
                x2: element.coords.x2 + offsetX,
                y1: element.coords.y1 + offsetY,
                y2: element.coords.y2 + offsetY,
            }
        }
    }
}

export default class ToolCase {
  constructor(options = {}) {
    this.options = options;
    this.tools = [
      new CrossHair(options),
      new Arrow(options),
      new Cross(options),
      new ChainCause(options)
    ];
  }

  getTool(type) {
    if (type && this.tools.length >= type) {
      return this.tools[type-1];
    }
    return null;
  }
}