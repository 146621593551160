import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Form, Input, Button, validations } from '../utility/form';
import { login } from '../../state/doctor/doctor.actionCreators';

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.form = null;
    this.state = {
      username: '',
      password: '',
    };
  }

  setForm = (form) => {
    this.form = form;
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    
    if(this.form.validateAll()) {
      const { username, password } = this.state;
      const { dispatch, cachedPath } = this.props;
      const to = cachedPath || "/patients";
      dispatch(login(username, password, to, this.props.t))
    }
  };

  render() {
    const { t } = this.props;
    const _validations = validations(t);
    const { username, password } = this.state;

    return (
      <div className="auth_form">
        <div className="auth_form__inner">
          <p className="auth_form__head">{t("loginDescription")}</p>
          <div className="auth_form__body">
            <Form ref={this.setForm} onSubmit={this.handleSubmit} className="form">
              <div className="auth_form__body__element">
                <Input
                  label={`${t("username")}:`}
                  placeholder={`${t("username_placeholder")}`}
                  validations={[_validations.email_required]}
                  tabIndex="1"
                  type="text"
                  name="username"
                  value={username}
                  onChange={this.handleChange}
                />
              </div>
              <div className="auth_form__body__element">
                <Input
                  label={`${t("password")}:`}
                  placeholder={`${t("password_placeholder")}`}
                  link={<Link tabIndex="4" className="Form__input__label__link" to="/forgot-password">{t('forgotPassword')}</Link>}
                  validations={[_validations.password_required]}
                  tabIndex="2"
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                />
              </div>
              <div className="auth_form__body__cta">
                <Button tabIndex="3">
                  {t("login")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cachedPath: state.doctor.cachedPath,
});

export default connect(mapStateToProps)(withTranslation("common")(LoginForm));
