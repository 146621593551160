import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import common_de from "../assets/translations/de.json";
import common_en from "../assets/translations/en.json";

function formattedDate(date, language, options) {
  const formater = new Intl.DateTimeFormat(language, options);
  if (date instanceof Date) {
    return formater.format(date);
  } else {
    return formater.format(new Date(date));
  }
}

export const defaultLocale = "en"

export const locales = [
    {
        code: "de",
        name: "Deutsch",
        dir: "ltr"
    },
    {
        code: "en",
        name: "English",
        dir: "ltr"
    },
]

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to the react-i18next components.
  // Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'de',
    debug: true,

    react: {
      useSuspense: false
    },

    interpolation: { 
      escapeValue: false, // React already does escaping
      format: (value, format, lng) => {
        switch(format) {
          case 'longDate':
            return formattedDate(value, lng, { 
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            });
          case 'shortDate':
              return formattedDate(value, lng, { 
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              });
          case 'numericDate':
            return formattedDate(value, lng, { 
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            });
          default: 
            return value;
        }
      }
    },
    resources: {
      en: {
        common: common_en // 'common' is our custom namespace
      },
      de: {
        common: common_de
      },
    },
  });

export default i18n;