import { flashErrorMessage } from 'redux-flash';
import { push } from 'connected-react-router';
import { format } from 'date-fns';
import LocalApi from '../api/LocalApi';
import PatientApi from '../api/PatientApi';
import * as actionTypes from '../state/patients/patients.actionTypes';
import uuid from 'uuid';
import { submitHomeLessons } from './HomeLessons';
import { syncAnamneses } from './Anamneses';
import { syncRecords, jobQueue, getIsoTimeStamp, tryConnect, toObject, getItem} from './Sync';

function filesToObject(files) {
    const fileObject = {};
    for(const file of files) {
        fileObject[file.id] = file;
    }
    return fileObject;
}

export async function removePatient(patient, connection = {}) {
    const returnData = {
        data: null,
        error: null
    };
    try {
        /*
            Attempt to remove patient from server
        */
        const { isOnline } = connection;
        const isLocal = await tryConnect(isOnline, async () => {
            const patient_status = await PatientApi.remove(patient.uuid);
        }, async () =>  {
            return null;
        })[1];
        /*
            Remove patient from the local API
        */
        await LocalApi.removeItem(`patients_${patient.uuid}`);
        /*
            Get patients after delete
        */
        const localPatientUuids = await LocalApi.getItem('patients') || [];
        const localPatients = toObject(await Promise.all(localPatientUuids.map((uuid) => LocalApi.getItem(`patients_${uuid}`))));
        const patients = Object.values(localPatients);
        returnData.data = patients;
        return returnData;
    } catch (e) {
        returnData.error = e.message;
        return returnData;
    }
}

/*
    Invoked everytime time you load / reload any app page
    And if you you are going from offline to online mode
*/
export function syncPatients(doctorId) {
    return async (dispatch, getState) => {
        const { isOnline } = getState().connection;
        /*
            Get patientss from the local IndexDB
        */
        const localPatientUuids = await LocalApi.getItem('patients') || [];
        const localPatients = toObject(await Promise.all(localPatientUuids.map((uuid) => LocalApi.getItem(`patients_${uuid}`))));
        return (await tryConnect(isOnline, async () => {
            /*
                Put the homelessons to the state
            */
            await dispatch(submitHomeLessons());
            /*
                Get patients from the server
            */
            const remotePatients = await PatientApi.getByDoctor(doctorId);
            /*
                Sync process
                Returns the object in key / value format
                {
                    [uuid]: Object
                }
            */
            const patientsSyncResults = syncRecords(localPatients, remotePatients);
            const patients = Object.values(patientsSyncResults);
            const patientsToInject = patients.map(patient => patient.uuid);
            /*
                Check for the error
            */
            if (patientsToInject.length === 0 && remotePatients.length > 0) {
                dispatch({
                    type: 'SET_ERROR',
                    error: 'syncPatients - No patients to inject into IndexDB , while server does have a patients'
                });
            }
            // ===
            LocalApi.setItem('patients', patientsToInject);
            const updatedPatients = patients.filter((patient => {
                return patient.syncState !== "synced";
            }));
            updatedPatients.forEach((patient) => jobQueue.push(syncPatient(patient)));
            jobQueue.start();
            return patients;
        }, async () => {
            return Object.values(localPatients);
        }))[0];
    }
}

export function syncOnePatient(patient) {
    return async (dispatch, getState) => {
        const { isOnline } = getState().connection;
        const localPatientUuids = await LocalApi.getItem('patients') || [];
        const localPatients = toObject(await Promise.all(localPatientUuids.map((uuid) => LocalApi.getItem(`patients_${uuid}`))));
        return (await tryConnect(isOnline, async () => {
            await dispatch(submitHomeLessons());
            const remotePatients = [patient]
            const patients = Object.values(syncRecords(localPatients, remotePatients));
            const updatedPatients = patients.filter((patient => {
                return patient.syncState !== "synced";
            }));
            LocalApi.setItem('patients', patients.map(patient => patient.uuid));
            updatedPatients.forEach((patient) => jobQueue.push(syncPatient(patient)));
            jobQueue.start();
            return patients;
        }, async () => {
            return Object.values(localPatients);
        }))[0];
    }
}

export function syncPatient(patient) {
    return {
        key: `patients_${patient.uuid}`,
        task: async () => {
            const files = await PatientApi.getFiles(patient.id);
            LocalApi.setItem(`patients_${patient.uuid}_files`, filesToObject(files));

            let updatePromise = null;
            if(patient.syncState === "pending") {
                if(patient.resourceState === "new") {
                    updatePromise = PatientApi.create(patient);
                } else {
                    updatePromise = PatientApi.update(patient.uuid, patient);
                }
        
            }
            const resolve = patient => {
                if(patient) {
                    LocalApi.updateItem(`patients_${patient.uuid}`, {
                        ...patient,
                        resourceState: null,
                        syncState: "synced",
                        updatedAtOriginal: null,
                    });
                }
            };
            await LocalApi.persistItem(`patients_${patient.uuid}`, patient);
            jobQueue.scheduleNow(syncAnamneses(patient.uuid, updatePromise, resolve));
            return patient;
        }
    }
}

export function getPatient(uuid) {
  return async (dispatch, getState) => {
    const patient = await getItem(`patients_${uuid}`);
    if(patient) {
        return patient;
    } else {
        return null;
    }
  }
}

export function createPatient(data, t) {
    return async (dispatch, getState) => {
        try {
            const { isOnline } = getState().connection;
            const patients = getState().patients;
            data.birthday = format(data.birthday,"yyyy-MM-dd").split('T')[0];
            data.gender = 'null';
            const patient = (await tryConnect(isOnline, async () => {
                return await PatientApi.create(data);
            }, async () => {
                const now = getIsoTimeStamp();
                const dataForLocalStorage = {
                    ...data,
                    resourceState: "new",
                    uuid: uuid.v4(),
                    createdAt: now,
                    updatedAt: now,
                };
                return dataForLocalStorage;
            }))[0];
            LocalApi.pushToItem('patients', patient.uuid);
            LocalApi.setItem(`patients_${patient.uuid}`, { ...patient });
            const updatedPatients = [ ...patients, patient ];
            updatedPatients.sort((a, b) => {
                const dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
                return dateB - dateA;
            });
            dispatch({
                type: actionTypes.SET_PATIENTS,
                patients: updatedPatients,
            });
            dispatch(push({pathname: `/patients/${patient.uuid}`, patient}));
        } catch(error) {
          dispatch(flashErrorMessage(error.message));
        }
    }
}

export function updatePatient(patient, data, t) {
    return async (dispatch, getState) => {
        try {
            const { isOnline } = getState().connection;
            const patients = getState().patients;
            if (data.birthday) {
                data.birthday = format(data.birthday,"yyyy-MM-dd").split('T')[0];//right
            }
            if (data.genderTypeId) {
                data.genderTypeId = parseInt(data.genderTypeId, 10);
            }
            
            const updatedPatient = (await tryConnect(isOnline, async () => {
                return await PatientApi.update(patient.uuid, data);
            }, async () => {
                const now = getIsoTimeStamp();
                return {
                    ...data,
                    id: patient.id,
                    uuid: patient.uuid,
                    updatedAt: now,
                    updatedAtOriginal: patient.updatedAtOriginal || patient.updatedAt,
                }
            }))[0];
            await LocalApi.setItem(`patients_${patient.uuid}`, updatedPatient);
            Object.assign(patient, updatedPatient);
            dispatch({
                type: actionTypes.SET_PATIENTS,
                patients: patients.map((p) => p.uuid === updatedPatient.uuid ? updatedPatient : p),
            });
            dispatch(push({pathname: `/patients/${patient.uuid}`, patient}));
        } catch(error) {
            dispatch(flashErrorMessage(error.message));
        }
    }
}

export async function addFiles(patient, files, fileName) {
    const updatedAt = getIsoTimeStamp();
    const createdFiles = await PatientApi.addFiles(patient.id, files, updatedAt, fileName);
    const localFiles = await LocalApi.getItem(`patients_${patient.uuid}_files`) || {};
    for(const file of createdFiles) {
        localFiles[file.id] = file;
    }
    await LocalApi.setItem(`patients_${patient.uuid}_files`, localFiles);
    await LocalApi.updateItem(`patients_${patient.uuid}`, { 
        updatedAt,
    });
    return createdFiles;
}

export async function removeFiles(patient, files) {
    const updatedAt = getIsoTimeStamp();
    return await Promise.all([
        PatientApi.removeFiles(patient.id, { fileIds: files.map(f => f.id), updatedAt }),
        LocalApi.getItem(`patients_${patient.uuid}_files`).then((localFiles) => {
            if(localFiles) {
                for(const file of files) {
                    delete localFiles[file.id];
                }
            }
            LocalApi.setItem(`patients_${patient.uuid}_files`, localFiles);
        }),
        LocalApi.updateItem(`patients_${patient.uuid}`, { 
            updatedAt,
        }),
    ]);
}

export async function removeFile(patient, file) {
    const updatedAt = getIsoTimeStamp();
    return await Promise.all([
        PatientApi.removeFile(patient.id, file.id, updatedAt),
        LocalApi.getItem(`patients_${patient.uuid}_files`).then((localFiles) => {
            if(localFiles) {
                delete localFiles[file.id];
            }
            LocalApi.setItem(`patients_${patient.uuid}_files`, localFiles);
        }),
        LocalApi.updateItem(`patients_${patient.uuid}`, { 
            updatedAt,
        }),
    ]);
}