import api from '../config/api';

const Questionnaire = () => {
    const getAll = () => {
        return api.get('/api/questionnaires');
    };

    const getByDoctor = async (doctorId) => {
        const response = await api.get(`/api/doctors/${doctorId}/questionnaires`);
        return response.data;
    };

    const getOne = (id) => {
        return api.get('/api/questionnaires/'+id);
    };

    const create = (data) => {
        return api.post('/api/questionnaires', data);
    };

    const update = (data, id) => {
        return api.put('/api/questionnaires/'+id, data);
    };

    const remove = (id) => {
        return api.delete('/api/questionnaires/'+id);
    };

    return {
        getAll,
        getByDoctor,
        getOne,
        create,
        update,
        remove
    }

};

export default Questionnaire();
