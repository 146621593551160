import * as actionTypes from './graph.actionTypes';
import graphDefaults from  './graph.defaults';

/*
    Elements creation form defaults
*/
const defaultSvg = [
    {
        elements: [],
        viewBox: {
            width: 960,
            height: 775
        }
    }
];
const painDegreesColors = graphDefaults.painDegreesColors;
const graphTypes = graphDefaults.graphTypes;
const defaultGraphType = graphTypes.find(e => e.id === 1);

const initialStateTemplate = {
    imageUrl: '',
    isTutorialActive: true,
    graphTypes,
    defaultGraphType,
    painDegreesColors,
    grabbedElement: null,
    selectedElement: null,
    grabOffset: null,
    viewBox: {},
    svgs: [],
    selectedSvg: {},
    defaultSvg,
    editorConfig: graphDefaults.editorConfig
};

export const initialState = initialStateTemplate;

function graph(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_GRAPH_ELEMENT: {
        return { ...state, elements: action.elements };
    }
    case actionTypes.SET_BASIC_PROPERTY: {
        const payload = action.action;
        const propertyName = Object.keys(payload)[0];
        const propertyObject = Object.defineProperty({}, propertyName, {
            value: payload[propertyName],
            writable: true,
            enumerable: true,
            configurable: true
        });
        return { ...state , ...propertyObject};
    }
    case actionTypes.SET_INIT: {
        return {...initialStateTemplate};
    }
    default:
      return state;
  }
}

export default graph;
