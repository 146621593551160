import * as actionTypes from './anamnesis.actionTypes';

/*
    Defaults
*/
const initialState = {
    form_builder_data: {},
    isLoading: true,
    isSympthomsOpened: false
};

function anamnesis(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_BASIC_PROPERTY: {
        const payload = action.action;
        const propertyName = Object.keys(payload)[0];
        const propertyObject = Object.defineProperty({}, propertyName, {
            value: payload[propertyName],
            writable: true,
            enumerable: true,
            configurable: true
        });
        return { ...state , ...propertyObject};
    }
    default:
      return state;
  }
}

export default anamnesis;
