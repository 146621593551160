/*
    React core modules imports / side modules imports
*/
import React, { useState, useRef, useEffect } from 'react';
import SpeechRecognitionBuilder from '../utility/SpeechRecognitionBuilder';
const speechRecognitionBuilderInstance = new SpeechRecognitionBuilder();

const SpeechRecognition = (props) => {
    const speechRecognizer = useRef();
    const timeoutIntervalId = useRef();
    const [isStartRecognitionEnabled, setStartRecognitionEnabled] = useState(true);
    const [isStopRecognitionEnabled, setStopRecognitionEnabled] = useState(false);
    const {
        transferRecognitionResult,
        classAdditionalName,
        deactivateRecognition,
    } = props;

    useEffect(() => {
        stopRecording(true);
    }, [deactivateRecognition]);

    // speech recognition events
    const onSpeechRecognizing = (sender, recognitionEventArgs) => {
        const result = recognitionEventArgs.result;
        const recognizedText = result.text;
        const recognizedTextDotsFree = recognizedText.replaceAll('.', '');
        // update the answer
        if (recognizedTextDotsFree) {
            // destory speech activity interval and reinit process again
            initSpeechActivityInterval();
            // ===
            transferRecognitionResult(recognizedTextDotsFree);
        }
    };
    const onSpeechSessionStarted = (sender, sessionEventArgs) => {
        setStartRecognitionEnabled(false); // disabled mic icon
        setStopRecognitionEnabled(true); // enable mouseleave cancellation
    };
    const onSpeechSessionStopped = (sender, sessionEventArgs) => {
        setStartRecognitionEnabled(true); // enable mic icon
        setStopRecognitionEnabled(false); // disable mouseleave cancelation
    };

    // speech activity interval
    const initSpeechActivityInterval = () => {
        if (timeoutIntervalId.current) {
            clearInterval(timeoutIntervalId.current);
            timeoutIntervalId.current = null;
        }
        const speechActivityStartTime = Date.now();
        timeoutIntervalId.current = setInterval(() => {
            const currentTime = Date.now();
            const timeDiff = currentTime - speechActivityStartTime;
            const timeElapsedSeconds = ((timeDiff % 60000) / 1000).toFixed(0);
            if (timeElapsedSeconds >= 30) {
                stopRecording(true);
            }
        }, 1000);
    };

    // start / stop recording handlers
    const startRecording = async () => {
        if (!speechRecognizer.current) {
            // build speech recognition class instance
            const recognitionObject = await speechRecognitionBuilderInstance.build();
            // define events and handlers
            recognitionObject.recognized = onSpeechRecognizing;
            // recognitionObject.recognizing = onSpeechRecognizing;
            recognitionObject.sessionStarted = onSpeechSessionStarted;
            recognitionObject.sessionStopped = onSpeechSessionStopped;
            // save recognition instance to the state
            speechRecognizer.current = recognitionObject;
            // start speech recognition
            if (isStartRecognitionEnabled) {
                recognitionObject.startContinuousRecognitionAsync();
            }
        }
    };
    const stopRecording = (forceStop = false) => {
        if (timeoutIntervalId.current) {
            clearInterval(timeoutIntervalId.current);
            timeoutIntervalId.current = null;
        }
        if ((speechRecognizer.current && forceStop) || (speechRecognizer.current && isStopRecognitionEnabled)) {
            speechRecognizer.current.stopContinuousRecognitionAsync(
                () => {
                    speechRecognizer.current.close();
                    speechRecognizer.current = null;
                },
                (error) => {
                    speechRecognizer.current.close();
                    speechRecognizer.current = null;
                }
            );
        }
    };

    // toggle recording / actual icon click handler
    const toggleRecording = () => {
        if (!isStartRecognitionEnabled) {
            stopRecording();
        } else {
            // run speech recognition interval for checking activity
            initSpeechActivityInterval();
            // start recording
            startRecording();
        }
    };

    const classNameBuilder = () => {
        const classesArray = [
            'speech_recognition_control'
        ];
        if (isStartRecognitionEnabled) {
            classesArray.push('speech_recognition_control--record');
        } else {
            classesArray.push('speech_recognition_control--record listening');
        }
        if (classAdditionalName) {
            classesArray.push(classAdditionalName);
        }
        return classesArray.join(" ");
    };

    return (
        <div
            onClick = {toggleRecording}
            className = {classNameBuilder()}
        />
    );
}

export default SpeechRecognition;
