import Cookie from 'universal-cookie';
import SpeechRecognitionApi from '../../api/SpeechRecognitionApi';

const speechsdk = require('microsoft-cognitiveservices-speech-sdk');

class SpeechRecognitionBuilder {
    constructor(options = {}) {
        this.options = options;
        return this;
    }

    async getTokenOrRefresh() {
        const cookie = new Cookie();
        const speechToken = cookie.get('speech-token');

        if (speechToken === undefined) {
            try {
                const res = await SpeechRecognitionApi.getSpeechRecognitionToken();
                const token = res.token || null;
                const region = res.tokenRegion || null;
                cookie.set('speech-token', region + ':' + token, {maxAge: 540, path: '/'});
                return { token, region };
            } catch (err) {
                return { token: null, region: null, error: err.response.data };
            }
        } else {
            const idx = speechToken.indexOf(':');
            const returnObject = {
                token: speechToken.slice(idx + 1),
                region: speechToken.slice(0, idx)
            };
            return returnObject;
        }
    }

    async build() {
        const response = await this.getTokenOrRefresh();
        const token = response.token || null;
        const tokenRegion = response.region || null;
        const tokenObjectData = {
            token,
            tokenRegion
        };
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObjectData.token, tokenObjectData.tokenRegion);
        speechConfig.speechRecognitionLanguage = 'de-DE';
        const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
        this.speechRecognizerObject = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
        return this.speechRecognizerObject;
    }
};

export default SpeechRecognitionBuilder;
