import localforage from 'localforage'
import { updateRecord } from '../actions/Sync';

class LocalApi {
  static async getItem(key) {
    return await localforage.getItem(key);
  }

  static async setItem(key, value)  {
    return await localforage.setItem(key, value);
  }

  static async removeItem(key) {
    return await localforage.removeItem(key);
  }

  static async persistItem(key, value) {
    if(value.syncState === "pendingLocal") {
      value.syncState = "synced";
    }
    return await localforage.setItem(key, value);
  }

  static async pushToItem(key, value) {
    const item = await localforage.getItem(key) || [];
    item.push(value);
    return await localforage.setItem(key, item);
  }

  static async updateItem(key, value) {
    const record = await localforage.getItem(key) || {};
    return localforage.setItem(key, {...record, ...value});
  }

  static async persistRecords(key, records) {
    for(let record of Object.values(records)) {
      if(record.syncState === "pendingLocal") {
        record.syncState = "synced";
      }
    }
    return await localforage.setItem(key, records); 
  }

  static async setRecords(key, values) {
    const records = {}
    for(let value of values) {
      records[value.uuid] = value;
    }
    return await localforage.setItem(key, records);
  }

  static async updateAll(key, values) {
    const records = await localforage.getItem(key) || {};
    for(let value of values) {
      if(value.deleted) {
        delete records[value.uuid]
      } else {
        updateRecord(records, value.uuid, value);
      }
    }
    return await localforage.setItem(key, records);
  }

  static async addRecord(key, value) {
    const records = await localforage.getItem(key) || {};
    records[value.uuid] = value;
    return await localforage.setItem(key, records);
  }
  static async deleteRecord(key, uuid) {
    const records = await localforage.getItem(key) || {};
    updateRecord(records, uuid, { resourceState: 'deleted' });
    return await localforage.setItem(key, records);
  }
  static async updateRecord(key, uuid, value) {
    const records = await localforage.getItem(key);
    updateRecord(records, uuid, value);
    return await localforage.setItem(key, records);
  }
}
export default LocalApi;