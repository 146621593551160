/*
    TODO:
    AnamnesisBuilderAnswer within that component !!!
    Maintain component state !!!
*/

/*
    React core modules imports / side modules imports
*/
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

/*
    Components
*/
import Relevance from './Relevance';
import AnamnesisBuilderAnswer from './AnamnesisBuilderAnswer';

function AnamnesisBuilderQuestion(props) {
    const {
        isTouch,
        patient,
        disabled,
        emitData,
        emitRootData,
        onDeactivateOtherRecognitionsForm,
        answersRecognitionUuids
    } = props; 

    const [answer, setAnswer] = useState({});

    useEffect(() => {
        setAnswer(props.answer);
    }, []);

    const onChangeAnswerData = (answer) => {
        if (typeof emitData === 'function') {
            emitData(answer);
        }
    };

    const onChangeRootAnswerData = (data) => {
        if (typeof emitData === 'function') {
            const answerCopy = JSON.parse(JSON.stringify(answer));
            const updateAnswer = { ...answerCopy, ...data};
            emitRootData(updateAnswer);
            setAnswer(updateAnswer);
        }
    };

    const onDeactivateOtherRecognitions = (uuid, isStopped = false) => {
        onDeactivateOtherRecognitionsForm(uuid, isStopped);
    };

    if (answer && answer.question) {
        const questionTypeId = answer.question.questionTypeId;
        return (
            <div className="question">
                {questionTypeId && questionTypeId === 6 ? (
                    <div className="question__title question__title--inlarge">
                        <p>{answer.question.content}</p>
                    </div>
                ) : (
                    <div className="question__title">
                        <p>{answer.question.content}</p>
                        <div className="relevance">
                            <Relevance
                                relevance={answer.relevant}
                                onChangeRelevant={disabled ? null : onChangeRootAnswerData}
                            />
                        </div>
                    </div>
                )}
                <div className="question__body">
                    <AnamnesisBuilderAnswer
                        isTouch={isTouch}
                        patient={patient}
                        answer={answer}
                        disabled={disabled}
                        onChangeAnswer={disabled ? null : onChangeAnswerData}
                        onDeactivateOtherRecognitions={onDeactivateOtherRecognitions}
                        answersRecognitionUuids={answersRecognitionUuids}
                    />
                </div>
            </div>
        );
    }
    return null;
}

export default withTranslation("common")(AnamnesisBuilderQuestion);
