import { useEffect } from 'react';
import ApiAuth from '../../api/ApiAuth';

const AuthDetector = ({
    expiresIn,
    exposeWarning
}) => {
    const runDetector = () => {
        const timeoutIntervalId = setInterval(async () => {
            const startTimeInSeconds = localStorage.getItem('timeStampSeconds') ? parseInt(localStorage.getItem('timeStampSeconds'), 10) : null;
            const endTimeInSeconds = startTimeInSeconds + expiresIn;
            if (startTimeInSeconds) {
                const currentTimeStampSeconds = Math.floor(new Date().getTime() / 1000);
                const deadlineDiffSec = endTimeInSeconds - currentTimeStampSeconds;
                const deadlineDiffMin = Math.floor(deadlineDiffSec / 60);
                if (deadlineDiffMin < 15) {
                    exposeWarning(deadlineDiffMin);
                }
            }
            await ApiAuth.isAuthenticated();
        }, 1000);
        return timeoutIntervalId;
    };
    useEffect(() => {
        const timeOutIntervalId = runDetector();
        return () => {
            clearInterval(timeOutIntervalId);
        };
    }, []);
    
    return null;
};

export default AuthDetector;
