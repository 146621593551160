import { flashErrorMessage } from 'redux-flash';
import CryptoJS from 'crypto-js';
import { push } from 'connected-react-router';
import ApiConfig from '../../config/ApiConfig';
import ApiAuth from "../../api/ApiAuth";
import QuestionnaireApi from '../../api/QuestionnaireApi';
import DiagnosisApi from '../../api/DiagnosisApi';
import HomeLessonApi from '../../api/HomeLessonApi';
import LocalApi from '../../api/LocalApi';
import { tryConnect, toObject } from '../../actions/Sync';
import * as actionTypes from './doctor.actionTypes';

export function getBodyImageKey(gender) {
  // Get LocalApi image key by gender. Accessed by Graph component.
  return `body_image_${gender}`
}

async function loadData(doctorId) {
  const questionnaire = await QuestionnaireApi.getByDoctor(doctorId);
  for(let question of questionnaire.questions) {
    if(question.options && question.options.images) {
      const { images } = question.options;
      for (let [key, image] of Object.entries(images)) {
        const baseURL = process.env.NODE_ENV === 'production'? ApiConfig.baseProURL: ApiConfig.baseDevURL;
        const imageUrl = image ? `${baseURL}/static/body_images/${image}` : null;
        const loadedImage = await fetch(imageUrl);
        await LocalApi.setItem(getBodyImageKey(key), loadedImage.blob());
      }
    }
  }
  LocalApi.removeItem('body.PNG') //Old body image key. This line can be removed when all doctors have logged in again.
  const homeLessons = await HomeLessonApi.getByDoctor(doctorId);
  const diagnoses = await DiagnosisApi.getAll();
  return {
    type: actionTypes.UPDATE_DATA,
    questionnaire,
    diagnoses,
    homeLessons: toObject(homeLessons),
  }
}

async function loginAction(dispatch, to, t, id, name, accessToken, encryptionKey, expiresIn) {
  dispatch({ type: actionTypes.LOGIN, id, name, accessToken, encryptionKey, expiresIn });
  dispatch(push(to));
  var timeout = parseInt(expiresIn);
  const timeStampsSeconds = Math.floor(new Date().getTime() / 1000)
  localStorage.setItem('timeStampSeconds', timeStampsSeconds);
  if(timeout > Number.MAX_SAFE_INTEGER / 1000) {
    timeout = Number.MAX_SAFE_INTEGER;
  } else {
    timeout *= 1000;
  }
  setTimeout(() => {
    dispatch(expireSession());
  }, timeout);
  dispatch(await loadData(id));
}

export function expireSession() {
  return async (dispatch, getState) => {
    dispatch({ 
      type: actionTypes.RESET,
      cachedPath: getState().router.location.pathname 
    });
    dispatch(flashErrorMessage("You have been inactive for 6 hours and have been logged out for security reasons. Please log in again."));
  } 
}

export function refreshQuestionnaire(doctorId) {
  return async (dispatch, getState) => {
    const { isOnline } = getState().connection;

    const questionnaire = (await tryConnect(isOnline, async () => {
      return await QuestionnaireApi.getByDoctor(doctorId);
    }, async () => {
      return null;
    }))[0];

    if(questionnaire) {
      return await dispatch({
        type: actionTypes.UPDATE_DATA,
        questionnaire,
      });
    } else {
      return null;
    }
  }
}

export function refreshHomeLessons() {
  return async (dispatch, getState) => {
    const { isOnline } = getState().connection;
    const { id } = getState().doctor;

    const homeLessons = (await tryConnect(isOnline, async () => {
      return await HomeLessonApi.getByDoctor(id);
    }, async () => {
      return null;
    }))[0];

    if(homeLessons) {
      return await dispatch({
        type: actionTypes.UPDATE_DATA,
        homeLessons: toObject(homeLessons),
      });
    } else {
      return null;
    }
  }
}

export function login(username, password, to, t) {
  return async (dispatch) => {
    try {
      const { 
        id, 
        name, 
        accessToken, 
        encryptionKeyMirror,
        expiresIn,
      } = await ApiAuth.login({ name: username, password });
      const encryptionKey = CryptoJS.AES.decrypt(encryptionKeyMirror, password).toString(CryptoJS.enc.Utf8);
      loginAction(dispatch, to, t, id, name, accessToken, encryptionKey, expiresIn);
    } catch (error) {
      // const message = error.response
      //   ? error.response.data.error
      //   : t("noServerConnection");
      const message = error.response
        ? 'E-Mail Adresse oder Passwort ist nicht korrekt. Bitte versuche es erneut.'
        : t("noServerConnection");
      dispatch(flashErrorMessage(message));
    }
  };
}

export function createPassword(doctorId, password, encryptionKey, to, t) {
  return async (dispatch) => {
    try {
      const encryptionKeyMirror = CryptoJS.AES.encrypt(encryptionKey, password).toString();
      const { 
        id, 
        name, 
        accessToken,
        expiresIn
      } = await ApiAuth.createPassword({ id: doctorId, password, encryptionKeyMirror });
      loginAction(dispatch, to, t, id, name, accessToken, encryptionKey, expiresIn);
    } catch (error) {
      const message = error.response
        ? error.response.data.error
        : t("noServerConnection");;
      dispatch(flashErrorMessage(message));
    }
  }
}

export function logout(t) {
  return async (dispatch) => {
    dispatch({ type: 'RESET' });
    dispatch({ type: actionTypes.LOGOUT });
    dispatch(push('/'));
  };
}

export const refreshDoctorData = (accessToken) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_ACCESS_TOKEN, accessToken });
  }
};
