import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaFile } from 'react-icons/fa';
import { MdClose, MdCloudUpload } from 'react-icons/md';
import { withTranslation } from 'react-i18next';
import Button from '../utility/Button';
import LoadingDots from '../utility/LoadingDots';
import './FileDropzone.scss';

function FileDropzone(props) {
  const t = props.t;
  const [ files, setFiles ] = useState([]);
  const [ upload, setUpload ] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    setFiles([...files, ...acceptedFiles]);
  }, []);

  function deleteFile(index) {
    setFiles([...files.slice(0, index), ...files.slice(index+1)]);
  }

  async function onUpload(e) {
    e.preventDefault();
    e.stopPropagation();
    setUpload(true)
    await props.onSubmit(files);
    setFiles([]);
    setUpload(false)
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop})
  const filePlaceholder = t("placeOrSelectFiles").split('\n');

  return (
    <div className="FileDropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      { files.length ? (
        <div className="FileDropzone__active">
          <div className="FileDropzone__files">
            { upload ? (
              <LoadingDots className="FileDropzone__loading-dots"/>
            ) : (
              files.map((file, index) => (
                <div key={index} className="FileDropzone__file">
                  <button
                    className="FileDropzone__file__delete"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      deleteFile(index);
                    }}
                  >
                    <MdClose />
                  </button>
                  <FaFile className="FileDropzone__file__icon" />
                  <span className="FileDropzone__file__name">{file.name}</span>
                </div>
              )))
            }
          </div>
          <Button className="FileDropzone__submit" onClick={onUpload}>
            <MdCloudUpload />
            {t("upload")}
          </Button>
        </div>
      ) : (
        <span className="FileDropzone__text">
          {filePlaceholder[0]}
          <br />
          {filePlaceholder[1]}
          <br />
          {filePlaceholder[2]}
        </span>
      )}
    </div>
  );
}

export default withTranslation("common")(FileDropzone);
