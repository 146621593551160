import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Patients from './patients/Patients';
import PatientCreate from "./patients/PatientCreate";
import PatientEdit from "./patients/PatientEdit";
import PatientDetail from "./patients/PatientDetail";
import AnamnesisBuilderModule from "./anamnesis/AnamnesisBuilderModule";
import AnamnesisDetailsNew from "./anamnesis/AnamnesisDetailsNew";
import Symptoms from "./anamnesis/Symptoms";
import TreatmentModule from "./treatments/TreatmentModule";
import Files from './files/Files';
import speechRecognitionTest from "./anamnesis/speechRecognitionTest";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Redirect exact from="/" to="/patients" />
        <Route exact path="/patients" component={Patients} />
          <Route exact path="/speech/test" component={speechRecognitionTest} />
          <Route exact path="/patients/create" component={PatientCreate} />
          <Route exact path="/patients/:uuid/edit" component={PatientEdit} />
          <Route exact path="/patients/:uuid/files" component={Files} />
          <Route exact path="/patients/:uuid" component={PatientDetail} />
          <Route exact path="/patients/:uuid/anamneses/create" component={AnamnesisBuilderModule}/>
          <Route exact path="/patients/:uuid/anamneses/:anamnesisUuid/edit" component={AnamnesisBuilderModule}/>
          <Route exact path="/patients/:uuid/anamneses/:anamnesisUuid" component={AnamnesisDetailsNew}/>
          <Route exact path="/patients/:uuid/anamneses/:anamnesisUuid/symptoms" component={Symptoms}/>
          <Route exact path="/patients/:uuid/anamneses/:anamnesisUuid/treatments/create" component={TreatmentModule}/>
          <Route exact path="/patients/:uuid/anamneses/:anamnesisUuid/treatments/:treatmentUuid/edit" component={TreatmentModule}/>
        <Route component={Patients}/>
      </Switch>
    );
  }
}

export default Routes;
