import React from 'react';
import validator from 'validator';

export const validations = t => ({
  required: (value) => {
    if (!value.toString().trim().length) {
      // We can return string or jsx as the 'error' prop for the validated Component
      return <span className="Form__input__error">{t('required')}</span>
    }
  },
  email_required: (value) => {
    if (!value.toString().trim().length) {
      // We can return string or jsx as the 'error' prop for the validated Component
      return <span className="Form__input__error">{t('email_required')}</span>
    }
  },
  password_required: (value) => {
    if (!value.toString().trim().length) {
      // We can return string or jsx as the 'error' prop for the validated Component
      return <span className="Form__input__error">{t('password_required')}</span>
    }
  },
  email: (value) => {
    if (!validator.isEmail(value)) {
      return <span className="Form__input__error">{t('validEmail', { value })}</span>
    }
  },
});