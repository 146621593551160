const painDegreesColors = {
    fillColor: '#fff',
    strokeColor: '#00A999'
};

export default {
    painDegreesColors,
    editorConfig: {
        mode: 'edit',
        isFullScreenMode: true
    },
    graphTypes: [
        {
            id: 1,
            desc: 'Schmerzpunkt',
            degree_of_pain: 1,
            drawing: {
                circle: {
                    strokeColor: painDegreesColors.strokeColor,
                    fillColor: painDegreesColors.fillColor
                }
            }
        },
        {
            id: 2,
            desc: 'Ziehendender Schmerz',
            degree_of_pain: 1,
            drawing: {
                circle: {
                    strokeColor: painDegreesColors.strokeColor,
                    fillColor: painDegreesColors.fillColor
                }
            }
        },
        {
            id: 3,
            desc: 'Ursache',
            degree_of_pain: 1,
            drawing: {
                circle: {
                    strokeColor: painDegreesColors.strokeColor,
                    fillColor: painDegreesColors.fillColor
                }
            }
        },
        {
            id: 4,
            desc: 'Ursache Kette',
            degree_of_pain: 1,
            drawing: {
                circle: {
                    strokeColor: painDegreesColors.strokeColor,
                    fillColor: painDegreesColors.fillColor
                }
            }
        }
    ]
};
