/*
    React core modules imports / side modules imports
*/
import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import SpeechRecognitionBuilder from '../utility/SpeechRecognitionBuilder';

/*
    Icons
*/
import { FaTrashAlt } from 'react-icons/fa';
import controlIcon from '../../assets/img/delete-cross-16.png';

/*
    Components
*/
import { Input, Textarea } from '../utility/form';
import BasicUploadButton from "../shared/BasicUploadButton";

/*
    Import actions
*/
import { addFiles } from '../../actions/Patients';

const speechRecognitionBuilderInstance = new SpeechRecognitionBuilder();

function AnamnesisBuilderAnswer(props) {
    const speechRecognizer = useRef();
    const timeoutIntervalId = useRef();
    const answerInputElement = useRef(null);
    /*
        Maps the props
    */
    const {
        isTouch,
        t,
        patient,
        disabled,
        onChangeAnswer,
        onDeactivateOtherRecognitions,
        answersRecognitionUuids
    } = props;
    // speech recognition controls status states
    const [ isStartRecognitionEnabled, setStartRecognitionEnabled ] = useState(true);
    const [ isStopRecognitionEnabled, setStopRecognitionEnabled ] = useState(false);
    // =============
    const [ answer, setAnswer ] = useState({});
    const [ files, setFiles ] = useState([]);
    const [ isElementFocused , setIsElementFocused ] = useState(false);
    
    useEffect(() => {
        const files = (props.answer.answer && props.answer.answer.files) || [];
        setFiles(files);
        setAnswer(props.answer);
    }, []);

    useEffect(() => {
        // now we need to destroy recognition object for every but current answer
        const currentAnswerUuid = answer.uuid;
        if (currentAnswerUuid && answersRecognitionUuids.length === 2) {
            if (answersRecognitionUuids[0] === currentAnswerUuid) {
                stopRecording(true);
            }
        }
    }, [answersRecognitionUuids]);

    // speech recognition events
    const onSpeechRecognizing = (sender, recognitionEventArgs) => {
        const result = recognitionEventArgs.result;
        const recognizedText = result.text;
        // update the answer
        if (recognizedText) {
            // destory speech activity interval and reinit process again
            initSpeechActivityInterval();
            // ===
            const questionType = getQuestionType(answer);
            const answerData = {
                voice_recognition: recognizedText
            };
            if (questionType === 4) {
                const additionalAnswerValue = answer.answer.additionalAnswer || '';
                let newAdditionalAnswerValue = `${additionalAnswerValue} ${answerData.voice_recognition}`;
                newAdditionalAnswerValue = newAdditionalAnswerValue.replace(/\s\s+/g, ' ');
                answerData.additionalAnswer = newAdditionalAnswerValue;
            } else {
                let basicAnswerValue = answer.answer.basic || '';
                let newBasicInputValue = `${basicAnswerValue} ${answerData.voice_recognition}`;
                newBasicInputValue = newBasicInputValue.replace(/\s\s+/g, ' ');
                answerData.basic = newBasicInputValue;
            }
            setAnswerUpdate(answerData);
        }
    };
    const onSpeechSessionStarted = (sender, sessionEventArgs) => {
        setStartRecognitionEnabled(false); // disabled mic icon
        setStopRecognitionEnabled(true); // enable mouseleave cancellation
    };
    const onSpeechSessionStopped = (sender, sessionEventArgs) => {
        setStartRecognitionEnabled(true); // enable mic icon
        setStopRecognitionEnabled(false); // disable mouseleave cancelation
    };
    // ======

    // speech recognition controls
    const toggleRecording = () => {
        if (!isStartRecognitionEnabled) {
            onDeactivateOtherRecognitions(answer.uuid, true);
            stopRecording();
        } else {
            onDeactivateOtherRecognitions(answer.uuid);
            // run speech recognition interval for checking activity
            initSpeechActivityInterval();
            // start recording
            startRecording();
        }
    };
    const mouseLeaveHandler = () => {
        onDeactivateOtherRecognitions(answer.uuid, true);
        stopRecording();
    };
    const initSpeechActivityInterval = () => {
        if (timeoutIntervalId.current) {
            clearInterval(timeoutIntervalId.current);
            timeoutIntervalId.current = null;
        }
        const speechActivityStartTime = Date.now();
        timeoutIntervalId.current = setInterval(() => {
            const currentTime = Date.now();
            const timeDiff = currentTime - speechActivityStartTime;
            const timeElapsedSeconds = ((timeDiff % 60000) / 1000).toFixed(0);
            if (timeElapsedSeconds >= 30) {
                stopRecording(true);
            }
        }, 1000);
    };
    const startRecording = async () => {
        if (!speechRecognizer.current) {
            // build speech recognition class instance
            const recognitionObject = await speechRecognitionBuilderInstance.build();
            // define events and handlers
            recognitionObject.recognized = onSpeechRecognizing;
            // recognitionObject.recognizing = onSpeechRecognizing;
            recognitionObject.sessionStarted = onSpeechSessionStarted;
            recognitionObject.sessionStopped = onSpeechSessionStopped;
            // save recognition instance to the state
            speechRecognizer.current = recognitionObject;
            // start speech recognition
            if (isStartRecognitionEnabled) {
                recognitionObject.startContinuousRecognitionAsync();
            }
        }
    };
    const stopRecording = (forceStop = false) => {
        if (timeoutIntervalId.current) {
            clearInterval(timeoutIntervalId.current);
            timeoutIntervalId.current = null;
        }
        if ((speechRecognizer.current && forceStop) || (speechRecognizer.current && isStopRecognitionEnabled)) {
            speechRecognizer.current.stopContinuousRecognitionAsync(
                () => {
                    speechRecognizer.current.close();
                    speechRecognizer.current = null;
                },
                (error) => {
                    speechRecognizer.current.close();
                    speechRecognizer.current = null;
                }
            );
        }
    };
    // ===========================
    const cleanTheAnswerDown = () => {
        const domAnswerInputElement = answerInputElement.current;
        if (domAnswerInputElement === document.activeElement) {
            setIsElementFocused(true);
        } else {
            setIsElementFocused(false);
        }
    };
    const cleanTheAnswerUp = () => {
        const domAnswerInputElement = answerInputElement.current;
        if (isElementFocused) {
            domAnswerInputElement.focus();
            setIsElementFocused(false);
        }
        const answerData = {
            basic: '',
            voice_recognition: ''
        };
        setAnswerUpdate(answerData);
    };

    /*
        State answer updater
    */
    const setAnswerUpdate = (data) => {
        const updateAnswerObject = { ...answer.answer, ...data };
        answer.answer = updateAnswerObject;
        setAnswer(answer);
        onChangeAnswer(answer);
    }
    
    /*
        Answers change handlers
    */
    const onChangeAnswerHandler = (e) => {
        let value = e.target.value;
        const answerData = {
            basic: value
        };
        setAnswerUpdate(answerData);
    }
    const onChangeAdditionalAnswerHandler = (e) => {
        let value = e.target.value;
        const answerData = {
            additionalAnswer: value
        };
        setAnswerUpdate(answerData);
    }

    /*
        File upload callback    
    */
    const uploadButtonCallback = async (files) => {
        let data = new FormData();
        for(let file of files){ 
            data.append('files', file);
        }
        const createdFiles = await addFiles(patient, data);
        const answerData = {
            files: createdFiles
        };
        setFiles(createdFiles);
        setAnswerUpdate(answerData);
    }

    /*
        Delete existed file
    */
    const onDeleteExisitingFile = (index) => {
        const filesCopy = JSON.parse(JSON.stringify(files));
        const answerDeletedFiles = answer.deleteFiles || [];
        const deleteFiles = [ ...answerDeletedFiles, filesCopy[index]];
        filesCopy.splice(index, 1);
        setFiles(filesCopy);
        const answerData = {
            deleteFiles,
            files: filesCopy
        };
        setAnswerUpdate(answerData);
    }

    const renderSpeechRecognitionControls = () => {
        return (
            <>
                <div
                    onClick = {toggleRecording}
                    className = {!isStartRecognitionEnabled ? 'speech_recognition_control speech_recognition_control--record listening' : 'speech_recognition_control speech_recognition_control--record'}
                />
                <div
                    className="speech_recognition_control speech_recognition_control--delete"
                    onMouseUp={cleanTheAnswerUp}
                    onMouseDown={cleanTheAnswerDown}
                >
                    <img src={controlIcon} alt='clean the answer' />
                </div>
            </>
        );
    };

    /*
        Render different types of questions
    */
    const getQuestionType = (answer) => {
        const question = answer.question || {};
        const questionType = question.questionTypeId ? question.questionTypeId : question.questionType && question.questionType.id ? question.questionType.id : 0;
        return questionType;
    };
    const renderQuestionBody = () => {
        const answerBody = answer.answer || {};
        const question = answer.question || {};
        const questionType = getQuestionType(answer);
        const options = question.options || [];
        // basic input value
        const basicInputValue = answerBody.basic || '';
        switch(questionType) {
            case 1: {
                const dProps = {
                    className: "Answer",
                    type: "text",
                    disabled,
                    ref: answerInputElement
                };
                if (disabled) {
                    dProps.defaultValue = basicInputValue;
                } else {
                    dProps.value = basicInputValue;
                    dProps.onChange = onChangeAnswerHandler;
                }
                return (
                    <>
                        {!disabled &&
                            renderSpeechRecognitionControls()
                        }
                        <Input {...dProps} />
                    </>
                );
            }
            case 2: {
                return (
                    <div className="theme_radio_group mb_15">
                        <div className="theme_radio">
                            <input
                                type="radio"
                                name={'qce_radio_' + question.id}
                                id={'qce_radio_' + question.id + '_yes'}
                                disabled={disabled}
                                value="yes" 
                                defaultChecked={answerBody && answerBody.basic && answerBody.basic === 'yes'} 
                                onChange={onChangeAnswerHandler}
                                onMouseLeave = {!isTouch ? mouseLeaveHandler : null}
                            />
                            <label htmlFor={'qce_radio_' + question.id + '_yes'}>
                                <div className="check_round"><span className="check_round__inside"></span></div>
                                {t("yes")}
                            </label>
                        </div>
                        <div className="theme_radio">
                            <input
                                type="radio"
                                name={'qce_radio_' + question.id}
                                id={'qce_radio_' + question.id + '_no'}
                                disabled={disabled}
                                value="no" 
                                defaultChecked={answerBody && answerBody.basic && answerBody.basic === 'no'} 
                                onChange={onChangeAnswerHandler}
                            />
                            <label htmlFor={'qce_radio_' + question.id + '_no'}>
                                <div className="check_round"><span className="check_round__inside"></span></div>
                                {t("no")}
                            </label>
                        </div>
                    </div>
                );
            }
            case 3: {
                const lineCount = options ? options.number : 0;
                const dProps = {
                    className: "Answer",
                    disabled,
                    rows: lineCount,
                    ref: answerInputElement
                };
                if (disabled) {
                    dProps.defaultValue = basicInputValue;
                } else {
                    dProps.value = basicInputValue;
                    dProps.onChange = onChangeAnswerHandler;
                }
                return (
                    <>
                        {!disabled &&
                            renderSpeechRecognitionControls()
                        }
                        <Textarea {...dProps} />
                    </>
                );
            }
            case 4: {
                const additionalAnswer = answerBody && answerBody.additionalAnswer ? answerBody.additionalAnswer : '';                
                const dProps = {
                    disabled,
                    ref: answerInputElement
                };
                if (disabled) {
                    dProps.defaultValue = additionalAnswer;
                } else {
                    dProps.value = additionalAnswer;
                    dProps.onChange = onChangeAdditionalAnswerHandler;
                }
                return (
                    <div className="Answer">
                        <div className="theme_radio_group mb_15">
                            <div className="theme_radio">
                                <input
                                    type="radio"
                                    name={'qce_radio_' + question.id}
                                    id={'qce_radio_' + question.id + '_yes'}
                                    disabled={disabled}
                                    value="yes" 
                                    defaultChecked={answerBody && answerBody.basic && answerBody.basic === 'yes'} 
                                    onChange={onChangeAnswerHandler}
                                />
                                <label htmlFor={'qce_radio_' + question.id + '_yes'}>
                                    <div className="check_round"><span className="check_round__inside"></span></div>
                                    {t("yes")}
                                </label>
                            </div>
                            <div className="theme_radio">
                                <input
                                    type="radio"
                                    name={'qce_radio_' + question.id}
                                    id={'qce_radio_' + question.id + '_no'}
                                    disabled={disabled}
                                    value="no" 
                                    defaultChecked={answerBody && answerBody.basic && answerBody.basic === 'no'}
                                    onChange={onChangeAnswerHandler}
                                />
                                <label htmlFor={'qce_radio_' + question.id + '_no'}>
                                    <div className="check_round"><span className="check_round__inside"></span></div>
                                    {t("no")}
                                </label>
                            </div>
                        </div>
                        {answerBody && answerBody.basic === 'yes' &&
                            <div className="Answer__optional__form">
                                {!disabled &&
                                    renderSpeechRecognitionControls()
                                }
                                <Textarea {...dProps} />
                            </div>
                        }
                    </div>
                );
            }
            case 7: {
                return (
                    <div className="theme_radio_group mb_15">
                        <div className="theme_radio">
                            <input
                                type="radio"
                                name={'qce_radio_' + question.id}
                                id={'qce_radio_' + question.id + '_positive'}
                                disabled={disabled}
                                value="positive" 
                                defaultChecked={answerBody && answerBody.basic && answerBody.basic === 'positive'} 
                                onChange={onChangeAnswerHandler}
                            />
                            <label htmlFor={'qce_radio_' + question.id + '_positive'}>
                                <div className="check_round"><span className="check_round__inside"></span></div>
                                {t("positive")}
                            </label>
                        </div>
                        <div className="theme_radio">
                            <input
                                type="radio"
                                name={'qce_radio_' + question.id}
                                id={'qce_radio_' + question.id + '_negative'}
                                disabled={disabled}
                                value="negative" 
                                defaultChecked={answerBody && answerBody.basic && answerBody.basic === 'negative'} 
                                onChange={onChangeAnswerHandler}
                            />
                            <label htmlFor={'qce_radio_' + question.id + '_negative'}>
                                <div className="check_round"><span className="check_round__inside"></span></div>
                                {t("negative")}
                            </label>
                        </div>
                        <div className="theme_radio">
                            <input
                                type="radio"
                                name={'qce_radio_' + question.id}
                                id={'qce_radio_' + question.id + '_neutral'}
                                disabled={disabled}
                                value="neutral" 
                                defaultChecked={answerBody && answerBody.basic && answerBody.basic === 'neutral'} 
                                onChange={onChangeAnswerHandler}
                            />
                            <label htmlFor={'qce_radio_' + question.id + '_neutral'}>
                                <div className="check_round"><span className="check_round__inside"></span></div>
                                {t("neutral")}
                            </label>
                        </div>
                    </div>
                );
            }
            default: {
                return null;
            }
        }
    }

    if (answer && Object.keys(answer).length > 0) {
        return (
            <>
                <div
                    className="question__body__wrap"
                    onMouseLeave = {!isTouch ? mouseLeaveHandler : null}
                >
                    {renderQuestionBody()}
                    { files && !!files.length && (
                        <div className="Answer__files">
                            <span className="Answer__files__title">{`${t('files')}:`}</span>
                            {files.map((file, index) => (
                                <span key={index}>
                                    {!disabled && (
                                        <button onClick={() => onDeleteExisitingFile(index)}>
                                            <FaTrashAlt />
                                        </button>
                                    )}
                                    <a href={file.path} target="_blank">{file.originalFilename}</a>
                                </span>
                            ))}
                        </div>
                    )}
                </div>
                {!disabled && answer.question && answer.question.allowFiles && (
                    <BasicUploadButton
                        uploadButtonCallback={uploadButtonCallback}
                    />
                )}
            </>
        );
    }
    return null;
}

export default withTranslation("common")(AnamnesisBuilderAnswer);
