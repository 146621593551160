import LocalApi from '../api/LocalApi';
import HomeLessonApi from '../api/HomeLessonApi';
import uuid from 'uuid';
import { getIsoTimeStamp, tryConnect } from './Sync';
import { refreshHomeLessons } from '../state/doctor/doctor.actionCreators';

export function submitHomeLessons() {
  return async (dispatch, getState) => {
    const unsubmittedHomeLessons = await LocalApi.getItem('unsubmitted_home_lessons');
    if(unsubmittedHomeLessons) {
      await Promise.all(unsubmittedHomeLessons.map(homeLesson => HomeLessonApi.create(homeLesson)));
      await LocalApi.setItem('unsubmitted_home_lessons', []);
    }
  };
}

export function getHomeLessons() {
  return async (dispatch, getState) => {
    const { isOnline } = getState().connection;

    return (await tryConnect(isOnline, async () => {
      await dispatch(submitHomeLessons());
      await dispatch(refreshHomeLessons());
      const { doctor } = getState();
      return doctor.homeLessons;
    }, async () => {
      const { doctor } = getState();
      const unsubmittedHomeLessons = await LocalApi.getItem('unsubmitted_home_lessons') || [];
      const homeLessons = { ...doctor.homeLessons };
      for(let lesson of unsubmittedHomeLessons) {
        homeLessons[lesson.uuid] = lesson;
      }
      return homeLessons;
    }))[0];
  };
}

export function createHomeLesson(category, data) {
  return async (dispatch, getState) => {
    const { isOnline } = getState().connection;

    data.homeLessonCategoryId = category.id;
    return (await tryConnect(isOnline, async () => {
      return await HomeLessonApi.create(data);
    }, async() => {
      const now = getIsoTimeStamp();
      const homeLesson = {
        ...data,
        createdAt: now,
        updatedAt: now,
        uuid: uuid.v4(),
      }
      LocalApi.pushToItem(`unsubmitted_home_lessons`, homeLesson);
      homeLesson.homeLessonCategory = category;
      return homeLesson;
    }))[0];
  };
}