/*
    React core modules imports / side modules imports
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { flashErrorMessage } from 'redux-flash';
/*
    Components imports
*/
import AppTopNavBar from '../navbar/AppTopNavBar';
import Relevance from '../anamnesis/Relevance';
import HomeLessons from './HomeLessons';
import HomeLessonEditorModule from './HomeLessonEditorModule';
import SpeechRecognitionBasic from "../shared/SpeechRecognitionBasic";
/*
    Actions imports
*/
import { getPatient } from '../../actions/Patients';
import { getAnamnesis, getAnamnesisDirectDb } from '../../actions/Anamneses';
import { getHomeLessons, createHomeLesson } from '../../actions/HomeLessons';
import { getAnswers as loadAnswers} from '../../actions/Answers';
import { getBodyAnswer, getBodyAnswerIndex } from '../anamnesis/AnamnesisLoader';
import { injectTreatment, getTreatments } from '../../actions/Treatments';
/*
    Icons imports
*/
import {
    FaPaperclip,
    FaTextHeight,
    FaPlus,
    FaChevronLeft,
    FaChevronRight
} from 'react-icons/fa';
import symtomeIcon from '../../assets/img/symtome_icon.svg';
import treatmentIcon from '../../assets/img/icon_behandlung_blank.svg';
/*
    Misc
*/
import { Textarea } from '../utility/form';
import { getAge, formatDate } from '../utility/Date';

class TreatmentModule extends Component {
    constructor(props) {
        super(props);
        this.noteTextAreaElement = React.createRef();
        this.lessonEditor = null;
        /*
            Retrieve params
        */
        this.patientUuid = props.match.params.uuid;
        this.anamnesisUuid = props.match.params.anamnesisUuid;
        this.treatmentUuid = props.match.params.treatmentUuid || null;
        /*
            Data holders
        */
        this.treatment = null;
        this.treatments = [];
        this.anamnesis = null;
        this.patient = null;
        /*
            Set the loading state / initial state
        */
        const isTouch = ('ontouchstart' in window && window.TouchEvent !== undefined) || window.navigator.maxTouchPoints > 1 || window.navigator.msMaxTouchPoints > 1;
        this.state = {
            renderHomeLessons: false,
            isHomeLessonsOpened: false,
            isLoading: true,
            deactivateRecognition: false,
            isTouch,
            noteTextAreaCursorPosition: 0,
        }
    }
    /*
        Lifecycle methods
    */
    componentDidMount() {
        this.setData();
    }
    componentDidUpdate(prevProps) {
        if(prevProps.match.params.treatmentUuid !== this.props.match.params.treatmentUuid) {
            this.setData(this.props.match.params.treatmentUuid);
        }
    }
    /*
        Method responsible for the data visualization
    */
    async setData(treatmentUuid = null) {
        let error = null;
        const { dispatch } = this.props;
        /*
            Load patient data
        */
        const patient = await dispatch(getPatient(this.patientUuid));
        this.patient = patient;
        /*
            Load anamnesis data
        */
        this.anamnesis = await getAnamnesis(this.patient, this.anamnesisUuid);
        /*
            Load treatment related data     
        */
        let homeLessons = [];
        let selectedHomeLessons = [];
        let currentTreatment = null;
        let bodyAnswer = null;
        if(this.anamnesis) {
            /*
                Prepare body answer
            */
            const originalAnswers = await loadAnswers(this.patient, this.anamnesis);
            bodyAnswer = getBodyAnswer(originalAnswers);
            /*
                TODO:
                Get body answer directly from the DB
                in case if it is empty in a local db
                and put default svgs template , even svgs straigt from DB are empty
                because if body answer has no svgs , it will breaks injectTreatment method
            */
            let svgs = (bodyAnswer && bodyAnswer.answer && bodyAnswer.answer.svgs && bodyAnswer.answer.svgs[0]) ? bodyAnswer.answer.svgs : null;
            if (!svgs) {
                // get body answer directly from the DB
                // in case if it is empty in a local db
                const anamnesesDirect = await getAnamnesisDirectDb(this.anamnesisUuid);
                const bodyAnswerIndexDb = getBodyAnswerIndex(anamnesesDirect.answers);
                if (bodyAnswerIndexDb > -1) {
                    const bodyAnswerDirect = anamnesesDirect.answers[bodyAnswerIndexDb];
                    bodyAnswer = bodyAnswerDirect;
                    const svgsDirect = bodyAnswerDirect.answer.svgs || null;
                    // give the default svgs object
                    if (!svgsDirect) {
                        const defaultSvgs = [
                            {
                                elements: [],
                                viewBox: {
                                    width: 960,
                                    height: 775
                                }
                            }
                        ];
                        bodyAnswer.answer.svgs = defaultSvgs;
                    }
                }
            }
            /*
                Load home lessons
            */
            homeLessons = await dispatch(getHomeLessons());
            /*
                Load treatments
            */
            this.treatments = await getTreatments(this.anamnesis);
            this.treatments.forEach(treatment => {
                treatment.homeLessons = treatment.homeLessons ? treatment.homeLessons.map(treatment => homeLessons[treatment.uuid]).filter(treatment => !!treatment) : [];
            });
            /*
                Find current treatment treatmentUuid
            */
            const current_treatment_uuid = treatmentUuid || this.treatmentUuid;
            const currentTreatmentIndex = this.treatments.findIndex(t => t.uuid === current_treatment_uuid);
            if (currentTreatmentIndex > -1) {
                currentTreatment = this.treatments[currentTreatmentIndex];
                selectedHomeLessons = currentTreatment.homeLessons || [];
            }
        }
        /*
            Dismiss loading state
        */
        this.setState({
            isLoading: false,
            homeLessonOptions: Object.values(homeLessons),
            currentTreatment,
            treatment: (currentTreatment && currentTreatment.treatment) || '',
            success: (currentTreatment && currentTreatment.success) || 0,
            homeLessons,
            selectedHomeLessons,
            bodyAnswer,
            error,
        });
    }
    /*
        Change treatment success
    */
    changeSuccess = (success) => {
        this.setState({ success: success.relevant });
    }
    /*
        Render basic patient info in a header
    */
    renderPatientCard() {
        const { t } = this.props;
        if (this.patient) {
            const age = getAge(this.patient.birthday);
            return (
                <div className="patient_details">
                    <p>{`${this.patient.firstName} ${this.patient.surName}`}</p>
                    <p>{age + ' ' + t('years') +' | ' + t('birthday') + ': ' + formatDate(this.patient.birthday)}</p>
                </div>
            );
        }
        return null;
    }
    injectTreatmentThroughSymptoms = async () => {
        const { dispatch } = this.props;
        const injectTreatmentRes = await this.injectTreatment();
        if (injectTreatmentRes.error) {
            dispatch(flashErrorMessage(injectTreatmentRes.error));
        } else {
            const data = injectTreatmentRes.data || null;
            const treatment_uuid = (data && data.treatment && data.treatment.uuid) ? data.treatment.uuid : null;
            if (treatment_uuid) {
                const dispatchPushObject = {
                    isNewTimestampNeeded: true,
                    pathname:`/patients/${this.patientUuid}/anamneses/${this.anamnesisUuid}/symptoms`,
                    prevPath: `/patients/${this.patientUuid}/anamneses/${this.anamnesisUuid}/treatments/${treatment_uuid}/edit`
                };
                dispatch(push(dispatchPushObject));
            } else {
                dispatch(flashErrorMessage('Treatment id is not defined'));
            }
        }
    }
    injectTreatmentStraign = async () => {
        const { dispatch } = this.props;
        const injectTreatmentRes = await this.injectTreatment();
        if (injectTreatmentRes.error) {
            dispatch(flashErrorMessage(injectTreatmentRes.error));
        } else {
            const dispatchPushObject = {
                pathname: `/patients/${this.patientUuid}/anamneses/${this.anamnesisUuid}`,
                anamnesis: this.anamnesis,
                patient: this.patient,
                tab_id: 'treatment'
            }
            dispatch(push(dispatchPushObject));
        }
    }
    /*
        Inject the treatment
    */
    injectTreatment = async () => {
        const { dispatch, t } = this.props;
        const { selectedHomeLessons, treatment, bodyAnswer, currentTreatment } = this.state;
        const data = {
            treatment: treatment || '',
            homeLessons: selectedHomeLessons || [],
            bodyAnswer,
            success: this.state.success
        };
        if(this.treatments.length) {
            const previousTreatment = this.treatments[0];
            data.previousTreatment = { ...previousTreatment };
        }
        if (currentTreatment) {
            data.id = currentTreatment.id;
        }
        const injectTreatmentRes = await dispatch(injectTreatment(this.patient, this.anamnesis, data));
        return injectTreatmentRes;
    }
    /*
        Track treatment change
    */
    handleTreatmentChange = (event) => {
        const cursorPosition = this.getCursorPosition();
        this.setState({
            treatment: event.target.value,
            noteTextAreaCursorPosition: cursorPosition
        });
    }
    handleTreatmentClick = () => {
        const cursorPosition = this.getCursorPosition();
        this.setState({
            noteTextAreaCursorPosition: cursorPosition
        });
    };
    /*
        Copy and inject note from the previous treatment
    */
    copyNote = (e) => {
        if (this.treatments.length > 0) {
            const latestTreatment = this.treatments[0];
            const treatmentNote = (latestTreatment && latestTreatment.treatment) ? latestTreatment.treatment : '';
            this.setState({
                treatment: treatmentNote
            });
        }
    }
    /*
        Copy and inject lessons from the previous treatment
    */
    copyLessons = (e) => {
        if (this.treatments.length > 0) {
            this.setState({ selectedHomeLessons: this.treatments[0].homeLessons} );
            this.setState({
                isHomeLessonsOpened: false
            });
        }
    }
    /*
        Activate home lessons editor
    */
    activateHomeLessonEditor = () => {
        this.setState({ renderHomeLessons: true });
    }
    /*
        Open / close home lessoNS
    */
    toggleHomeLessons = () => {
        this.setState({
            isHomeLessonsOpened: !this.state.isHomeLessonsOpened
        });
    }
    /*
        Home lessons callback function
        Happens every time home lessons are close
    */
    homeLessonsCallback = (v) => {
        this.toggleHomeLessons();
    }
    alterSelectedHomeLessons = (uuid, opts = {}) => {
        const { selectedHomeLessons, homeLessons } = this.state;
        if (Object.keys(opts).length > 0 && opts.action === 'check') {
            const index = selectedHomeLessons.findIndex(l => l.uuid === uuid);
            if (index > -1) {
                selectedHomeLessons.splice(index, 1);
            } else {
                const homeLessonsKeys = Object.values(homeLessons);
                const insertIndex = homeLessonsKeys.findIndex(l => l.uuid === uuid);
                if (insertIndex > -1) {
                    selectedHomeLessons.push(homeLessonsKeys[insertIndex]);
                }
            }
            this.setState({
                selectedHomeLessons
            });
        }
    }
    /*
        Inject new lesson
    */
    injectNewLesson = async (name, category) => {
        const { dispatch } = this.props;
        const { selectedHomeLessons, homeLessonOptions } = this.state;
        const homeLesson = await dispatch(createHomeLesson(category, { name }));
        this.setState({
            selectedHomeLessons: [...selectedHomeLessons, homeLesson],
            homeLessonOptions: [...homeLessonOptions, homeLesson]
        });
        this.toggleHomeLessons();
    }
    /*
        Receive voice recognition resutls
    */
    receiveRecognitionResult = (recognizedText) => {
        const {
            treatment,
            noteTextAreaCursorPosition,
        } = this.state;
        const treatmentRecognized = `${treatment.substr(0, noteTextAreaCursorPosition)}${recognizedText}${treatment.substr(noteTextAreaCursorPosition)}`;
        this.setState({
            treatment: treatmentRecognized
        });
    };
    /*
        Get cursor position
    */
    getCursorPosition = () => {
        const content = this.noteTextAreaElement.current;
        if (content && content.selectionStart !== null && content.selectionStart !== undefined) {
            const position = content.selectionStart;
            return position;
        }
        return 0;
    }
    /*
        Render right navigation
    */
    renderRightNavigation = () => {
        const { t } = this.props;
        return (
            <a onClick={this.injectTreatmentStraign}>{t('save')}</a>
        );
    }
    mouseLeaveHandler = () => {
        this.setState({
            deactivateRecognition: true
        });
    };

    mouseEnterHandler = () => {
        this.setState({
            deactivateRecognition: false
        });
    };
    /*
        Render the component when data is loaded
    */
    renderLoaded() {
        const { t } = this.props;
        const success = this.state.success ? this.state.success : 0;
        const {
            homeLessonOptions,
            currentTreatment,
            treatment,
            bodyAnswer,
            selectedHomeLessons,
            isTouch,
            deactivateRecognition,
        } = this.state;
        /*
            Calculate current treatment number
        */
        let currentTreatmentNumber  = null;
        let prevLink = null;
        let nextLink = null;
        if (currentTreatment) {
            const reverseTreatments = [...this.treatments].reverse();
            let currentTreatmentKey = reverseTreatments.findIndex(t => t.uuid === currentTreatment.uuid);
            currentTreatmentNumber = currentTreatmentKey + 1;
            /*
                Define next / prev navigatio links
            */
            const prevTreatmentIndex = currentTreatmentKey - 1;
            const nextTreatmentIndex = currentTreatmentKey + 1;
            if (reverseTreatments[prevTreatmentIndex]) {
                prevLink = `/patients/${this.patientUuid}/anamneses/${this.anamnesisUuid}/treatments/${reverseTreatments[prevTreatmentIndex].uuid}/edit`;
            }
            if (reverseTreatments[nextTreatmentIndex]) {
                nextLink = `/patients/${this.patientUuid}/anamneses/${this.anamnesisUuid}/treatments/${reverseTreatments[nextTreatmentIndex].uuid}/edit`;
            }
        }
        return (
            <>
                <AppTopNavBar
                    isControlPanel={true}
                    title={this.renderPatientCard()}
                    leftNavigation = {
                        <Link className='icon-enabled-link' to={{pathname:`/patients/${this.patientUuid}/anamneses/${this.anamnesisUuid}`, tab_id: 'treatment'}}>
                            <span className='icon icon-navi-left'></span>
                            {t('navigation_back')}
                        </Link>
                    }
                    rightNavigation = {this.renderRightNavigation()}
                />
                <div className="app_container__inner">
                    <div className="treatment_details">
                        <div className="treatment_details_header">
                            <div className="theme_header">
                                <div className={classNames('theme_header__info', { 
                                    "full-width": !currentTreatment
                                })}>
                                    {currentTreatment &&
                                        <div className="theme_header__info__left">
                                            <div className="info_cell">
                                                <div className="info_cell__icon">
                                                    <img src={treatmentIcon} />
                                                </div>
                                                <div className="info_cell__text">
                                                    <p>{t('treatment')}.{currentTreatmentNumber}</p>
                                                    <p>{t('from') + ' ' + formatDate(currentTreatment.createdAt)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="theme_header__info__right">
                                        <div className="control_cell">
                                            <a
                                                className="theme_btn medium"
                                                onClick = {this.injectTreatmentThroughSymptoms}
                                            >
                                                <img src={symtomeIcon} />
                                                {t("anamnesis_symptoms")}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* success bar */}
                        <div className="treatment_details__success">
                            <div className="treatment_details__success__left">
                                <p>{t('treatmentSuccess')}:</p>
                            </div>
                            <div className="treatment_details__success__right">
                                <Relevance relevance={success} onChangeRelevant={this.changeSuccess}/>
                            </div>
                        </div>
                        {/* treatment sheet form */}
                        <div className="treatment_details__form">
                            {/* navigation controls between treatments */}
                            {currentTreatment &&
                                <>
                                    {prevLink &&
                                        <NavLink className="treatment_details__form__control left" to={prevLink}>
                                            <FaChevronLeft />
                                        </NavLink>
                                    }
                                    {nextLink &&
                                        <NavLink className="treatment_details__form__control right" to={nextLink}>
                                            <FaChevronRight />
                                        </NavLink>
                                    }
                                </>
                            }
                            {/* form itself */}
                            <div className="treatment_details__form__left">
                                <div className="form_textarea">
                                    <Textarea
                                        name="treatment"
                                        label={t('note')}
                                        onChange={this.handleTreatmentChange}
                                        value={treatment}
                                        ref={this.noteTextAreaElement}
                                        onClick={this.handleTreatmentClick}
                                    />
                                    <div
                                        className="controls"
                                        onMouseLeave = {!isTouch ? this.mouseLeaveHandler : null}
                                        onMouseEnter = {!isTouch ? this.mouseEnterHandler : null}
                                    >
                                        <a className="controls__add" onClick={this.copyNote}>
                                            <FaPlus />
                                            <span>{t('note_from_the_last_treatment')}</span>
                                        </a>
                                        <div className="controls__icons">
                                            <SpeechRecognitionBasic
                                                transferRecognitionResult={this.receiveRecognitionResult}
                                                deactivateRecognition={deactivateRecognition}
                                            />
                                            <a onClick={() => false}><FaPaperclip/></a>
                                            <a className="active" onClick={() => false}><FaTextHeight/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="treatment_details__form__right">
                                <div className="form_textarea">
                                    <div className="Form__input">
                                        <label>{t('homework')}</label>
                                        <div className="homework_area">
                                            <HomeLessonEditorModule
                                                isHomeLessonsOpened={this.state.isHomeLessonsOpened}
                                                options={homeLessonOptions}
                                                selectedHomeLessons={selectedHomeLessons}
                                                homeLessonsCallback={this.homeLessonsCallback}
                                                alterSelectedHomeLessons={this.alterSelectedHomeLessons}
                                                passNewLesson={this.injectNewLesson}
                                            />
                                            <div className="read_only_textarea" onClick={this.toggleHomeLessons}
                                            >
                                                <div
                                                    className={classNames(`homelessons_list`, { 
                                                        'hidden': this.state.isHomeLessonsOpened,
                                                    })}
                                                >
                                                    <HomeLessons homeLessons={selectedHomeLessons}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="controls">
                                        <a className="controls__add" onClick={this.copyLessons}>
                                            <FaPlus />
                                            <span>{t('homework_from_last_treatment')}</span>
                                        </a>
                                        <div className="controls__icons">
                                            <a onClick={() => false}><FaPaperclip/></a>
                                            <a className="active" onClick={() => false}><FaTextHeight/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    render() {
        const { isLoading, error } = this.state;
        if (error) {
            return (
                <div className="error_panel">
                    <div className="error_panel__inner">
                        {error.msg}
                    </div>
                </div>
            );
        }
        return (
            <>
                { !isLoading && this.renderLoaded()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(withTranslation('common')(TreatmentModule));
