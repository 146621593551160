import moment from 'moment';
import LocalApi from '../api/LocalApi';

class JobQueue {
  constructor(){
    this.head = {
      next: null,
    }
    this.back = this.head;
    this.runing = false;
    this.jobs = {};

  }

  get(key) {
    return this.jobs[key];
  }

  remove(key) {
    const job = this.jobs[key];
    if(!job) return;

    if(job.prev) {
      job.prev.next = job.next;
    }
    if(job.next) {
      job.next.prev = job.prev;
    }
    delete this.jobs[key];
  }

  schedule(job) {
    this.push(job);
    if(!this.running) {
      this.run();
    }
  }

  scheduleNow(job) {
    this.pushFront(job);
    if(!this.running) {
      this.run();
    }
  }

  pushFront(job) {
    this.head.next = {
      ...job,
      resolve: null,
      next: this.head.next,
      prev: this.head,
    }
    this.jobs[job.key] = this.head.next;
  }
  push(job) {
    this.back.next = {
      ...job,
      resolve: null,
      next: null,
      prev: this.back,
    };
    this.back = this.back.next;
    this.jobs[job.key] = this.back;
  }

  start = () => {
    if(!this.running) {
      this.run();
    }
  }

  run = async () => {
    const current = this.head.next;
    if(current) {
      this.running = true;
      const key = current.key;
      current.resolve = current.task();
      if(current === this.back) {
        this.back = this.head;
      }
      this.head.next = current.next;
      await current.resolve;
      setTimeout(this.run);
      delete this.jobs[key];
    } else {
      this.running = false;
    }
  }
}

export async function updateRecord(records, uuid, value) {
  if(records[uuid]) {
    Object.assign(records[uuid], value);
  } else {
    throw new Error("No record found with that uuid.");
  }
}

export async function tryConnect(online, withConnection, withoutConnection) {
  if(!online) {
    return [ await withoutConnection(), true ];
  } else {
    try {
      return [ await withConnection(), false ];
    } catch(err) {
      if(err.message === "Network Error" && !err.response) {
        return [ await withoutConnection(), true ];
      } else {
        throw err;
      }
    }
  }
}

const jobQueue = new JobQueue();
export { jobQueue };

export function getIsoTimeStamp() {
    return new Date().toISOString();
}

export function getAll(object) {
  return Object.values(object).filter(record => record.resourceState !== 'deleted');
}

export function sortByCreatedAt(arrayOrObject, order="ASC") {
  let records = arrayOrObject;
  if(!(records instanceof Array)) {
    records = getAll(records);
  }
  return records.sort((a, b) => {
    const aDate = new Date(a.createdAt);
    const bDate = new Date(b.createdAt);
    return order === "ASC" ? aDate - bDate : bDate - aDate;
  })
}
export function isNewer(localRecord, remoteRecord) {
  if(!localRecord && !remoteRecord) throw Error("One record needs to be valid.");
  if(!localRecord) return false;
  if(!remoteRecord) return true;
  return new Date(localRecord.updatedAt) > new Date(remoteRecord.updatedAt);
}

export async function getItem(key) {
  const syncJob = jobQueue.get(key);
  if(syncJob) {
      if(!syncJob.resolve) {
          jobQueue.remove(key);
          return await syncJob.task();
      } else {
          return await syncJob.resolve;
      }
  } else {
      return await LocalApi.getItem(key);
  }
}

export function syncRecord(localRecord, remoteRecord, updateCallback) {
  if(!localRecord && !remoteRecord) throw Error("One record needs to be valid.");
  if(!remoteRecord) {
    updateCallback(localRecord);
    return localRecord;
  }
  const updatedAtLocal = localRecord ? new Date(localRecord.updatedAt) : null;
  const updatedAtRemote = new Date(remoteRecord.updatedAt);
  if(
    !localRecord //Record is new record
  ) {
    remoteRecord.syncState = "pendingLocal";
    return remoteRecord;
  } else if(updatedAtRemote > updatedAtLocal) { //Record was changed remotely 
    localRecord.syncState = "pending"; //Mark so it is not deleted locally
    remoteRecord.syncState = "pendingLocal";
    return remoteRecord;
  } else if(localRecord.resourceState === 'deleted') { //Record was deleted locally
    updateCallback(localRecord);
    return null;
  } else if(
    updatedAtRemote.getTime() === updatedAtLocal.getTime()
    && (!localRecord.updatedAtOriginal || localRecord.updatedAtOriginal === localRecord.updatedAt)
    //Records did not change remotely or locally
  ) {
    localRecord.syncState = "pending"; //Mark so it is not deleted locally
    remoteRecord.syncState = "synced";
    return remoteRecord;
  } else if(
    updatedAtLocal >= updatedAtRemote //Record was changed locally
  ) {
    updateCallback(localRecord);
    return localRecord;
  }  else {
    console.log("Local record");
    console.log(localRecord);
    console.log("Remote record");
    console.log(remoteRecord);
    updateCallback(localRecord);
    return localRecord;
    // throw new Error(`Impossible condition.`);
  }
}

export function toObject(records) {
  const result = {};
  for(let record of records) {
    if(record) {
      result[record.uuid] = record;
    } 
  }
  return result;
}

export function syncRecords(localRecords, remoteRecords, updateCallback, deleteCallback) {
  const remoteUpdates = [];
  const result = {};

  for(let record of remoteRecords) {
    const localRecord = localRecords ? localRecords[record.uuid] : null;
    const synced = syncRecord(localRecord, record, (r) => {
      if(r.syncState !== 'pending') {
        r.syncState = "pending";
        remoteUpdates.push(r);
      }
    })
    if(synced) {
      result[record.uuid] = synced;
    }
  }
  const newRecords = Object.values(localRecords).filter(record => record.resourceState === "new");
  
  for(let record of newRecords) {
    result[record.uuid] = record;
    if(record.syncState !== 'pending') {
      record.syncState = "pending";
      remoteUpdates.push(record);
    }
  }

  const deletedRecords = Object.values(localRecords).filter(record => {
    return record.syncState !== "pending" && record.resourceState !== "new";
  });

  if(remoteUpdates.length && updateCallback) {
    updateCallback(remoteUpdates);
  }

  if(deletedRecords.length && deleteCallback) {
    deleteCallback(deletedRecords);
  }
  return result
}

export class MultiCallback {
  constructor(callback, times = 1) {
    this.times = times;
    this.callback = callback;
  }

  getCallback = () => {
    return (result) => {
      this.times--;
      if(this.times === 0) {
        return this.callback(result);
      }
    }
  }
}