import React, { useState, useEffect } from 'react';
import SpeechRecognitionBasic from "../shared/SpeechRecognitionBasic";
import { withTranslation } from 'react-i18next';
import { MdClose, MdSearch } from 'react-icons/md';

const HomeLessonEditorSearchModule = (props) => {
    const { 
        t,
        onChangeOutside
    } = props;
    const [deactivateRecognition, setDeactivateRecognition] = useState(false);
    const [isTouch, setTouchStatus] = useState(false);
    const [search, setSearch] = useState('');
    const [isSelected, setSelected] = useState(false);

    useEffect(() => {
        const isTouch = ('ontouchstart' in window && window.TouchEvent !== undefined) || window.navigator.maxTouchPoints > 1 || window.navigator.msMaxTouchPoints > 1;
        setTouchStatus(isTouch);
    }, []);

    const onChange = (event) => {
        const { value } = event.target;
        setSearch(value);
        onChangeOutside(value);
    };

    const receiveRecognitionResult = (recognizedText) => {
        const newSearchValue = search ? `${search} ${recognizedText}` : recognizedText;
        setSearch(newSearchValue);
        onChangeOutside(newSearchValue);
    };

    const handleSelect = () => {
        setSelected(true);
    };

    const onReset = () => {
        setSearch('');
    };

    const mouseLeaveHandler = () => {
        setDeactivateRecognition(true);
    };

    const mouseEnterHandler = () => {
        setDeactivateRecognition(false);
    };

    return (
        <form className="Search__form" onSubmit={(e) => e.preventDefault()}>
            <div
                onMouseLeave = {!isTouch ? mouseLeaveHandler : null}
                onMouseEnter = {!isTouch ? mouseEnterHandler : null}
                className="home_lessons_editor__searchbar__search Search"
            >
                <input
                    autoFocus
                    type="text"
                    value={search}
                    className="theme_text_input"
                    onChange={onChange}
                    onFocus={handleSelect}
                    placeholder={t('quick_search')}
                />
                <SpeechRecognitionBasic
                    classAdditionalName="speech_recognition_control--absolute"
                    transferRecognitionResult={receiveRecognitionResult}
                    deactivateRecognition={deactivateRecognition}
                />
                <MdSearch className="Search__input__icon Search__input__icon--left" />
                {isSelected && (
                    <button
                        type="button"
                        onClick={onReset}
                        className="Search__input__close"
                    >
                        <MdClose />
                    </button>
                )}
            </div>
        </form>
    );
};

export default withTranslation("common")(HomeLessonEditorSearchModule);
