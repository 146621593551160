import api from '../config/api';

class SpeechRecognitionApi {
    static getSpeechRecognitionToken = async () => {
        const response = await api.get('/api/get-speech-token');
        return response.data;
    };
};

export default SpeechRecognitionApi;
