/* eslint-disable no-restricted-globals */
import React, { Component } from "react";
import {
    FaRegStar,
    FaTrashAlt,
    FaPlus
} from 'react-icons/fa';
import { MDBDataTableV5 } from 'mdbreact';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getPatient, updatePatient } from '../../actions/Patients';
import { getAnamneses } from '../../actions/Anamneses';
import PatientApi from '../../api/PatientApi';
import AnamnesisApi from '../../api/AnamnesisApi';
import { NavLink } from 'react-router-dom';
import { flashErrorMessage } from 'redux-flash';
import { removeAnamnesis } from '../../actions/Anamneses';
import AppTopNavBar from '../navbar/AppTopNavBar';
import patientsListFolderImage from '../../assets/img/patients_list_folder.svg';
import anamnesisCardIcon from '../../assets/img/icon_clipboard.svg';
import fileListFolderImage from '../../assets/img/icon_sheet.svg';
import { removeFile, addFiles } from '../../actions/Patients';
import FileDropzoneModal from '../files//FileDropzoneModal';
import { getAge, formatDate } from '../utility/Date';
import './PatientDetail.scss';
import classNames from 'classnames';
import { syncOnePatient } from '../../state/patients/patients.actionCreators';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class PatientDetail extends Component {
    constructor(props) {
        super(props);

        this.patientUuid = props.match.params.uuid;
        this.lastVisited = null;
        this.state = {
            patient: this.props.location.patient,
            anamneses: [],
            isLoading: true,
            files: [],
            selectedEditFile: null,
            errorMessage: '',
            tab_id: 'details' // 'details', 'lab_results' 'policy'
        }
    }

    async setData() {
        /*
            Load patient
        */
        const {
            dispatch
        } = this.props;
        let patient = null;
        const patientDraft = await dispatch(getPatient(this.patientUuid));
        if (patientDraft) {
            patient = patientDraft;
        } else {
            patient = await PatientApi.getOne(this.patientUuid);
            dispatch(syncOnePatient(patient));
        }
        // ===
        if (patient) {
            try {
                const files = await PatientApi.getFiles(patient.id);
                let anamneses = await getAnamneses(patient);
                if (anamneses.length === 0) {
                    anamneses = await AnamnesisApi.getByPatient(patient.uuid);
                }
                if (anamneses.length > 0) {
                    anamneses.sort((x, y) => +new Date(y.createdAt) - +new Date(x.createdAt));
                    this.lastVisited = this.getMaxDate(anamneses);
                } else {
                    this.lastVisited = patient.createdAt;
                }
                this.setState({ isLoading: false, patient, anamneses, files });
            } catch (error) {
                this.setState({
                    errorMessage: error.message
                });
            }
        } else {
            this.setState({
                errorMessage: 'Patient is not detected'
            });
        }
    }

    componentDidMount() {
        if (this.props.patients) {
            this.setData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patients !== this.props.patients) {
            this.setData();
        }
    }

    getMaxDate(anamneses) {
        let maxDate = new Date(anamneses[0].updatedAt);
        let returnDate = anamneses[0].updatedAt;

        for (let i = 1; i < anamneses.length; i++) {
            const newDate = new Date(anamneses[i]);
            if (newDate > maxDate) {
                maxDate = newDate;
                returnDate = anamneses[i];
            }
        }
        return returnDate;
    }

    deleteFile = async (index) => {
        const { files, patient } = this.state;
        const { t } = this.props;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='theme_confirm'>
                        <p className='theme_confirm__text'>Soll die wirklich gelöscht werden?</p>
                        <div className="theme_confirm__cta">
                            <button className="theme_btn small" onClick={onClose}>{t('no')}</button>
                            <button
                                className="theme_btn small"
                                onClick={async () => {
                                    await removeFile(patient, files[index]);
                                    this.setState({ files: [...files.slice(0, index), ...files.slice(index + 1)] })
                                    onClose();
                                }}
                            >
                                {t('yes')}
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }

    editFile = (index, file) => {
        this.setState({
            selectedEditFile: file
        });
        this.setState({
            isDropZoneOpened: true
        });
        console.log('EDIT FILE TODO: ', index);
        console.log('EDIT FILE TODO: ', file);
    }

    uploadFile = async (newFiles, fileName) => {
        let data = new FormData();
        const { patient, files } = this.state;
        for (let file of newFiles) {
            data.append('files', file);
        }
        const results = await addFiles(patient, data, fileName);
        this.setState({ files: [...files, ...results], isDropZoneOpened: false });
    }

    // uploadFileModal = async (newFile) => {
    //     let data = new FormData();
    //     const { patient, files } = this.state;
    //     data.append('files', newFile);
    //     console.log('data: ', data);
    //     const results = await addFiles(patient, data);
    //     this.setState({ files: [...files, ...results], isDropZoneOpened: false})
    // }

    onClickAnamnesis = (anamnesis) => {
        const { patient } = this.state;
        return (e) => {
            e.preventDefault();
            this.props.history.push({
                pathname: `/patients/${patient.uuid}/anamneses/${anamnesis.uuid}`,
                anamnesis,
                patient,
            });
        };
    }

    renderAnamnesises = (anamneses) => {
        const { t } = this.props;
        const parts = t("noAnamnesisRecorded").split('\n')
        if (anamneses.length === 0) {
            const { patient } = this.state;
            return (
                <div>
                    {parts[0]} {parts[1]} <Link to={{ pathname: `/patients/${patient.uuid}/anamneses/create`, patient: patient }}>{parts[2]}</Link> {parts[3]}
                </div>
            );
        }
        return (
            <>
                {anamneses.map((anamnesis) => (
                    <div className="anamneses_card" key={anamnesis.uuid}>
                        <div className="anamneses_card__info" onClick={this.onClickAnamnesis(anamnesis)}>
                            <div className="anamneses_card__info__icon"><img src={anamnesisCardIcon} /></div>
                            <div className="anamneses_card__info__text">
                                <p>{formatDate(anamnesis.createdAt)}</p>
                                <p>{anamnesis.title}</p>
                            </div>
                        </div>
                        <div className="anamneses_card__cta">
                            <a onClick={() => this.deleteAnamnesis(anamnesis)}><FaTrashAlt /></a>
                        </div>
                    </div>
                ))}
            </>
        );
    }

    deleteAnamnesis = async (anamnesis) => {
        const { dispatch, t } = this.props;
        const { patient } = this.state;
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='theme_confirm'>
                        <p className='theme_confirm__text'>Soll die wirklich gelöscht werden?</p>
                        <div className="theme_confirm__cta">
                            <button className="theme_btn small" onClick={onClose}>{t('no')}</button>
                            <button
                                className="theme_btn small"
                                onClick={async () => {
                                    try {
                                        await dispatch(removeAnamnesis(patient, anamnesis));
                                        /*
                                            Delete from the state
                                        */
                                        const { anamneses } = this.state;
                                        const anamnesisCopy = [...anamneses];
                                        const deleteIndex = anamnesisCopy.findIndex(a => a.uuid === anamnesis.uuid);
                                        if (deleteIndex > -1) {
                                            anamnesisCopy.splice(deleteIndex, 1);
                                            this.setState({ anamneses: anamnesisCopy });
                                        }
                                    } catch (error) {
                                        dispatch(flashErrorMessage(error.message));
                                    }
                                    onClose();
                                }}
                            >
                                {t('yes')}
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }

    renderPatientDetailsData() {
        const { tab_id } = this.state;
        switch (tab_id) {
            case 'details': {
                ;
                return this.renderPatientDetails();
            }
            case 'lab_results': {
                return this.renderPatientLabResults();
            }
            case 'policy': {
                return this.renderPatientPrivacy();
            }
        }
    }

    changeTabId(tab_id) {
        this.setState({ tab_id });
    }

    renderPatientDetails() {
        const { t } = this.props;
        const { patient, anamneses } = this.state;
        const noPersonalDetailsPlaceholder = t('noPersonalDetails').split('\n');
        return (
            <div className="details">
                <div className="details__left">
                    <p className="details__left__title">{t('lastVisitedDetails')}: {formatDate(this.lastVisited)}</p>
                    <div className="details__left__text">
                        {patient.details || (
                            <div>
                                {noPersonalDetailsPlaceholder[0]} <Link to={{ pathname: `/patients/${patient.uuid}/edit`, patient: patient }}>{noPersonalDetailsPlaceholder[1]}</Link> {noPersonalDetailsPlaceholder[2]}
                            </div>
                        )}
                    </div>
                </div>
                <div className="details__right">
                    {this.renderAnamnesises(anamneses)}
                </div>
            </div>
        );
    }

    fileModalDropzoneClose = () => {
        this.setState({
            isDropZoneOpened: false,
            selectedEditFile: null
        });
    }

    fileModalDropzoneOpen = () => {
        this.setState({
            isDropZoneOpened: true
        });
    }

    changeFavoriteStatus = () => {
        const { patient } = this.state;
        const { dispatch, t } = this.props;
        const currentFavorite = (patient && patient.favorite) || false;
        const value = !currentFavorite;
        const payload = {
            uuid: patient.uuid,
            id: patient.id,
            updatedAt: patient.updatedAt,
            favorite: value
        };
        dispatch(updatePatient(patient, payload, t));
    }

    downloadFile(file) {
        const innerWidth = 700;
        const innerHeight = 500;
        const fullScreenWidth = innerWidth - 80;
        const fullScreenHeight = innerHeight - 180;
        /*
            Create a file template
        */
        const fileTemplate = () => {
            const imageExtensions = [
                'png',
                'jpeg',
                'jpg'
            ];
            const videoExtensions = [
                'mp4',
                'mov'
            ];
            // extract file extension
            const urlObject = new URL(file.path);
            const port = urlObject.port ? `:${urlObject.port}` : '';
            const filePathArray = file.path.split('/');
            const fileName = filePathArray.pop();
            const fileNameSplit = fileName.split('.');
            let originalFileExt = 'jpg';
            if (fileNameSplit.length > 1) {
                originalFileExt = fileNameSplit.pop();
            }
            const fileExt = originalFileExt.toLowerCase();
            const fileNameFinal = `${fileNameSplit[0]}.${originalFileExt}`;
            let filePath = `${urlObject.protocol}//${urlObject.hostname}${port}/static/uploads/${fileNameFinal}`;
            // console.log('file: ', file);
            // const filePathSplit = file.filename.split('.');
            // const fileExt = file.filename ? filePathSplit.pop().toLowerCase() : 'jpg';
            // console.log('filePathSplit part:: ', filePathSplit[0]);
            // let filePath = file.path;
            if (process.env.NODE_ENV === 'production') {
                // filePath = file.path.replace(/^http:\/\//i, 'https://');
                filePath = filePath.replace(/^http:\/\//i, 'https://');
            }
            if (imageExtensions.indexOf(fileExt) > -1) {
                return (
                    <>
                        <img src={filePath} onClick={openFileNative} />
                    </>
                );
            } else if (videoExtensions.indexOf(fileExt) > -1) {
                return (
                    <>
                        <video
                            style={{
                                width: `${fullScreenWidth}px`,
                                height: `${fullScreenHeight}px`
                            }}
                            controls
                            autoPlay
                        >
                            <source src={filePath} type="video/mp4" />
                        </video>
                    </>                    
                );
            } else if (fileExt === 'pdf') {
                return (
                    <>
                        <iframe
                            src={filePath}
                            frameBorder="0"
                            style={{
                                width: `${fullScreenWidth}px`,
                                height: `${fullScreenHeight}px`
                            }}
                        />
                    </>
                );
            } else {
                return (
                    <>
                        <a url={filePath}>{filePath}</a>
                    </>
                );
            }
        };
        // ===
        const { t } = this.props;
        const openFileNative = () => {
            const a = document.createElement('a');
            a.href = file.path;
            a.download = file.filename || 'download';
            a.addEventListener('click', () => { }, false);
            a.click();
        };
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='theme_fullscreen'>
                        <div className="theme_fullscreen__cta top-alig-left">
                            <button className="theme_btn small" onClick={onClose}>
                                {t('back')}
                            </button>
                        </div>
                        <div className="theme_fullscreen__body">
                            {fileTemplate()}
                        </div>
                    </div>
                );
            }
        });
    }

    renderPatientLabResults() {
        const { files } = this.state;
        const { t } = this.props;
        /*
            Build data for the data table
        */
        const tableData = {
            columns: [
                {
                    label: '',
                    field: 'icon',
                    sort: 'disabled',
                    width: 100
                },
                {
                    label: t('tbl_name'),
                    field: 'originalFilename',
                    sort: 'asc'
                },
                {
                    label: t('tbl_date'),
                    field: 'updatedAt',
                    sort: 'asc'
                },
                {
                    label: t('tbl_actions'),
                    field: 'aktionen',
                    sort: 'disabled',
                    width: 100
                }
            ],
            rows: []
        };
        const legitKeys = tableData.columns.map(c => {
            if (c.field && c.field !== '') {
                return c.field;
            }
            return null;
        }).filter(c => c !== null);
        if (files && files.length > 0) {
            files.sort((a, b) => {
                const dateA = new Date(a.updatedAt), dateB = new Date(b.updatedAt);
                return dateB - dateA;
            });
            for (let p = 0; p < files.length; p++) {
                const dataRowObject = {};
                Object.defineProperty(dataRowObject, 'clickEvent', {
                    value: this.downloadFile.bind(this, files[p]),
                    writable: true,
                    enumerable: true,
                    configurable: true
                })
                for (let i = 0; i < legitKeys.length; i++) {
                    let legitValue = files[p][legitKeys[i]];
                    if (legitKeys[i] === 'updatedAt') {
                        legitValue = formatDate(files[p]['updatedAt']);
                    }
                    if (legitKeys[i] === 'icon') {
                        legitValue = (
                            <img src={fileListFolderImage} />
                        );
                    }
                    if (legitKeys[i] === 'aktionen') {
                        legitValue = (
                            <div className="lab_results__body__tbl__controls">
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.deleteFile(p);
                                    }}
                                ><FaTrashAlt /></a>
                            </div>
                        );
                    }
                    Object.defineProperty(dataRowObject, `${legitKeys[i]}`, {
                        value: legitValue,
                        writable: true,
                        enumerable: true,
                        configurable: true
                    });
                }
                tableData.rows.push(dataRowObject);
            }
        }
        return (
            <div className="lab_results">
                <div className="lab_results__header">
                    <div className="lab_results__header__title">
                        <p>{t('patient_detail_tab_lab')}</p>
                    </div>
                    <div className="lab_results__header__control">
                        <a onClick={() => this.fileModalDropzoneOpen()}>
                            <FaPlus />
                            {t('upload')}
                        </a>
                    </div>
                </div>
                <div className="lab_results__body">
                    {files.length > 0 ? (
                        <div className="theme_table">
                            <MDBDataTableV5 hover scrollX data={tableData} searching={false} />
                        </div>
                    ) : (<></>)}
                </div>
            </div>
        );
    }

    renderPatientPrivacy() {
        const { t } = this.props;
        return (
            <div className="data_protection">
                <div className="data_protection__text">
                    <p>{t('data_protection_1')}</p>
                    <p>{t('data_protection_2')}</p>
                    <p>{t('data_protection_3')}</p>
                </div>
                <div className="data_protection__cnt">
                    <div className="theme_checkbox_xl">
                        <input
                            type="checkbox"
                            name="data_protection_conf"
                            id="data_protection_conf"
                            value="1"
                            defaultChecked={true}
                            disabled
                        />
                        <label htmlFor="data_protection_conf">{t('data_protection_conf')}</label>
                    </div>
                </div>
            </div>
        );
    }

    renderLoaded() {
        const { t } = this.props;
        const { patient, tab_id } = this.state;
        const currentFavorite = (patient && patient.favorite) || false;

        if (!patient) {
            return (
                <div className="EmptyPlaceholder">
                    {t('patientNotFound')}
                </div>
            );
        }

        const age = getAge(patient.birthday);
        return (
            <>
                <div className="patient_detail">
                    <div className="theme_header">
                        <div className="theme_header__info">
                            <div className="theme_header__info__left">
                                <div className="info_cell">
                                    <div className="info_cell__icon">
                                        <img src={patientsListFolderImage} />
                                    </div>
                                    <div className="info_cell__text">
                                        <p>{patient.firstName} {patient.surName}</p>
                                        <p>{age + ' ' + t('years') + ' | ' + t('birthday') + ': ' + formatDate(patient.birthday)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="theme_header__info__right">
                                <div className="control_cell">
                                    <div className="control_cell__icon">
                                        <a
                                            className={classNames('', {
                                                'active': currentFavorite
                                            })}
                                            onClick={() => this.changeFavoriteStatus()
                                            }><FaRegStar /></a>
                                    </div>
                                    <div className="control_cell__btn">
                                        <NavLink to={`/patients/${patient.uuid}/anamneses/create`}>{t("addAnamnesis")}</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="theme_tabs patient_detail__tabs">
                            <a className={tab_id === 'details' ? 'active' : ''} onClick={() => this.changeTabId('details')}>{t('patient_detail_tab_overview')}</a>
                            <a className={tab_id === 'lab_results' ? 'active' : ''} onClick={() => this.changeTabId('lab_results')}>{t('patient_detail_tab_lab')}</a>
                            <a className={tab_id === 'policy' ? 'active' : ''} onClick={() => this.changeTabId('policy')}>{t('patient_detail_tab_data')}</a>
                        </div>
                    </div>
                    <div className="patient_detail__data">
                        {this.renderPatientDetailsData()}
                    </div>
                </div>
            </>
        );
    }

    renderErrorMessgae() {
        const { errorMessage } = this.state;
        return (
            <div className="alert alert--error">
                <p className="alert__title">Error info:</p>
                <div className="alert__body">
                    <strong>{ errorMessage }</strong>
                </div>
            </div>
        );
    }

    render() {
        const { t } = this.props;
        const { patient, isLoading, errorMessage } = this.state;

        return (
            <>
                {this.state.isDropZoneOpened &&
                    <FileDropzoneModal
                        initialValue={this.state.isDropZoneOpened}
                        selectedEditFile={this.state.selectedEditFile}
                        fileModalDropzoneClose={this.fileModalDropzoneClose}
                        onSubmit={this.uploadFile}
                    />
                }
                <AppTopNavBar
                    isControlPanel={true}
                    title={t("patientDetails")}
                    leftNavigation={
                        <NavLink className="icon-enabled-link" to={'/patients'}>
                            <span className='icon icon-navi-left'></span>
                            {t("back")}
                        </NavLink>
                    }
                    rightNavigation={
                        <NavLink to={{ pathname: `/patients/${this.patientUuid}/edit`, patient: patient }}>{t("edit")}</NavLink>
                    }
                />
                <div className="app_container__inner customHead">
                    {errorMessage && this.renderErrorMessgae()}
                    {!isLoading && this.renderLoaded()}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    isOnline: state.connection.isOnline,
    patients: state.patients
});

export default connect(mapStateToProps)(withTranslation('common')(PatientDetail));