import api from '../config/api';

class ApiAuth {
    static isAuthenticated = async () => {
        const response = await api.get('/api/auth/isValid');
        return response.data;
    };

    static reissueToken = async (data) => {
        const response = await api.post('/api/auth/refreshJwtToken', data);
        return response.data;
    };

    static getDoctorByToken = async (token) => {
        const response = await api.get('/api/doctors/getByToken/'+token);
        return response.data;
    };

    static createPassword = async(data) => {
        const response = await api.post('/api/auth/createPassword', data);
        return response.data;
    };

    static login = async (data) => {
        const response = await api.post('/api/auth/signin', data);
        return response.data;
    };

    static logout = (data) => {
        return api.get('/api/auth/logout', data).data;
    };

    static resetRequest = (email) => {
        return api.post('/api/auth/reset/request', { email }).data;
    };

    static checkResetValidation = (data) => {
        return api.post('/api/auth/reset/checkValidation', data)
    };

    static resetPassword = (password, token) => {
        return api.post('/api/auth/reset/password', { password, token });
    };
};

export default ApiAuth;