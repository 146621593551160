import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getPatient, updatePatient } from '../../actions/Patients';
import PatientForm from './PatientForm';
import { NavLink } from 'react-router-dom';
import AppTopNavBar from '../navbar/AppTopNavBar';
import { formatDate } from '../utility/Date';


class PatientEdit extends Component {
  constructor(props) {
    super(props);

    this.patientUuid = this.props.match.params.uuid
    this.form = null;
    this.state = {
      patient: this.props.location.patient,
      isLoading: true,
    }
  }

  async setPatient() {
    let { patient } = this.state;
    if(!patient) {
      patient = await this.props.dispatch(getPatient(this.patientUuid));
    }
    this.setState({ patient, isLoading: false });
  }

  componentDidMount() {
    if(this.props.patients) {
      this.setPatient();
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.patients !== prevProps.patients) {
      this.setPatient();
    }
  }

  setForm = (form) => {
    this.form = form;
  }

  savePatient = () => {
    if(this.form.validateAll()) {
      const data = this.form.state;
      const { dispatch, doctorId, t } = this.props;
      const payload = { ...data, doctorId }
      
      dispatch(updatePatient(this.state.patient, payload, t));
    }
  }

  renderLoaded() {
    const { t } = this.props;
    const { patient } = this.state;
    
    if(!patient) {
      return(
        <div className="EmptyPlaceholder">
          {t('patientNotFound')}
        </div>
      );
    }
    return (
        <>
            <AppTopNavBar
                isControlPanel={true}
                title={
                    <>
                        <p>{t("patientDetails")}</p>
                        <p>
                            {`${patient.firstName} ${patient.surName} 
                            (${formatDate(patient.birthday)}, ${t(patient.genderType.name)})`}
                        </p>
                    </>
                }
                leftNavigation = {
                    <NavLink className="icon-enabled-link" to={`/patients/${this.patientUuid}`}>
                        <span className='icon icon-navi-left'></span>
                        {t("back")}
                    </NavLink>
                }
                rightNavigation = {
                    <a onClick={this.savePatient}>{t("save")}</a>
                }
            />
            <div className="app_container__inner">
                <PatientForm patient={patient} ref={this.setForm}/>
            </div>
        </>
    );
  }

  render() {
    const { isLoading } = this.state;
    return (
        <>
            { !isLoading && this.renderLoaded()}
        </>
    );
  }
}

const mapStateToProps = (state) => ({
  doctorId: state.doctor.id,
  patients: state.patients,
});

export default connect(mapStateToProps)(withTranslation("common")(PatientEdit));