import React, { useEffect, useState } from 'react';
import { refreshDoctorData } from '../state/doctor/doctor.actionCreators';
import ApiAuth from '../api/ApiAuth';
import { connect } from 'react-redux';
import { Detector } from './utility/Detector';
import AuthDetector from './utility/AuthDetector';
import Routes from './Routes';
import { withTranslation } from 'react-i18next';
import './MainContainer.scss';
import AppBottomNavBar from './navbar/AppBottomNavBar';
// import socketIOClient from "socket.io-client";

function MainContainer(props) {
    // const [socketAuthResponse, setSocketAuthResponse] = useState(null);
    const [sessionExpireWarning, setSessionExpireWarning] = useState(null);
    useEffect(() => {
        // Try and make an authenticated request to the Api.
        // If the requests fails because the token is expired,
        // the middleware automatically logs the user out.
        // ApiAuth.isAuthenticated().then(() => { }).catch(() => { });
        // socket based replacement for the auth status check
        // const socketEndpoint = 'http://localhost:8080/auth_check';
        // const socket = socketIOClient(socketEndpoint);
        // socket.on("FromAPI", data => {
        //     setSocketAuthResponse(data);
        // });
        // console.log('socketAuthResponse: ', socketAuthResponse);
    }, []);

    const exposeWarningTrigger = (deadlineDiffMin) => {
        setSessionExpireWarning({
            deadlineDiffMin
        });
    };

    const refreshJwtSession = async () => {
        // perform re-login action
        const { accessToken } = await ApiAuth.reissueToken({ name: props.doctorState.name });
        if (accessToken) {
            // update global state
            props.dispatch(refreshDoctorData(accessToken));
            // update start time in a localstorage
            const timeStampsSeconds = Math.floor(new Date().getTime() / 1000)
            localStorage.setItem('timeStampSeconds', timeStampsSeconds);
        }
    };

    const renderAuthDetector = () => {
        if (props.isAuthenticated) {
            return <AuthDetector
                        expiresIn={props.expiresIn}
                        exposeWarning={exposeWarningTrigger}
                    />;
        }
        return null;
    };

    return (
        <div className="app">
            {renderAuthDetector()}
            <Detector className='MainContainer__offline' />
            <div className="app_container">
                {sessionExpireWarning && (
                    <div className="session_expire_warning">
                        <span>Your session will expire in {sessionExpireWarning.deadlineDiffMin} minutes</span>
                        <button
                            className="theme_btn small"
                            onClick={refreshJwtSession}
                        >Refresh</button>
                    </div>
                )}
                <Routes />
            </div>
            <AppBottomNavBar />
        </div>
    );
}

const mapStateToProps = (state) => ({
    isOnline: state.connection.isOnline,
    isAuthenticated: state.doctor.isAuthenticated,
    expiresIn: state.doctor.expiresIn,
    doctorState: state.doctor
});

export default connect(mapStateToProps)(withTranslation('common')(MainContainer));
