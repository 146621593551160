import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as flashReducer } from 'redux-flash';
import doctor from './doctor/doctor.reducer';
import connection from './connection/connection.reducer';
import patients from './patients/patients.reducer';
import graph from './graph/graph.reducer';
import misc from './misc/misc.reducer';
import anamnesis from './anamnesis/anamnesis.reducer';
import error from './error/error.reducer';
import { LOGOUT, RESET } from './doctor/doctor.actionTypes';

function createRootReducer(history) {
  return (state, action) => {
    const combinedReducer = combineReducers({
      router: connectRouter(history),
      doctor,
      connection,
      patients,
      graph,
      flash: flashReducer,
      misc,
      error,
      anamnesis
    });

    if(action.type === RESET || action.type === LOGOUT) {
      const { doctor } = state;
      state = { doctor };
    }

    return combinedReducer(state, action);
  };
}

export default createRootReducer;
