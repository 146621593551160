import api from '../config/api';

class TreatmentApi {
    static getByAnamnesis = async (anamnesisUuid) => {
        //Sync remote and local data and return all
        const response = await api.get(`/api/anamneses/${anamnesisUuid}/treatments`);
        return response.data;
    };

    static create = async (anamnesisUuid, data) => {
        const response = await api.post(`/api/anamneses/${anamnesisUuid}/treatments`, data);
        return response.data;
    };

    static getOne = (id) => {
        return api.get(`/api/treatments/${id}`);
    };
 
    static update = async (data, id) => {
        const response = await api.put(`/api/treatments/${id}`, data);
        return response.data; 
    };

    static updateAll = async (anamnesisUuid, treatments) => {
        const response = await api.put(`/api/anamneses/${anamnesisUuid}/treatments`, treatments);
        return response.data;
    }

    static remove = (id) => {
        return api.delete(`/api/treatments/${id}`);
    };
};

export default TreatmentApi;
