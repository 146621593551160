import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import PublicApp from './components/PublicApp';
import FlashMessages from './components/utility/FlashMessages';
import MainContainer from './components/MainContainer';

function App(props) {
  return (
    <Fragment>
      <FlashMessages />
      <Switch>
        <Route path="/" component={props.isAuthenticated ? MainContainer : PublicApp} />
      </Switch>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.doctor.isAuthenticated,
});

export default connect(mapStateToProps)(App);
