import React from 'react';
import { NavLink } from 'react-router-dom';
import logoIcon from '../../assets/img/logo.png';

const AuthNavBar = () => {
    return (
        <div className="auth_nav_bar">
            <NavLink
                to="/login"
                className="logo"
            >
                <div className="logo__cta">
                    <img src={logoIcon} />
                </div>
                <span className="logo__text">Treat-Assistant</span>
            </NavLink>
        </div>
    );
}

export default AuthNavBar;
