import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';
import { getLatestMessage, clearMessages } from 'redux-flash';

function FlashMessages(props) {
    const { flash, dispatch } = props;

    function triggerMessagesClear() {
        dispatch(clearMessages());   
    }

    if (!flash) return null;

    if (flash.isError) {
        return (
            <div className="flash_message flash_message--error">
                <a onClick={() => triggerMessagesClear()} className="flash_message__remove">
                    <FaTimes />
                </a>
                <div className="flash_message__icon">
                    <FaTimes />
                </div>
                <span className="flash_message__text">{flash.message}</span>
            </div>
        );
    }

    return (
        <div className="flash_message flash_message--success">
            <a onClick={() => triggerMessagesClear()} className="flash_message__remove">
                <FaTimes />
            </a>
            <div className="flash_message__icon">
                <FaCheck />
            </div>
            <span className="flash_message__text">{flash.message}</span>
        </div>
    );
}

const mapStateToProps = (state) => ({
  flash: getLatestMessage(state),
});

export default connect(mapStateToProps)(FlashMessages);
