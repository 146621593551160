import React, { useState } from 'react';
import classNames from 'classnames';
import { control } from 'react-validation';
import generateId from './generateId';

export const CheckBox = control(({className, label, error, isChanged, isUsed, ...props}) => {
  const styleClasses = classNames('Form__checkbox', className);
  const [id] = useState(generateId('validated-checkbox-'));

  return (
    <div>
      <div className={styleClasses}>
        <input
          type="checkbox" 
          id={id}
          {...props}
        />
        <label htmlFor={id}>{label}</label>
      </div>
      {isChanged && isUsed && error}
    </div>
  );
});