import React, {Component} from "react";
import { 
  FaFolderOpen,
  FaFile,
} from 'react-icons/fa';
import { connect } from 'react-redux';
import { MdClose } from 'react-icons/md';
import { withTranslation } from 'react-i18next';
import PatientApi from '../../api/PatientApi';
import NavContainer from '../utility/NavContainer';
import { removeFile, addFiles } from '../../actions/Patients';
import FileDropzone from './FileDropzone';
import './Files.scss';

class Files extends Component {
  constructor(props) {
    super(props);

    this.patientUuid = props.match.params.uuid;
    this.lastVisited = null;
    this.state = {
      patient: this.props.location.patient,
      isLoading: true,
      files: [],
    }
  }

  async setData() {
    let { patient } = this.state;
    try {
      if(!patient) {
        patient = await PatientApi.getOne(this.patientUuid); 
      }
      const files = await PatientApi.getFiles(patient.id);
      this.setState({ patient, files, isLoading: false });
    } catch(e) {
      if(e.response && e.response.status === 404) {
        this.setState({ isLoading: false });
      } else {
        throw e;
      }
    }
  }

  async componentDidMount() {
    if(this.props.isOnline) {
      this.setData();
    }
  }

  async componentDidUpdate(prevProps) {
    if(!prevProps.isOnline && this.props.isOnline) {
      this.setData();
    }
  }

  deleteFile = async (index) => {
    const { files, patient } = this.state;
    await removeFile(patient, files[index]);
    this.setState({ files: [...files.slice(0, index), ...files.slice(index+1)]})
  }

  uploadFile = async (newFiles) => {
    let data = new FormData();
    const { patient, files } = this.state;

    for(let file of newFiles) {
      data.append('files', file);
    }
    const results = await addFiles(patient, data);
    this.setState({ files: [...files, ...results]})
  }

  renderLoaded() {
    const { t } = this.props;
    const { files, patient } = this.state;

    if(!patient) {
      return(
        <div className="EmptyPlaceholder">
          {t('patientNotFound')}
        </div>
      );
    }

    return (
      <div className="Files">
        <div className="Files__upload">
          <FileDropzone onSubmit={this.uploadFile}/>
        </div>
        { files.length === 0 ? (
          <div className="Files__empty">
            <div className="EmptyPlaceholder">
              {t("noUploads")}
            </div>
          </div>
        ) : (
          <div className="Files__list">
            {files.map((file, index) => (
              <a download={file.originalFilename} href={file.path} key={index} className="Files__file">
                <button
                  className="Files__file__delete"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.deleteFile(index);
                  }}
                >
                  <MdClose />
                </button>
                <FaFile className="Files__file__icon" />
                <span className="Files__file__name">{file.originalFilename}</span>
              </a>
            ))}
          </div>
        )}
      </div>
    );
  }
  render() {
    const { t, isOnline } = this.props;
    const { patient, isLoading} = this.state;

    return (
      <NavContainer
        back={{pathname: `/patients/${this.patientUuid}`, patient }}
        title={
          <span>
            <FaFolderOpen />
            {t("labResults")}
          </span>
        }
      >
        { !isOnline
          ? (
            <div className="Files__offline">
              <div className="EmptyPlaceholder">
                {t('filesOffline')}
              </div>
            </div>
          ) : (
            !isLoading && this.renderLoaded()
          )
        }
      </NavContainer>
    )
  }
} 

const mapStateToProps = (state) => ({
  isOnline: state.connection.isOnline,
});
export default connect(mapStateToProps)(withTranslation('common')(Files));