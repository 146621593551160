import React from 'react'
import { withTranslation } from 'react-i18next';
import { formatDate } from '../utility/Date';


const SuggestionsMini = (props) => {
    const { 
        results, 
        itemProps, 
        onMouseLeave,
        t
    } = props;

    if (results && results.length > 0) {
        const options = results.map((patient, index) => {
            const {id, ...divProps} = itemProps(index);
            return (
                <li key={patient.uuid} id={id}>
                    <a {...divProps}>
                        <span>{patient.firstName}</span> <span>{patient.surName}</span> ({formatDate(patient.birthday)})
                    </a>
                </li>
            )
        });
        return (
            <div className="patient_search__suggestions" onMouseEnter={() => {}} onMouseLeave={onMouseLeave}>
                <ul className="patient_search__suggestions__list">{options}</ul>
            </div>
        )
    }
    return null;
}

export default withTranslation("common")(SuggestionsMini);
