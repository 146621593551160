import React from 'react';
import classNames from 'classnames';
import './Button.scss';


function Button({
  children,
  type,
  className,
  ...rest
}) {
  const styleClasses = classNames('Button', className);

  return (
    <button
      {...rest}
      className={styleClasses}
      type={type}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  type: 'button',
  className: '',
};

export default Button;
