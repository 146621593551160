import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import { FaFileSignature, FaRegCalendarAlt, FaPaperclip, FaTextHeight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { Form, Input, CheckBox, RadioButton, RadioOption, Textarea } from '../utility/form';
import 'react-datepicker/dist/react-datepicker.css';
import './PatientForm.scss';

class PatientForm extends Component{
  constructor(props) {
    super(props);

    this.form = null;
    const birthday = props.patient ? new Date(props.patient.birthday) : new Date();
    this.state = {
      firstName: props.patient ? props.patient.firstName : "",
      surName: props.patient ? props.patient.surName : "",
      birthday: birthday,
      genderTypeId: props.patient ? props.patient.genderType.id : 1,
      details: props.patient && props.patient.details ? props.patient.details : "",
      privacyPolicySigned: props.patient ? props.patient.privacyPolicySigned : false,
    }
  }

  setForm = (form) => {
    this.form = form;
  }
  
  handleDateChange = (birthday) => {
    this.setState({ birthday });
  }

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    if (target.name === 'genderTypeId') {
      value = parseInt(target.value, 10);
    }
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  validateAll = () => {
    return this.form.validateAll();
  }

  required = (value) => {
    const { t } = this.props;
    if (!value.toString().trim().length) {
      // We can return string or jsx as the 'error' prop for the validated Component
      return <span className="Form__input__error">{t("required")}</span>
    }
  };

  signed = (value, props) => {
    const { t } = this.props;
    if(!props.checked) {
      return <span className="Form__input__error">{t("required")}</span>
    }
  }

  render() {
    const { t } = this.props;
    const { genderTypeId, privacyPolicySigned } = this.state;    
    return (
        <Form ref={this.setForm} className="patient_form">
            <div className="patient_form__left">
                <div className="form_element form_element__radio-inline">
                    <div className="theme_radio">
                        <input
                            type="radio"
                            name="genderTypeId"
                            id="gender_male"
                            value="1"
                            defaultChecked={genderTypeId === 1}
                            onChange={this.handleInputChange}
                        />
                        <label htmlFor="gender_male">
                          <div className="check_round"><span className="check_round__inside"></span></div>
                          {t("male")}
                        </label>
                    </div>
                    <div className="theme_radio">
                        <input
                            type="radio"
                            name="genderTypeId"
                            id="gender_female"
                            value="2"
                            defaultChecked={genderTypeId === 2}
                            onChange={this.handleInputChange}
                        />
                        <label htmlFor="gender_female">
                          <div className="check_round"><span className="check_round__inside"></span></div>
                          {t("female")}
                        </label>
                    </div>
                    <div className="theme_radio">
                        <input
                            type="radio"
                            name="genderTypeId"
                            id="gender_diverse"
                            value="3"
                            defaultChecked={genderTypeId === 3}
                            onChange={this.handleInputChange}
                        />
                        <label htmlFor="gender_diverse">
                          <div className="check_round"><span className="check_round__inside"></span></div>
                          {t("diverse")}
                        </label>
                    </div>
                </div>
                <div className="form_element">
                    <Input
                        label={`${t("firstName")}`}
                        type="text"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.handleInputChange}
                        validations={[this.required]}
                    />
                </div>
                <div className="form_element">
                    <Input
                        label={`${t("lastName")}`}
                        type="text"
                        name="surName"
                        value={this.state.surName}
                        onChange={this.handleInputChange}
                        validations={[this.required]}
                    />
                </div>
                <div className="form_element form_element__datepicker">
                    <label>{t("dob")}</label>
                    <div className="form_element__datepicker__wrap">
                        <div className="form_element__datepicker__wrap__icon">
                            <FaRegCalendarAlt/>
                        </div>
                        <DatePicker
                            utcOffset={0}
                            className="PatientForm__datepicker-input"
                            selected={this.state.birthday}
                            onChange={this.handleDateChange}
                            dateFormat="dd.MM.yyyy"
                        />
                    </div>
                </div>
            </div>
            <div className="patient_form__right">
                <div className="form_element form_element__textarea">
                    <div className="form_element__textarea__wrap">
                        <div className="form_element__textarea__wrap__controls">
                            <div className="form_element__textarea__wrap__controls__icons">
                                <a onClick={this.switchMode}><FaPaperclip/></a>
                                <a className="active" onClick={this.switchMode}><FaTextHeight/></a>
                            </div>
                        </div>
                        <Textarea
                            label={t("personalDetails")}
                            name="details"
                            value={this.state.details}
                            onChange={this.handleInputChange}
                        />
                    </div>
                </div>
            </div>
        </Form>
    )
  }
}

export default withTranslation('common', { withRef: true })(PatientForm);