import React, { Component } from 'react'
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import SuggestionsMini from './SuggestionsMini';
import Search from '../utility/Search';
import PatientApi from '../../api/PatientApi';  

const WAIT_INTERVAL = 200;

class PatientSearch extends Component {

  constructor(props) {
    super(props);
    this.patients = [];

    this.justSelectedSuggestion = false;
    this.pressedSuggestion = null;
    this.timer = null;
    this.state = {
      search: '',
      results: null,
      isLoading: true,
      highlightedSuggestionIndex: null,
    }
  }

  async setData() {
    setTimeout(async () => {
      this.patients = await PatientApi.getByDoctorFull(this.props.doctorId);
      this.setState({
        isLoading: false
      });
    }, 500);
  }

  componentDidMount() {
    this.setData();
  }

  reset = () => {
    this.setState({
      search: '',
      results: null,
      highlightedSuggestionIndex: null,
      pressedSuggestion: null,
      justSelectedSuggestion: false,
      justMouseEntered: false,
    });
  }
  
  onValueChange = (value) => {
    clearTimeout(this.timer);

    if(value !== this.state.search) {
      this.setState({
        highlightedSuggestionIndex: null,
        search: value
      });
    }
    
    this.timer = setTimeout(this.onSuggestionsFetchRequested, WAIT_INTERVAL);
  };

  onSuggestionsFetchRequested = () => {
    const { search } = this.state;
    if(!search || search.length === 0) {
      this.setState({
        results: null
      })
    } else {
      //subset includes all patients where lastName or surName starts with search
      const subset = this.patients.filter((patient) => (
        patient.firstName.toLowerCase().lastIndexOf(search.toLowerCase(), 0) === 0 ||
        patient.surName.toLowerCase().lastIndexOf(search.toLowerCase(), 0) === 0
      ));
      this.setState({
        results: subset,                           
      })
    }
  };

  updateHighlightedSuggestion(newValue) {
    this.setState({
      highlightedSuggestionIndex: newValue,
    });
  }

  resetHighlightedSuggestion() {
    this.setState({
      highlightedSuggestionIndex: null,
    });
  }

  redirectToPatient = (patientIndex) => {
    const patient = this.state.results[patientIndex];
    this.props.dispatch(push({pathname: `/patients/${patient.uuid}`}));
  }

  onClickCreate = () => {
    this.props.dispatch(push({pathname: "/patients/create"}));
  }

  onSuggestionClick = (itemIndex) => {
    return (event) => {
      event.preventDefault();
      this.redirectToPatient(itemIndex);
    }
  };

  onSuggestionMouseEnter = (event) => {
    if (event.target === this.pressedSuggestion) {
      this.justSelectedSuggestion = true;
    }
  };

  onSuggestionMouseDown = event => {
    if (!this.justSelectedSuggestion) {
      this.justSelectedSuggestion = true;
      this.pressedSuggestion = event.target;
    }
  };

  onSuggestionMouseMove = (itemIndex) => {
    return () => {
      if( this.state.highlightedSuggestionIndex !== itemIndex) {
        this.updateHighlightedSuggestion(itemIndex);
      }
    }
  }

  onSuggestionMouseLeave = (event) => {
    if (
      this.justSelectedSuggestion &&
      event.target === this.pressedSuggestion
    ) {
      this.justSelectedSuggestion = false;
    }
  };

  onContainerMouseLeave = () => {
    this.resetHighlightedSuggestion();
  }

  itemProps = (itemIndex) => ({
    id: 'suggestion-' + itemIndex, 
    'data-suggestion-index': itemIndex,
    onMouseEnter: this.onSuggestionMouseEnter,
    onMouseMove: this.onSuggestionMouseMove(itemIndex),
    onMouseLeave: this.onSuggestionMouseLeave,
    onMouseDown: this.onSuggestionMouseDown,
    onClick: this.onSuggestionClick(itemIndex),
  });

  onKeyDown = (event) => {
    const { highlightedSuggestionIndex, results } = this.state;
    const { keyCode } = event;
    let newHighlightedItemIndex = highlightedSuggestionIndex
    
    switch (keyCode) {
      case 40: // ArrowDown
        if (highlightedSuggestionIndex === null) {
          newHighlightedItemIndex = 0;
        } else if (results && highlightedSuggestionIndex < (results.length - 1)) {
          newHighlightedItemIndex++;
        }
        if (results && results.length > 0) {
          this.updateHighlightedSuggestion(
            newHighlightedItemIndex,
          );
          const item = document.getElementById('suggestion-' + newHighlightedItemIndex);
          item.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
        }

        event.preventDefault();
        break;

      case 38: // ArrowUp
        newHighlightedItemIndex =
          (highlightedSuggestionIndex > 0) 
          ? highlightedSuggestionIndex - 1
          : null;

        if (results && results.length > 0) {
          this.updateHighlightedSuggestion(
            newHighlightedItemIndex,
          );
          if(newHighlightedItemIndex == null) {
            const searchContainer = document.getElementById('search-container')
            searchContainer.scrollIntoView({behavior: "auto", block: "end", inline: "nearest"});
          } else {
            const item = document.getElementById('suggestion-' + newHighlightedItemIndex);
            item.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
          }
        }

        event.preventDefault();
        break;

      // Enter
      case 13: {
        event.preventDefault();
        if(highlightedSuggestionIndex != null) {
          this.redirectToPatient(highlightedSuggestionIndex);
        }
        break;
      }

      // Escape
      case 27: {
        event.preventDefault();
        this.reset();
        break;
      }

      default: {
        break;
      }
    }
  }

  render() {
    const { t } = this.props;
    const {
      isLoading,
      search, 
      results, 
      highlightedSuggestionIndex
    } = this.state;
    return (
      <div className="Search">
        <div className="Search__inner patient_search">
          <Search
            id='search-container'
            onChange={this.onValueChange}
            onKeyDown={this.onKeyDown}
            placeholder={t("searchPatients")}
            onReset={this.reset}
            className={(results && results.length > 0) ? 'search_opened' : ''}
          />
          <SuggestionsMini datatable-pagination
            results={results} 
            itemProps={this.itemProps} 
            highlightedSuggestionIndex={highlightedSuggestionIndex}
            search={search}
            isLoading={isLoading}
            onClickCreate={this.onClickCreate}
            onMouseLeave={this.onContainerMouseLeave}
          />
        </div>
      </div>
    );
  } 
}
const mapStateToProps = (state) => ({
  doctorId: state.doctor.id,
  isOnline: state.connection.isOnline
});

export default connect(mapStateToProps)(withTranslation("common")(PatientSearch));