import React from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import 'mdbreact/dist/css/mdb.css';
import Root from "./Root";
import  registerServiceWorker from './registerServiceWorker';
import './style/index.scss';

import './config/i18n';

ReactDOM.render(
    <Root/>,
    document.getElementById('root')
);

registerServiceWorker();
