/* eslint-disable no-restricted-globals */
/*
    React core modules imports / side modules imports
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
/*
    Components imports
*/
import AppTopNavBar from '../navbar/AppTopNavBar';
import SymptomModule from '../graph/SymptomModule';
/*
    Actions imports
*/
import { getPatient } from '../../actions/Patients';
import { getAnamnesis, updateAnamnesis, getAnamnesisDirectDb } from '../../actions/Anamneses';
import { getAnswers as loadAnswers} from '../../actions/Answers';
import graphDefaults from '../../state/graph/graph.defaults';
import { getBodyAnswerIndex } from './AnamnesisLoader';
/*
    Misc
*/
import { getAge, formatDate } from '../utility/Date';
/*
    Redux actions imports
*/
import { setBasicProperty as setGraphBasicProperty } from '../../state/graph/graph.actionCreators';

class Symptoms extends Component {
    constructor(props) {
        super(props);
        /*
            Retrieve url params
        */
        this.patientUuid = props.match.params.uuid;
        this.anamnesisUuid = props.match.params.anamnesisUuid;
        this.isNewTimestampNeeded = props.location.isNewTimestampNeeded || false;
        /*
            Define previous path
        */
        const defaultPrevPath = `/patients/${this.patientUuid}/anamneses/${this.anamnesisUuid}`;
        this.prevPath = props.location.prevPath || defaultPrevPath;
        this.prevPathRoute = props.location.prevPath || null;
        /*
            Data holders
        */
        this.patient = null;
        this.anamnesis = null;
        /*
            Set the loading state
        */
        this.state = {
            isLoading: true,
            error: {}
        }
    }
    /*
        Lifecycle methods
    */
    componentDidMount() {
        this.setData();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
            this.setState({ prevPath: this.props.location });
        }
    }
    componentWillUnmount() {
        const { dispatch } = this.props;
        /*
            Reset editor config
        */
        const defaultEditorConfig = graphDefaults.editorConfig;
        const action = {
            editorConfig: defaultEditorConfig
        };
        setGraphBasicProperty(action)(dispatch);
    }

    /*
        Method responsible for the data visualization
    */
    async setData() {
        let error = {};
        const { dispatch, graph } = this.props;
        /*
            Load patient data
        */
        const patient = await dispatch(getPatient(this.patientUuid));
        this.patient = patient;
        /*
            Load anamnesis data
        */
        const anamnesis = await getAnamnesis(patient, this.anamnesisUuid);
        this.anamnesis = anamnesis;
        if(anamnesis) {
            /*
                Load answers
            */
            const answers = await loadAnswers(patient, anamnesis);
            answers.sort((a, b) => {
                return a.question.order < b.question.order ? -1 : 1;
            });
            /*
                Find a body answer among the answers
            */
            const bodyAnswerIndex = getBodyAnswerIndex(answers);
            if (bodyAnswerIndex > -1) {
                const bodyAnswer = answers[bodyAnswerIndex];
                /*
                    Setup graph editor config
                */
                const gender = (patient && patient.genderType.name) || 'male';
                let svgs = bodyAnswer.answer.svgs || null;
                // get svgs of the body answer directly from the DB
                // in case if it is empty in a local db
                if (!svgs) {
                    const anamnesesDirect = await getAnamnesisDirectDb(this.anamnesisUuid);
                    const bodyAnswerIndexDb = getBodyAnswerIndex(anamnesesDirect.answers);
                    if (bodyAnswerIndexDb > -1) {
                        const bodyAnswerDirect = anamnesesDirect.answers[bodyAnswerIndexDb];
                        const svgsDirect = bodyAnswerDirect.answer.svgs || null;
                        svgs = svgsDirect;
                        // update answers
                        answers[bodyAnswerIndex].answer.svgs = svgsDirect;
                    }
                }
                // give the default svgs object
                if (!svgs) {
                    const defaultSvgs = [
                        {
                            elements: [],
                            viewBox: {
                                width: 960,
                                height: 775
                            }
                        }
                    ];
                    svgs = defaultSvgs;
                    answers[bodyAnswerIndex].answer.svgs = defaultSvgs;
                }
                // build the editor object
                const editorConfigUpdate = {
                    svgs,
                    gender,
                    isFullScreenMode: true,
                    mode: 'edit'
                };
                const editorConfig = { ...graph.editorConfig, ...editorConfigUpdate}
                const action = {
                    editorConfig
                };
                setGraphBasicProperty(action)(dispatch);
                /*
                    Save anamnesis (in order to change a timestamp)
                */
                // ===
                this.setState({
                    answers,
                    isLoading: false,
                    error,
                });
            } else {
                this.setState({
                    isLoading: false,
                    error: {
                        msg: 'Symptoms : setData : body answer is not found'
                    },
                });
            }
        }
        /*
            Dismiss loading state
        */
        this.setState({
            isLoading: false,
            error
        });
    }

    /*
        Render basic patient info in a header
    */
    renderPatientCard() {
        const { t } = this.props;
        if (this.patient) {
            const age = getAge(this.patient.birthday);
            return (
                <div className="patient_details">
                    <p>{`${this.patient.firstName} ${this.patient.surName}`}</p>
                    <p>{age + ' ' + t('years') +' | ' + t('birthday') + ': ' + formatDate(this.patient.birthday)}</p>
                </div>
            );
        }
        return null;
    }
    
    /*
        Back link click handler
    */
    goBack = () => {
        if (this.prevPathRoute) {
            const { dispatch } = this.props;
            dispatch(push({
                pathname: this.prevPathRoute
            }));
        } else {
            window.history.back();
        }
    }

    symptomSlideCallback = (svgs) => {
        const bodyAnswerIncomingSvgs = svgs.svgs;
        if (bodyAnswerIncomingSvgs) {
            /*
                Update body answer svgs
            */
            // fetch answers from the state
            const { answers } = this.state;
            const answersCopy = JSON.parse(JSON.stringify(answers));
            // find body answer index among answers
            const bodyAnswerIndex = getBodyAnswerIndex(answersCopy);
            if (bodyAnswerIndex > -1) {
                // define bodyAnswer as a pointer
                // so as i changing bodyAnswer , it will be automatically updated inside of the answers
                const bodyAnswer = answersCopy[bodyAnswerIndex];
                if (bodyAnswer.answer && bodyAnswer.answer.svgs) {
                    bodyAnswer.answer.svgs = bodyAnswerIncomingSvgs;
                    this.setState({
                        answers: answersCopy
                    });
                } else {
                    this.setState({
                        error: {
                            msg: 'Symptoms : symptomSlideCallback : body answer has no svgs inside answer object'
                        }
                    });
                }
            } else {
                this.setState({
                    error: {
                        msg: 'Symptoms : symptomSlideCallback : body answer is not found'
                    }
                });
            }
        }
    }

    /*
        Anamnesis updater
    */
    updateAnamnesis = () => {
        const { dispatch, t } = this.props;
        const { answers } = this.state;
        /*
            Double check if body answer has svgs
        */
        let error = {
            msg: 'Body answer has no svgs object property exsited inside of answer object'
        };
        const bodyAnswerIndex = getBodyAnswerIndex(answers);
        if (bodyAnswerIndex > -1) {
            const bodyAnswer = answers[bodyAnswerIndex];
            if (bodyAnswer.answer.svgs) {
                error = null;
            }
        }
        // ===
        if (!error) {
            /*
                Needs to add a new timestamp
                Commented out for now
                Since we need to decide on a logic
            */
            // if (this.isNewTimestampNeeded) {
            //     const bodyAnswer = answers[bodyAnswerIndex];
            //     const latestSvg = bodyAnswer.answer.svgs[bodyAnswer.answer.svgs.length - 1];
            //     answers[bodyAnswerIndex].answer.svgs.push(latestSvg);
            // }
            // ===
            const anamnesisUpdateData = {
                answers,
                title: this.anamnesis.title
            };
            dispatch(updateAnamnesis(this.patient, this.anamnesis, anamnesisUpdateData, t));
        } else {
            this.setState({
                error
            });
        }
    }

    /*
        Render the component when data is loaded
    */
    renderLoaded() {
        const { t, graph } = this.props;
        const { error } = this.state;
        const editorConfig = graph.editorConfig;
        /*
            Define config for the graph editor
        */
        return (
            <>
                {(error && error.msg) ? (
                    <div className="app_container__inner">
                        <div className="alert alert--error">
                            <p className="alert__title">Error info:</p>
                            <div className="alert__body">
                                <strong>{error.msg}</strong>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <AppTopNavBar
                            isControlPanel={true}
                            title={this.renderPatientCard()}
                            leftNavigation = {
                                <>
                                    <a className='icon-enabled-link' onClick={this.goBack}>
                                        <span className='icon icon-navi-left'></span>
                                        {t('navigation_back')}
                                    </a>
                                </>
                            }
                            rightNavigation = {
                                <>
                                    <a onClick={this.updateAnamnesis}>{t('save')}</a>
                                </>
                            }
                        />
                        <div className="app_container__inner fullWidth whiteBackground">
                            <SymptomModule
                                editorConfig={editorConfig}
                                symptomSlideCallback={this.symptomSlideCallback}
                            />
                        </div>
                    </>
                )}
            </>
        );
    }
    render() {
        const { isLoading } = this.state;
        return (
            <>
                { !isLoading && this.renderLoaded()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    connection: state.connection,
    graph: state.graph
});

export default connect(mapStateToProps)(withTranslation('common')(Symptoms));
