/*
    React core modules imports / side modules imports
*/
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import HomeLessonEditorSearchModule from './HomeLessonEditorSearchModule';
/*
    Icons imports
*/
/*
    Components imports
*/
import AddLesson from './AddLesson';

function HomeLessonEditorModule(props) {
    let data = {};
    const { 
        t,
        options,
        selectedHomeLessons,
        passNewLesson
    } = props;
    const [isHomeLessonsOpened, setHomeLessonsOpenedState] = useState(false);
    const [groups, setGroups] = useState([]);
    
    useEffect(() => {
        setHomeLessonsOpenedState(props.isHomeLessonsOpened);
        const groups = getGroups(selectedHomeLessons);
        setGroups(groups);
    }, [props.isHomeLessonsOpened]);

    /*
        Close home lessons module
    */
    const toggleHomeLessons = () => {
        setHomeLessonsOpenedState(false);
        props.homeLessonsCallback(data);
    }

    /*
        Filter options based on a search term
    */
    const filterOptions = (search) => {
        const lowerSearch = search.toLowerCase();
        return options.reduce((result, option) => {
            const searchBegin = option.name.toLowerCase().indexOf(lowerSearch);
            if(searchBegin !== -1) {
                const searchEnd = searchBegin + lowerSearch.length;
                result.push({ searchBegin, searchEnd, ...option });
            }
            return result;
        }, []);
    }

    /*
        Build the groups of home lessons
    */
    const getGroups = (options) => {
        if(options.length === 0) {
            return [];
        }
        const groups = [[options[0]]];
        for(let i = 1; i < options.length; i++) {
            if (options[i-1].homeLessonCategory.id !== options[i].homeLessonCategory.id) {
                groups.push([options[i]]);
            } else {
                groups[groups.length - 1].push(options[i]);
            }
        }
        return groups;
    }

    /*
        Handle search bar typing
    */
    const onSearchChange = (search) => {
        const options = filterOptions(search);
        const groups = getGroups(options);
        setGroups(groups);
    }

    /*
        Handle checkbox
    */
    const handleInputChange = (e) => {
        const uuid = e.target.id;
        props.alterSelectedHomeLessons(uuid, {
            action: 'check'
        });
    }

    const addLesson = async (category, name) => {
        passNewLesson(name, category);
    }

    /*
        Check the checkbox check status
    */
    const isChecked = (uuid) => {
        const index = selectedHomeLessons.findIndex(l => l.uuid === uuid);
        if (index > -1) {
            return true;
        }
        return false;
    }

	return (
        <div
            className={classNames(`home_lessons_editor`, { 
                'active': isHomeLessonsOpened,
            })}
        >
            <div className="home_lessons_edItor__searchbar">
                <HomeLessonEditorSearchModule
                    onChangeOutside={onSearchChange}
                />
                <a className="theme_btn home_lessons_edItor__searchbar__cnt" onClick={toggleHomeLessons}>{t("done")}</a>
            </div>
            <div className="home_lessons_edItor__groups">
                {groups &&
                    <ul className="groups_list">
                        {groups.map((group, index) => (
                            <li key={index} className="groups_list__category">
                                <span className="groups_list__category__title">{group[0].homeLessonCategory.name}</span>
                                {group.map((homeLesson) => (
                                    <div key={homeLesson.uuid} className="groups_list__category__lesson">
                                        <div className="theme_checkbox">
                                            <input
                                                type="checkbox"
                                                id={homeLesson.uuid}
                                                checked={isChecked(homeLesson.uuid)}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor={homeLesson.uuid}>
                                                {homeLesson.name.slice(0, homeLesson.searchBegin )}
                                                { homeLesson.searchBegin < homeLesson.searchEnd && (
                                                    <mark>{homeLesson.name.slice(homeLesson.searchBegin, homeLesson.searchEnd )}</mark>
                                                )}
                                                {homeLesson.name.slice(homeLesson.searchEnd)}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                                <div className="groups_list__category__title__add-lesson">
                                    <AddLesson onSubmit={addLesson} category={group[0].homeLessonCategory} />
                                </div>
                            </li>
                        ))}
                    </ul>
                }
            </div>
        </div>
	);
}

export default withTranslation("common")(HomeLessonEditorModule);
