function bodyAnswerFilter(answer) {
  const questionType = answer.question.questionType && (answer.question.questionType.id || 0);
  if (questionType === 5) {
      return true;
  }
  return false;
}

export function sortAnswers(answers) {
  return answers.sort((a, b) => {
    return a.question.order < b.question.order ? -1 : 1;
  })
}

export function getAnswers(answers) {
  const normalAnswers = answers.filter((answer) => !bodyAnswerFilter(answer));
  normalAnswers.sort((a, b) => {
    return a.question.order < b.question.order ? -1 : 1;
  })

  return normalAnswers;
}

export const getBodyAnswer = (answers) => {
  return answers.find((answer) => {
      const questionType = (answer.question && (answer.question.questionType || answer.question.questionTypeId)) ? answer.question.questionTypeId || answer.question.questionType.id : null;
      if (questionType && questionType === 5) {
          return true;
      }
      return false;
  });
}

export const getBodyAnswerIndex = (answers) => {
  return answers.findIndex((answer) => {
    const questionType = (answer.question && (answer.question.questionType || answer.question.questionTypeId)) ? answer.question.questionTypeId || answer.question.questionType.id : null;
      if (questionType && questionType === 5) {
          return true;
      }
      return false;
  });
}
