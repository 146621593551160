import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ApiConfig from '../../config/ApiConfig';
import { setOnline, setOffline } from '../../state/connection/connection.actionCreators';
import { syncPatients } from '../../state/patients/patients.actionCreators';

//Implementation base on https://github.com/chrisbolin/react-detect-offline/blob/master/src/index.js
const inBrowser = typeof navigator !== "undefined";
const apiBase = process.env.NODE_ENV === 'production' ? ApiConfig.baseProURL : ApiConfig.baseDevURL;
const pollingUrl = apiBase + '/api/test';
// these browsers don't fully support navigator.onLine, so we need to use a polling backup
const unsupportedUserAgentsPattern = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;

const ping = () => {
    return new Promise(resolve => {
        const isOnline = () => resolve(true);
        const isOffline = () => resolve(false);

        const xhr = new XMLHttpRequest();

        xhr.onerror = isOffline;
        xhr.ontimeout = isOffline;
        xhr.onreadystatechange = () => {
            if (xhr.readyState === xhr.HEADERS_RECEIVED) {
                if (xhr.status) {
                    isOnline();
                } else {
                    isOffline();
                }
            }
        };

        xhr.open("HEAD", pollingUrl);
        xhr.timeout = 5000;
        xhr.send();
    });
};

class UnconnectedDetector extends Component {
    constructor(props) {
        super(props);
        this.unsupportedBrowser = inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent)
        this.state = {
            detector_error: '',
            online:
                inBrowser && !this.unsupportedBrowsertypeof && navigator.onLine === "boolean"
                    ? navigator.onLine
                    : true
        };
    }

    componentDidMount() {
        window.addEventListener("online", this.goOnline);
        window.addEventListener("offline", this.goOffline);

        const { dispatch } = this.props;
        // dispatch(syncPatients());
        if(this.state.online) {
            dispatch(syncPatients());
        } else {
            this.setState({ detector_error: 'unsupported browser for the online / offline pooling' });
        }
        if (this.unsupportedBrowser) {
            ping().then(online => {
                online ? this.goOnline() : this.goOffline();
            });
            this.startPolling();
        } else {
            this.setState({ detector_error: 'unsupported browser for the online / offline pooling' });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("online", this.goOnline);
        window.removeEventListener("offline", this.goOffline);

        if (this.pollingId) {
            this.stopPolling();
        }
    }


    goOnline = () => {
        if (!this.state.online) {
            const { dispatch } = this.props;
            dispatch(setOnline());
            dispatch(syncPatients());
            this.setState({ online: true });
        }
    }

    goOffline = () => {
        if (this.state.online) {
            this.props.dispatch(setOffline());
            this.setState({ online: false });
        }
    }

    startPolling() {
        this.pollingId = setInterval(() => {
            ping().then(online => {
                online ? this.goOnline() : this.goOffline();
            });
        }, 10000);
    }

    stopPolling() {
        clearInterval(this.pollingId);
    }

    render() {
        if (this.state.online) {
            return null;
        } else if (this.state.detector_error) {
            return (
                <div className={this.props.className}>
                    {this.state.detector_error}
                </div>
            );
        } else {
            return (
                <div className={this.props.className}>
                    {this.props.t('offlineBanner')}
                </div>
            );
        }
    }
}

export const Detector = connect()(withTranslation('common')((UnconnectedDetector)));