import api from '../config/api';

export class AnswerApi {
    static getAll = () => {
        return api.get('/api/answers');
    };

    static getByAnamnesis = async (anamnesisUuid) => {
        //Sync remote and local data and return all
        const response = await api.get(`/api/anamneses/${anamnesisUuid}/answers`);
        return response.data;
    };

    static getOne = async (id) => {
        const response = await api.get(`/api/answers/${id}`);
        return response.data;
    };

    static create = async (data) => {
        const response = await api.post('/api/answers', data);
        return response.data;
    };

    static update = async (data, id) => {
        const response = await api.put(`/api/answers/${id}`, data);
        return response.data;
    };

    static updateAll = async (anamnesisUuid, answers) => {
        const response = await api.put(`/api/anamneses/${anamnesisUuid}/answers`, answers);
        return response.data;
    }

    static remove = async (id) => {
        const response = await api.delete(`/api/answers/${id}`);
        return response.data;
    };
};

export default AnswerApi;
