import uuid from 'uuid';
import LocalApi from '../api/LocalApi';
import TreatmentApi from '../api/TreatmentApi';
import { syncRecords, getIsoTimeStamp, tryConnect, sortByCreatedAt, getItem } from './Sync';

export async function getTreatments(anamnesis) {
  const treatments = await getItem(`anamneses_${anamnesis.uuid}_treatments`) || {};
  return sortByCreatedAt(treatments, "DESC");
}

export async function removeTreatment(anamnesis, treatment, connection = {}) {
    const returnData = {
        data: null,
        error: null
    };
    try {
        /*
            Attempt to remove tretment from server
        */
        const { isOnline } = connection;
        const isLocal = await tryConnect(isOnline, async () => {
            await TreatmentApi.remove(treatment.id);
        }, async () =>  {
            return null;
        })[1];
        /*
            Remove treatment from the local API
        */
        const treatments = await LocalApi.getItem(`anamneses_${anamnesis.uuid}_treatments`) || {};
        const treatmentsCopy = [];
        const keys = Object.keys(treatments);
        if (keys.length > 0) {
            for (let i = 0; i < keys.length; i++) {
                treatmentsCopy.push(treatments[keys[i]]);
            }
        }
        if (treatmentsCopy.length > 0) {
            const deleteKey = treatmentsCopy.findIndex(i => i.uuid === treatment.uuid);
            if (deleteKey > -1) {
                treatmentsCopy.splice(deleteKey, 1);
            }
            returnData.data = await LocalApi.setItem(`anamneses_${anamnesis.uuid}_treatments`, treatmentsCopy);
        }
        return returnData;
    } catch (e) {
        returnData.error = e.message;
        return returnData;
    }
}

export function syncTreatments(anamnesisUuid, previousPromise, previousResolve) {
  return {
    key: `anamneses_${anamnesisUuid}_treatments`,
    task: async () => {
      const previous = await previousPromise;
      let localTreatments = await LocalApi.getItem(`anamneses_${anamnesisUuid}_treatments`) || {};
      if(localTreatments.length) {
        localTreatments = {}; //Array of records is deprecated
      }
      const remoteTreatments = await TreatmentApi.getByAnamnesis(anamnesisUuid);
      let remoteUpdates = null
      const result = syncRecords(localTreatments, remoteTreatments, updates => {
        remoteUpdates = updates;
      });
      if(remoteUpdates) {
        TreatmentApi.updateAll(anamnesisUuid, remoteUpdates).then(treatments => {
          LocalApi.updateAll(`anamneses_${anamnesisUuid}_treatments`, treatments.map((treatment) => ({
            id: treatment.id,
            uuid: treatment.uuid,
            syncState: "synced",
            resourceState: null,
            createdAt: treatment.createdAt,
            updatedAt: treatment.updatedAt,
          }))).then(previousResolve(previous));
        })
      } else {
        previousResolve(previous);
      }
      await LocalApi.persistRecords(`anamneses_${anamnesisUuid}_treatments`, result);
      return result;
    }
  };
}

export const injectTreatment = (patient, anamnesis, data) => {
  return async (dispatch, getState) => {
    try {
      const { isOnline } = getState().connection;

      data.updatedAt = getIsoTimeStamp();
      const { bodyAnswer, previousTreatment, ...rest } = data;
      const treatment_id = data.id || null;
      if (treatment_id) {
        delete data.id;
      }
      
      const [ treatment, isLocal ] = await tryConnect(isOnline, async () => {
          if (treatment_id) {
            return await TreatmentApi.update(data, treatment_id);
          }
          return await TreatmentApi.create(anamnesis.uuid, data);
      }, async () => {
        const returnObject = {
          ...rest,
          homeLessons: data.homeLessons.map(homeLesson => ({ uuid: homeLesson.uuid })),
          createdAt: data.updatedAt
        };
        if (!treatment_id) {
          returnObject.resourceState = 'new';
          returnObject.uuid = uuid.v4();
        }
        return returnObject;
      });
      
      await Promise.all([
        LocalApi.getItem(`anamneses_${anamnesis.uuid}_answers`).then(answers => {
          bodyAnswer.answer.svgs[0].createdAt = data.updatedAt;
          if(isLocal) {
            bodyAnswer.updatedAtOriginal = bodyAnswer.updatedAtOriginal || bodyAnswer.updatedAt;
          }
          bodyAnswer.updatedAt = data.updatedAt;
          answers[bodyAnswer.uuid] = bodyAnswer;
          LocalApi.setItem(`anamneses_${anamnesis.uuid}_answers`, answers);
        }),
        LocalApi.updateRecord(`patients_${patient.uuid}_anamneses`, anamnesis.uuid, { 
          updatedAt: data.updatedAt,
          updatedAtOriginal: isLocal? (anamnesis.updatedAtOriginal || anamnesis.updatedAt) : null,
        }),
        LocalApi.updateItem(`patients_${patient.uuid}`, { 
          updatedAt: data.updatedAt,
          updatedAtOriginal: isLocal ? (patient.updatedAtOriginal || patient.updatedAt) : null,
        }),
      ]);

      const treatmentsOrig = await LocalApi.getItem(`anamneses_${anamnesis.uuid}_treatments`) || {};
      const treatments = [];
      for (const tProperty in treatmentsOrig) {
        treatments.push(treatmentsOrig[tProperty]);
      }
      if (treatment_id) {
        treatments[treatment.uuid] = treatment;
        const addTreatmentKey = treatments.findIndex(t => t.uuid === treatment.uuid);
        if (addTreatmentKey > -1) {
          treatments[addTreatmentKey] = treatment;
        }
      } else {
        treatments.push(treatment);
      }
      if(previousTreatment && (previousTreatment.uuid !== treatment.uuid)) {
        const previousTreatmentKey = treatments.findIndex(t => t.uuid === previousTreatment.uuid);
        if (previousTreatmentKey > -1) {
          const objectToAdd = {
            success: previousTreatment.success, 
            updatedAt: data.updatedAt,
            updatedAtOriginal: isLocal ? (previousTreatment.updatedAtOriginal || previousTreatment.updatedAt) : null
          };
          const objectFinal = {...treatments[previousTreatmentKey], ...objectToAdd};
          treatments[previousTreatmentKey] = objectFinal;
        }
      }
      await LocalApi.setItem(`anamneses_${anamnesis.uuid}_treatments`, treatments);
      anamnesis.updatedAt = data.updatedAt;
      patient.updatedAt = data.updatedAt;
      return {
        error: null,
        data: {
          treatment 
        }
      };
    } catch(error) {
      return {
        error: error.message
      };
    }; 
  }
};
