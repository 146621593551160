import api, { decrypt, encrypt } from '../config/api';

export function decryptPatient(patient) {
    return {
        ...patient,
        firstName: decrypt(patient.firstName),
        surName: decrypt(patient.surName),
        birthday: decrypt(patient.birthday),
        gender: decrypt(patient.gender),
        details: decrypt(patient.details),
    }
}

function encryptPatient(patient) {
    return {
        ...patient,
        firstName: encrypt(patient.firstName),
        surName: encrypt(patient.surName),
        birthday: encrypt(patient.birthday),
        gender: encrypt(patient.gender),
        details: encrypt(patient.details),
    }
}

class PatientApi {
    static searchByName = async (doctorId, name) => {
        const response = await api.get('/api/patients', {
            params: {
                doctorId,
                name,
            }
        });
        return response.data;
    }
    
    static getByDoctor = async (doctorId) => {
        const response = await api.get(`/api/doctors/${doctorId}/patients`);
        const patients = response.data.map(decryptPatient);
        return patients;
    }

    static getByDoctorFull = async (doctorId) => {
        const response = await api.get(`/api/doctors/${doctorId}/patientsFull`);
        const patients = response.data.map(decryptPatient);
        return patients;
    }

    static getOne = async (uuid) => {
        const response = await api.get('/api/patients/'+uuid);
        return decryptPatient(response.data);
    }

    static create = async (data) => {
        const response = await api.post('/api/patients', encryptPatient(data));
        return decryptPatient(response.data);
    };

    static addFiles = async (id, data, updatedAt, fileName = null) => {
        let url = `/api/patients/${id}/files?updatedAt=${updatedAt}`;
        if (fileName) {
            url = `/api/patients/${id}/files?updatedAt=${updatedAt}&fileName=${fileName}`;
        }
        const response = await api.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
        return response.data;
    }

    static removeFile = async (id, fileId, updatedAt) => {
        const response = await api.delete(`/api/patients/${id}/files/${fileId}?updatedAt=${updatedAt}`);
        return response.data;
    }

    static removeFiles = async (id, data) => {
        const response = await api.patch(`/api/patients/${id}/files`, data);
        return response.data;
    }

    static getFiles = async (id) => {
        const response = await api.get(`/api/patients/${id}/files`);
        return response.data;
    }

    static updateAll = async(data) => {
        const response = await api.put('patients', data.map(patient => encryptPatient(patient)));
        return response.data;
    }

    static update = async (uuid, data) => {
        const response = await api.put('/api/patients/'+uuid, encryptPatient(data));
        return decryptPatient(response.data);
    };

    static remove = async (uuid) => {
        const response = await api.delete(`/api/patients/${uuid}`);
        return response.data;
    };
};

export default PatientApi;
